import styled from '@emotion/styled'
import NewLoanInstallmentHistoryDialog from './LoanHistory'
import {
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Primary } from '../../utils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { SearchBoxCustom, SearchIconStyle } from '../Common/CommonStyles'
import { LoanRequestEntity, dashboardEntity, financeUI, userAuthenticationUI } from '../../reducers'
import { connect } from 'react-redux'
import { getLoanDetails, sendLoanRequest } from '../../actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { makeStyles } from '@mui/styles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '90%',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  padding: '25px 25px',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  background: Primary,
  color: 'white',
  lineHeight: 2.3,
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const DateField = styled(DatePicker)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root, & .MuiInputLabel-root': {
    lineHeight: '1em',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const InnerTabStyle: React.CSSProperties = {
  padding: '10px 130px',
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2f2',
    color: '#787070',
    fontFamily: 'Montserrat-Bold',
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
    width: '33%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
    width: '33%',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const RepaymentHistoryeTable = (props: any) => {
  const { LoanData } = props
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])
  const [selectedRowData, setSelectedRowData] = useState(null)
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const filtered = LoanData.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
    setFilteredRows(filtered)
  }, [searchQuery, LoanData])

  const handleClickOpen = (rowData: any) => {
    if (rowData.loan_request === 1) {
      setSelectedRowData(rowData)
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <SearchBoxCustom
        id='outlined-basic'
        placeholder='Search Loan Amount'
        variant='outlined'
        size='small'
        value={searchQuery}
        onChange={(e: any) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: <SearchIconStyle />,
        }}
      />

      <NewLoanInstallmentHistoryDialog
        open={open}
        onClose={handleClose}
        rowData={selectedRowData}
      />

      <TableContainer component={Paper} style={{ minHeight: '40vh' }}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>LOAN AMOUNT</StyledTableCell>
              <StyledTableCell>LOAN DATE</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? searchQuery
                ? filteredRows
                : LoanData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : searchQuery
              ? filteredRows
              : LoanData
            ).map((LoanData: any) => (
              <StyledTableRow
                key={`${LoanData?.loan_date}`}
                onClick={() => handleClickOpen(LoanData)}
                style={{ cursor: LoanData.loan_request === 1 ? 'pointer' : 'auto' }}
              >
                <StyledTableCell>{LoanData.loan_amount}</StyledTableCell>
                <StyledTableCell>
                  {new Date(LoanData.loan_date).toLocaleDateString('en-GB')}
                </StyledTableCell>
                <StyledTableCell>
                  {LoanData.loan_request === 1 ? 'Accepted' : 'Pending'}
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {filteredRows.length === 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={3}>No data available</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component='div'
        count={searchQuery ? filteredRows.length : LoanData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={false}
      />
    </>
  )
}

const Loan = (props: any) => {
  const useStyles = makeStyles({
    policyHeading: {
      fontStyle: 'italic',
      position: 'relative',
      left: '0px',
      color: '#193C6D !important',
      fontSize: '15px !important',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '255px',
      transition: 'text-decoration 0.3s',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
  const classes = useStyles()
  const {
    LoanData,
    fetchLoanData,
    sentServiceRequest,
    isLoanRequestSuccess,
    resetLoanRequest,
  } = props
  useEffect(() => {
    fetchLoanData()
  }, [])

  const [value, setValue] = React.useState(0)
  const [loanAmount, setLoanAmount] = React.useState('')
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()))
  const [requestDescription, setRequestDescription] = useState('')
  const [LoanAmountError, setLoanAmountError] = useState(false)
  const [LoanAmountExceededError, setLoanAmountExceededError] = useState(false)
  const [RequestDescError, setRequestDescError] = useState(false)
  const [LoanDateError, setLoanDateError] = useState('')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    fetchLoanData()
    setValue(newValue)
  }

  const handleDateChange = (date: any) => {
    if (date > dayjs(new Date())) {
      setSelectedDate(date)
    }
    setLoanDateError('')
  }

  const handleError = (date: any) => {
    if (date === 'invalidDate' || date === 'minDate' || date === 'maxDate') {
      setLoanDateError('Select valid date')
    }
  }

  function handleKeyPress(event: any) {
    if (
      event.key === 'e' ||
      event.key === 'E' ||
      event.key === '.' ||
      event.key === '+' ||
      event.key === '-'
    ) {
      event.preventDefault()
    }
  }

  const handleLoanAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const hasSpecialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(e.target.value)
    const isValidNumber = !isNaN(Number(inputValue)) && Number(inputValue) >= 1
    setLoanAmountError(!inputValue.length || hasSpecialCharacters || !isValidNumber)
    if (inputValue === '0') {
      setLoanAmountError(true)
    } else if (Number(inputValue) <= 9900000) {
      setLoanAmount(inputValue)
      setLoanAmountError(false)
      setLoanAmountExceededError(false)
    } else {
      setLoanAmountExceededError(true)
    }
  }
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequestDescError(!e.target.value)
    setRequestDescription(e.target.value)
  }

  const handleLoanPolicy = () => {
    window.open(
      'https://sites.google.com/tudip.com/tudipinternal/tudip-benefits/loans-and-advance-salary?authuser=0',
      '_blank',
      'noreferrer',
    )
  }

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleSubmit = () => {
    setLoanAmountError(false)
    setRequestDescError(false)
    if (!requestDescription || !requestDescription.length) {
      setRequestDescError(true)
    }
    let loan: number = parseInt(loanAmount)
    if (!loanAmount || !loanAmount.length || loan <= 1 || !requestDescription.length) {
      setLoanAmountError(true)
      setLoanAmount('')

      return
    }

    sentServiceRequest({
      loan_amount: parseInt(loanAmount),
      loan_request: 0,
      request_description: requestDescription,
      loan_date: selectedDate,
    })

    if (loanAmount && requestDescription && selectedDate) {
      setLoanAmount('')
      setRequestDescription('')
    }
  }

  useEffect(() => {
    if (isLoanRequestSuccess) {
      toast.success('Loan applied successfully')
      resetLoanRequest()
    }
  }, [isLoanRequestSuccess])

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return (
          <div style={{}}>
            <RepaymentHistoryeTable LoanData={LoanData} />
          </div>
        )

      case 1:
        return (
          <div style={InnerTabStyle}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
              <Grid item xs={6} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    minDate={dayjs(new Date()).subtract(0, 'day')}
                    label='Loan Date'
                    value={selectedDate}
                    onChange={handleDateChange}
                    onError={handleError}
                    slotProps={{
                      textField: {
                        helperText: LoanDateError,
                      },
                    }}
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} sm={6}>
                <>
                  <style>
                    {`
          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
                  </style>
                  <InputField
                    error={LoanAmountError || LoanAmountExceededError}
                    required
                    type='number'
                    inputProps={{ min: 1 }}
                    id='outlined-required'
                    label='Loan Amount (In ₹.)'
                    defaultValue=''
                    size='small'
                    helperText={
                      (LoanAmountError && 'Loan amount required') ||
                      (LoanAmountExceededError && 'Loan amount exceeded')
                    }
                    onChange={handleLoanAmountChange}
                    onKeyPress={handleKeyPress}
                    value={loanAmount}
                  />
                </>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField
                  error={RequestDescError}
                  required
                  id='outlined-required'
                  label='Description'
                  defaultValue=''
                  maxRows={4}
                  multiline={true}
                  fullWidth={true}
                  size='small'
                  helperText={RequestDescError && 'Description required'}
                  onChange={handleDescriptionChange}
                  value={requestDescription}
                />
              </Grid>
              <Grid item xs={12} sm={12} justifyContent='center'>
                <Typography
                  onClick={handleLoanPolicy}
                  className={`${classes.policyHeading} ${isHovered ? 'hovered' : ''}`}
                  color='text.secondary'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  gutterBottom
                >
                  <span style={{ color: 'red' }}>Note:</span> Please review the loan policy.
                </Typography>
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    variant='outlined'
                    style={{
                      fontSize: '13px',
                      height: '42px',
                      marginTop: '15px',
                      borderRadius: '20px',
                      padding: '5px 20px',
                      cursor:
                        loanAmount && requestDescription && selectedDate
                          ? 'pointer'
                          : 'not-allowed',
                      color: '#fff',
                    }}
                    onClick={handleSubmit}
                  >
                    LOAN REQUEST
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div style={MainContainer}>
      <StyledPaper>
        <Heading>Loan</Heading>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs sx={{ marginTop: '10px' }} value={value} onChange={handleChange} centered>
            <Tab label='Loan History' />
            <Tab label='Loan Request' />
          </Tabs>
          <Box sx={{ p: 3 }}>{renderTabContent()}</Box>
        </Box>
      </StyledPaper>
    </div>
  )
}

Loan.propTypes = {
  LoanRequestStatus: PropTypes.bool,
  SendLoanRequestData: PropTypes.object,
  sentServiceRequest: PropTypes.func.isRequired,
}

const mapStateToProps = (state: any) => {
  return {
    LoanRequestStatus: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationDone,
    SendLoanRequestData: LoanRequestEntity.sendLoanRequestData(state).sendLoanRequest,
    LoanData: LoanRequestEntity.sendLoanRequestData(state).getLoanDetails,
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isLoanRequestSuccess: financeUI.getFinance(state).isLoanRequestSuccess,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: any }) => void) => {
  return {
    sentServiceRequest: (data: any) => {
      dispatch(sendLoanRequest.request(data))
    },
    resetLoanRequest: () => {
      dispatch(sendLoanRequest.reset())
    },
    fetchLoanData: () => dispatch(getLoanDetails.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loan)
