import * as React from 'react'
import { Autocomplete, Grid, TextField, Tooltip, useMediaQuery } from '@mui/material'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { getAllEmployees } from '../../actions'
import { Dispatch } from 'redux'
import { employeeDataRequest } from '../../reducers/entities'
import { useNavigate } from 'react-router-dom'


const SearchBox = (props: any) => {
  const { employeeRecords, fetchEmployeeRecords } = props
  const [searchEmpDetails, setSearchEmpDetails] = React.useState('')
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const navigate = useNavigate()

  useEffect(() => {
    fetchEmployeeRecords()
  }, [])

  const handleSearchEmp = () => {
    if (searchEmpDetails) {
      const record = employeeRecords?.find((emp: any) => emp?.info === searchEmpDetails)
      setSearchEmpDetails('')
      if (record && record?.userId) {
        navigate(`/home/basic-info-on-search?id=${record?.userId}`)
      }
    }
  }

  /**
   * onSelect
   * @param value
   */
  const onSelect = (value: string) => {
    const employee = employeeRecords?.find((emp: any) => emp?.info === value)
    if (employee && employee?.userId) {
      navigate(`/home/basic-info-on-search?id=${employee?.userId}`)
      setSearchEmpDetails('')
    }
  }

  return (
    <Grid item xs={10} my={2}>
      <Autocomplete
        freeSolo
        autoComplete
        inputValue={searchEmpDetails}
        clearOnBlur={true}
        autoHighlight
        disableClearable
        options={searchEmpDetails ? employeeRecords?.map((emp: any) => emp?.info) : []}
        onChange={(event, value: string) => onSelect(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            id='searchEmployee'
            name='searchEmployee'
            autoFocus
            type='text'
            placeholder='Search Employee'
            InputLabelProps={{ style: { color: '#666666' } }}
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <Tooltip title='Search Employee' placement='top'>
                  <span onClick={handleSearchEmp}>
                    <SearchTwoToneIcon
                      sx={{ color: '#666666', cursor: 'pointer', position: 'absolute', top: 8 }}
                    />
                  </span>
                </Tooltip>
              ),
            }}
            value={searchEmpDetails}
            onChange={(e) => setSearchEmpDetails(e.target.value)}
            sx={{
              '& label.Mui-focused': {
                color: '#666666',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                  borderRadius: '15px',
                },
                '& fieldset': {
                  borderColor: '#fff !important',
                  borderRadius: '15px',
                  boxShadow: 2,
                },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '& .MuiInputBase-input': {
                  borderRadius: '15px',
                  width: isSmallScreen ? '250px' : '400px',
                  fontFamily: 'Montserrat-Medium',
                  marginLeft: 3,
                  letterSpacing: '0',
                },
              },
              svg: {
                color: '#666666',
              },
            }}
          />
        )}
      />
    </Grid>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    employeeRecords: employeeDataRequest.getAllEmployeesList(state).getAllEmployeesDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchEmployeeRecords: () => dispatch(getAllEmployees.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
