import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Table,
} from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { fetchBasicInfo, fetchCountry, fetchMaritialStatus, fetchState } from '../../actions'
import { dashboardEntity, dashboardUI, financeEntity } from '../../reducers'
import { HeaderHeading, StyledTableCell, StyledTableRow, loaderProps } from '../Common/CommonStyles'
import { ArrowBack } from '@mui/icons-material'

const Maincontainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: ' 85%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    cursor: 'not-allowed',
    padding: '11.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '14px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
  },
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    cursor: 'not-allowed',
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  fontSize: '13px',
  marginLeft: '10px',
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: '14px',
  },
}))

const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  color: 'black',
  padding: '20px',
}))
const TextLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: 'Montserrat-Medium',
}))

const PersonalInfo = (props: any) => {
  const data = props.data
  const navigate = useNavigate()

  return (
    <>
      <StyledPaper elevation={0}>
        <>
          <Box sx={{ textAlign: 'left' }}>
            <Box
              onClick={() => navigate(-1)}
              sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
            >
              <ArrowBack />
            </Box>
          </Box>
        </>
        <HeaderHeading sx={{ marginBottom: '20px' }}>Your Information</HeaderHeading>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }} spacing={3}>
          <Grid item xs={4} sm={4}>
            <InputField
              required={false}
              id='outlined-required'
              label='First Name'
              value={data.first_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Middle Name'
              placeholder='Middle Name'
              value={data.middle_name ? data.middle_name : 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputField
              required={false}
              id='outlined-required'
              label='Last Name'
              value={data.last_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label="Father's Name"
              value={data.father_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label="Mother's Name"
              value={data.mother_name ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <img
              src={data.image_path ? data.image_path : CurrentLoginUserProfile}
              style={{
                width: '154px',
                position: 'absolute',
                right: '200px',
                top: '290px',
                borderRadius: '50%',
                height: '150px',
                objectFit: 'cover',
              }}
              alt=''
            />
          </Grid>
          <Grid item xs={3.7} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Email'
              defaultValue={data?.username}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={3.7} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Alternate Email'
              defaultValue={data?.email}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={4} sm={1}></Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Mobile No'
              defaultValue={data?.mobile_no}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Employee Id'
              value={data.employee_id ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={4} sm={1}></Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Floor'
              value={data.floorName ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Workstation'
              value={data.workStation ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Office Time'
              value={
                data?.officeTime ? moment(data?.officeTime, ['HH.mm']).format('hh:mm A') : 'NA'
              }
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <InputField
              required
              id='outlined-required'
              label='Office Time'
              value={
                data?.officeTime ? moment(data?.officeTime, ['HH.mm']).format('hh:mm A') : 'NA'
              }
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          {/* <Grid item xs={4} md={4} sm={0.8}></Grid> */}
          <Grid item sm={2} sx={{ alignItems: 'center' }}>
            <StyledFormControl>
              <StyledFormLabel id='demo-row-radio-buttons-group-label'>
                Contract Employee
              </StyledFormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <StyledFormControlLabel
                  value='2'
                  control={<Radio />}
                  label='Yes'
                  checked={data.is_contract_employee === 1}
                  disabled
                />
                <StyledFormControlLabel
                  value='1'
                  control={<Radio />}
                  label='No'
                  checked={data.is_contract_employee === 0}
                  disabled
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <StyledFormControl>
              <StyledFormLabel id='demo-row-radio-buttons-group-label'>
                Under Probation
              </StyledFormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <StyledFormControlLabel
                  value='1'
                  control={<Radio />}
                  label='Yes'
                  checked={data.under_probation === 1}
                  disabled
                />
                <StyledFormControlLabel
                  value='0'
                  control={<Radio />}
                  label='No'
                  checked={data.under_probation === 0}
                  disabled
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl sx={{ minWidth: 120, marginTop: 1 }}>
              <TextLabel id='demo-simple-select-readonly-label'>Probation Period</TextLabel>
              <SelectField
                labelId='demo-simple-select-readonly-label'
                id='demo-simple-select-readonly'
                value={data.probation_period ?? 'NA'}
                label='Probation Period'
                inputProps={{ readOnly: true }}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={3}>3 months</MenuItem>
                <MenuItem value={6}>6 months</MenuItem>
                <MenuItem value={9}>9 months</MenuItem>
                <MenuItem value={12}>1 year</MenuItem>
              </SelectField>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={4}>
            <FormControl sx={{ minWidth: 120, marginTop: 1, marginLeft: '3px' }}>
              <TextLabel id='demo-simple-select-readonly-label'>Blood Group</TextLabel>
              <SelectField
                labelId='demo-simple-select-readonly-label'
                id='demo-simple-select-readonly'
                value={data.blood_group ?? 'NA'}
                label='Blood Group'
                inputProps={{ readOnly: true }}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'A+'}>A Positive</MenuItem>
                <MenuItem value={'A-'}>A Negative</MenuItem>
                <MenuItem value={'B+'}>B Positive</MenuItem>
                <MenuItem value={'B-'}>B Negative</MenuItem>
                <MenuItem value={'O+'}>O Positive</MenuItem>
                <MenuItem value={'O-'}>O Negative</MenuItem>
                <MenuItem value={'AB+'}>AB Positive</MenuItem>
                <MenuItem value={'AB-'}>AB Negative</MenuItem>
              </SelectField>
            </FormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  )
}

const AtClientLocation = (props: any) => {
  const data = props.data
  return (
    <StyledPaper elevation={0}>
      <LeavesDetailsHeading>CLIENT LOCATION(S)</LeavesDetailsHeading>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>LOCATION</StyledTableCell>
              <StyledTableCell>START DATE</StyledTableCell>
              <StyledTableCell>END DATE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.clientLocationHistory.map((location: any) => (
              <StyledTableRow key={location.id}>
                <StyledTableCell component='th' scope='row'>
                  {location.locationName}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {location.location_start_date
                    ? moment(location.location_start_date).format('DD-MM-YYYY')
                    : 'N/A'}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {location.location_end_date
                    ? moment(location.location_end_date).format('DD-MM-YYYY')
                    : 'N/A'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const OfficeTimingLogs = (props: any) => {
  const { empData } = props
  return (
    <StyledPaper elevation={0}>
      <LeavesDetailsHeading>OFFICE TIMING LOGS</LeavesDetailsHeading>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>OFFICE TIMING</StyledTableCell>
              <StyledTableCell>FROM</StyledTableCell>
              <StyledTableCell>TILL</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component='th' scope='row'>
                {moment(empData?.officeTimingLogs?.from).format('hh:mm A')}
              </StyledTableCell>
              <StyledTableCell align='right'>
                {moment(empData?.officeTimingLogs?.from).format('DD-MM-YYYY')}
              </StyledTableCell>
              <StyledTableCell align='right'>
                {empData?.officeTimingLogs?.till
                  ? moment(empData.officeTimingLogs.till).format('hh:mm A')
                  : 'N/A'}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const EmployeeAddress = (props: any) => {
  const { data, states, countries } = props
  const { userAddress } = data
  const [presentState, setPresentState] = React.useState(userAddress?.present_state)
  const [permanentState, setPermanentState] = useState(userAddress?.permanent_state)
  const [presentCountry, setPresentCountry] = React.useState(userAddress?.id_country)
  const [permanentCountry, setPermanentCountry] = useState(userAddress?.id_country_permanent)

  return (
    <StyledPaper elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5.9}>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              fontFamily: 'Montserrat-SemiBold',
              letterSpacing: '0px',
              color: '#193C6D',
            }}
          >
            Present Address
          </Typography>
        </Grid>
        <Grid item xs={0} md={0}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid item xs={12} md={5.9}>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              fontFamily: 'Montserrat-SemiBold',
              letterSpacing: '0px',
              color: '#193C6D',
            }}
          >
            Permanent Address
          </Typography>
        </Grid>
        <Grid item xs={12} md={5.9}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Building / House name / Flat no'
            value={userAddress?.present_address}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={0} md={0}>
          <Divider orientation='vertical' />
        </Grid>

        <Grid item xs={12} md={5.9}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Building / House name / Flat no'
            value={userAddress?.permanent_address}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={5.9}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Locality (Area / street)'
            value={userAddress?.present_locality ? userAddress?.present_locality : 'NA'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={0} md={0}>
          <Divider orientation='vertical' />
        </Grid>

        <Grid item xs={12} md={5.9}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Locality (Area / street)'
            value={userAddress?.permanent_locality ? userAddress?.permanent_locality : 'NA'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <FormControl sx={{ m: 1, minWidth: 150, marginLeft: '1px' }}>
            <TextLabel id='demo-simple-select-readonly-label'>Country</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={presentCountry}
              label='Country'
              variant='outlined'
              onChange={(e) => setPresentCountry(e.target.value)}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {countries &&
                countries?.map((country: any) => (
                  <MenuItem key={country?.id} value={country?.id}>
                    {country?.country_name}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextLabel id='demo-simple-select-readonly-label'>State</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={presentState}
              label='State'
              onChange={(e) => setPresentState(e.target.value)}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {states &&
                states?.map((state: any) => (
                  <MenuItem key={state?.id} value={state?.id}>
                    {state?.state_name}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={3} sm={1.9} sx={{ mt: '6px' }}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='City'
            value={userAddress?.present_city}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={0} sm={0}>
          <Divider orientation='vertical' />
        </Grid>

        <Grid item xs={4} sm={2}>
          <FormControl sx={{ m: 1, minWidth: 150, marginLeft: '1px' }}>
            <TextLabel id='demo-simple-select-readonly-label'>Country</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={permanentCountry}
              label='Country'
              onChange={(e) => setPermanentCountry(e.target.value)}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {countries &&
                countries?.map((country: any) => (
                  <MenuItem key={country?.id} value={country?.id}>
                    {country?.country_name}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextLabel id='demo-simple-select-readonly-label'>State</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={permanentState}
              label='State'
              onChange={(e) => setPermanentState(e.target.value)}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {states &&
                states?.map((state: any) => (
                  <MenuItem key={state?.id} value={state?.id}>
                    {state?.state_name}
                  </MenuItem>
                ))}
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={1.9} sx={{ mt: '6px' }}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='City'
            value={userAddress?.permanent_city}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Pincode'
            value={userAddress?.present_postal_code}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2} sx={{ marginLeft: '8px' }}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Nationality'
            defaultValue={data?.nationality}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={2}>
          <Divider orientation='vertical' />
        </Grid>

        <Grid item xs={4} sm={1.9}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Pincode'
            value={userAddress?.permanent_postal_code}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={4} sm={2} sx={{ marginLeft: '15px' }}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Nationality'
            defaultValue={data?.nationality}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const OtherEmployeeInfo = (props: any) => {
  const data = props.data
  const maritalData = props.maritalData
  const maritalStatus = maritalData.find((ele: any) => ele.id === data.marital_status)
  const marStatus = maritalStatus ? maritalStatus.marital_status : 'NA'

  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }} spacing={3}>
        <Grid item xs={4} sm={4}>
          <InputField
            required
            id='outlined-required'
            label='Official Birth Date'
            value={new Date(data.official_birth_date).toLocaleDateString('en-GB') ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputField
            required
            id='outlined-required'
            label='Actual Birth Date'
            value={new Date(data.birth_date).toLocaleDateString('en-GB') ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            required
            id='outlined-required'
            label='Age'
            value={data.age ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ minWidth: 120, marginTop: 1 }}>
            <TextLabel id='demo-simple-select-readonly-label'>Maritial Status</TextLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={marStatus}
              label='Marital Status'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={marStatus}>{marStatus}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            required
            InputLabelProps={{ required: false }}
            id='outlined-required'
            label='Spouse Name'
            value={data.spouse_name ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            required
            id='outlined-required'
            label='Spouse Mobile'
            value={data.spouse_mobile ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Emergency Contact Person'
            value={data.contact_person ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            id='outlined-required'
            label='Emergency Contact Person Phone'
            value={data.contact_person_phone ?? 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const EmploymentInfo = (props: any) => {
  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}></Grid>
    </StyledPaper>
  )
}

const OfficeLocationLogs = (props: any) => {
  const data = props.data
  return (
    <StyledPaper elevation={0}>
      <LeavesDetailsHeading>OFFICE LOCATION(S)</LeavesDetailsHeading>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>LOCATION</StyledTableCell>
              <StyledTableCell>START DATE</StyledTableCell>
              <StyledTableCell>END DATE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.companyLocationHistory.map((location: any) => (
              <StyledTableRow key={location.id}>
                <StyledTableCell component='th' scope='row'>
                  {location.locationName}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {moment(location.start_date).format('DD-MM-YYYY')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {location.end_date ? moment(location.end_date).format('DD-MM-YYYY') : 'N/A'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const ConditionLocation = (props: any) => {
  const data = props.data
  if (!data.client_location && data?.companyLocationHistory?.length === 0) {
    return null
  }
  return (
    <>
      {data?.clientLocationHistory?.length && data?.companyLocationHistory?.length ? (
        <>
          <AtClientLocation data={data} />
          <OfficeLocationLogs data={data} />
        </>
      ) : (
        data?.companyLocationHistory?.length && <OfficeLocationLogs data={data} />
      )}
    </>
  )
}

const Dependant = (props: any) => {
  const dependant = props.data.dependents
  return (
    <StyledPaper elevation={0}>
      <HeaderHeading sx={{ margin: '0px 0px 30px 0px' }}>Dependant List</HeaderHeading>
      <TableContainer
        sx={{
          margin: '20px 0',
        }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label='customized table'
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>NAME</StyledTableCell>
              <StyledTableCell>RELATIONSHIP</StyledTableCell>
              <StyledTableCell>AGE</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dependant?.map((data: any, index: number) => (
              <StyledTableRow key={data.id_relationship}>
                <StyledTableCell component='th' scope='row'>
                  {index}
                </StyledTableCell>
                <StyledTableCell align='left'>{data.id_relationship}</StyledTableCell>
                <StyledTableCell align='left'>{data.age}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const BasicInfo = (props: any) => {
  const {
    BasicInfoData,
    CountryData,
    MaritalStatusData,
    StateData,
    fetchBasicInfoData,
    fetchCountryData,
    fetchMaritalStatusData,
    fetchStateData,
    loaderState,
    isGetEmpInfoDataSuccess,
    resetloderReset,
    isBasicInfoData,
  } = props

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')

  useEffect(() => {
    if (userId) {
      fetchBasicInfoData({ userId, isGlobal: false })
    }
    fetchCountryData()
    fetchMaritalStatusData()
    fetchStateData()
  }, [userId])

  useEffect(() => {
    if (isGetEmpInfoDataSuccess) {
      resetloderReset()
    }
  }, [isGetEmpInfoDataSuccess])
  return (
    <>
      {isBasicInfoData && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}

      {Object.keys(BasicInfoData)?.length ? (
        <div style={Maincontainer}>
          <Grid>
            <Grid item xs={12}>
              <PersonalInfo data={BasicInfoData} />
              <OtherEmployeeInfo data={BasicInfoData} maritalData={MaritalStatusData} />
              <EmployeeAddress data={BasicInfoData} states={StateData} countries={CountryData} />
              <ConditionLocation data={BasicInfoData} />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    StateData: financeEntity.getFinance(state).getStateData,
    CountryData: financeEntity.getFinance(state).getCountryData,
    MaritalStatusData: dashboardEntity.getDashboard(state).getMaritalStatusData,
    loaderState: dashboardUI.getDashboard(state).isGetEmpInfoData,
    isGetEmpInfoDataSuccess: dashboardUI.getDashboard(state).isGetEmpInfoDataSuccess,
    isBasicInfoData: dashboardUI.getDashboard(state).isBasicInfoData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBasicInfoData: (data: any) => dispatch(fetchBasicInfo.request(data)),
    fetchStateData: () => dispatch(fetchState.request()),
    fetchCountryData: () => dispatch(fetchCountry.request()),
    fetchMaritalStatusData: () => dispatch(fetchMaritialStatus.request()),
    resetloderReset: () => dispatch(fetchBasicInfo.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
