import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as EditIcon } from '../../../assets/images/editIcon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/deleteIcon.svg'
import { useEffect, useState } from 'react'
import { CardsPropType, FormValues, deleteProjectCustomerType } from './ProjectCustomersTypes'
import { connect } from 'react-redux'
import { projectManagementUI } from '../../../reducers'
import { RootState } from '../../../configureStore'
import { deleteMandateType, deleteProjectCustomer } from '../../../actions'
import { Dispatch } from 'redux'
import ShowEntries from './ShowEntries'
import { NoCards } from './NoCards'
import { style } from './projectCustomersStyle'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'

const Cards = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  projectCustomersData,
  isGettingingProjectCustomers,
  deleteProjectCustomer,
  setEditCustomerData,
  setopenCustomerEditForm,
}: CardsPropType) => {
  const [filteredRows, setFilteredRows] = useState<FormValues[]>()
  const [actionDialog, setActionDialog] = useState(false)
  const diaglogTitle: string = 'Delete Mandate'
  const [diaglogContent, setDiaglogContent] = useState('')
  const [forRowDeleteId, setForRowDeleteId] = useState(0)
  const [overflowingTexts, setOverflowingTexts] = useState<Record<number, boolean>>({})

  useEffect(() => {
    setFilteredRows(projectCustomersData?.data)
  })

  const deleteFunction = () => {
    const id = forRowDeleteId

    deleteProjectCustomer({ data: { id } })
    setForRowDeleteId(0)
  }


  const deleteOpenConfirmationFunction = (rowId: number, rowName: string) => {
    setActionDialog(true)
    setForRowDeleteId(rowId)
    setDiaglogContent(rowName)
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    const updatedOverflowingTexts: Record<number, boolean> = {}
    filteredRows?.forEach((row) => {
      const element = document.getElementById(`customer-name-${row.id}`)
      if (element) {
        updatedOverflowingTexts[row.id] = element.scrollWidth > element.clientWidth
      }
    })
    setOverflowingTexts(updatedOverflowingTexts)
  }, [filteredRows])

  const UserRole = localStorage.getItem('roles')
  const isDisabled = !UserRole?.includes('Admin')

  const styles = {
    iconButtonStyles:{
      opacity: 1,
    }
    }

  return (
    <>
      {isGettingingProjectCustomers ? (
        <Box sx={style.boxCircularProgress}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box sx={style.cardBox}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {!filteredRows?.length  ? (
              <NoCards />
            ) : (
              filteredRows?.map((row: FormValues) => (
                <Grid item xs={12} sm={6} md={4} key={`${row.id}${row.mandate_name}`}>
                  <Card sx={style.cardStyle}>
                    <CardContent sx={style.cardContentStyle}>
                      <Box sx={style.cardContentBoxStyle}>
                        <Box width='70%'>
                          {overflowingTexts[row.id] ? (
                            <Tooltip
                              title={<Typography fontSize='1rem'> {row.mandate_name} </Typography>}
                            >
                              <Typography sx={style.cardContentTypoStyle}>
                                {row.mandate_name}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              id={`customer-name-${row.id}`}
                              noWrap
                              sx={style.cardContentTypoStyle}
                            >
                              {row.mandate_name}
                            </Typography>
                          )}
                        </Box>
                        <Box sx={style.iconBoxStyle}>
                          <Box sx={style.editIconsStyle}>
                            <IconButton
                              onClick={() => {
                                setEditCustomerData({
                                  id: row.id,
                                  mandate_name: row && row.mandate_name,
                                })
                                if (actionDialog === false) {
                                  setopenCustomerEditForm(true)
                                }
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Box sx={style.iconsStyle}>
                             <IconButton
                               onClick={() => {
                                deleteOpenConfirmationFunction(row.id, row.mandate_name)
                              }}
                              sx={styles.iconButtonStyles}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      )}
      {filteredRows && filteredRows?.length > 0 && (
        <Box sx={style.selectAndPaginationBox}>
          <Box>
            <ShowEntries rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
          </Box>
          <Pagination
            count={Math.ceil(projectCustomersData?.count / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color='primary'
          />
        </Box>
      )}
      <ConfirmDeleteDialog
        actionDialog={actionDialog}
        setActionDialog={setActionDialog}
        diaglogTitle={diaglogTitle}
        diaglogContent={diaglogContent}
        deleteFunction={deleteFunction}
      />
    </>
  )
}

const mapDispatchToProp = (dispatch: Dispatch) => {
  return {
    deleteProjectCustomer: (data: deleteProjectCustomerType) =>
      dispatch(deleteMandateType.request(data)),
  }
}

const mapStateToProp = (state: RootState) => {
  return {
    isGettingingProjectCustomers:
      projectManagementUI.getProjectManagemet(state).isGettingMandateType,
  }
}
export default connect(mapStateToProp, mapDispatchToProp)(Cards)
