import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
  } from '@mui/material'
  import TableRowContainer from './TableRowContainer'
import { style } from './SheetStyles'
import { StyledTableCell } from '../Common/CommonStyles'
 

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const getColumnData = [
  { id: "1", label: "EMP NAME (EMP ID)" },
  { id: "2", label: "SUBJECT" },
  { id: "3", label: "PROJECT NAME" },
  { id: "4", label: "TYPE" },
  { id: "5", label: "ESTIMATED TIME (MINS)" },
  { id: "6", label: "STATUS" },
];

const getColumnTimeSheetData = [
  { id: "1", label: "EMP NAME (EMP ID)" },
  { id: "2", label: "SUBJECT" },
  { id: "3", label: "PROJECT NAME" },
  { id: "4", label: "TYPE" },
  { id: "5", label: "TIME SPENT (MINS)" },
];

// selectedTab

  const TableLayout = (props: any) => {
    const { rowsToDisplay, onRowClick, selectedTab, mandateType} = props
    let statusType = new Map<number, string>()
    mandateType?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
      statusType.set(mandate.id, mandate.mandate_name);
    });
    
    return (
      <Box overflow='scroll' width= '100%' margin='20px  0px' sx={style.table}>
        <TableContainer component={Paper} id='export' sx={style.tableContainer}>
          <Table>
            <TableHead id='head'>
              <StyledTableRow sx={style.border}>
                {(selectedTab ===1 ? getColumnTimeSheetData : getColumnData)?.map((column: any) => (
                  <StyledTableCell key={column.id} sx={style.tableHeadWidth}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowsToDisplay?.map((row: any, idx: any) => (
                <TableRowContainer statusType = { statusType } key={idx} row={row} idx={idx} onRowClick={onRowClick}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
  
  export default TableLayout
  