import {
  Box,
  CircularProgress,
  Dialog,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import TableRowContainer from './TableRowContainer/TableRowContainer'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import ActionBar from '../ProjectResourceReport/ActionBar'
import { RootState } from '../../../configureStore'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import { style } from './ResourceReportStyle'
import { StyledTableCell, loaderProps } from '../../Common/CommonStyles'
import { getAllResourceReport, getAllResourceReportDropdownData } from '../../../actions'
import NoProjectRow from './NoRowBox/NoProjectRow'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface ResourcesReportData {
  getAllProjectResourceReport: any
  fetchResourceReport: any
  fetchSelectData: any
}

const styles = {
  backNavigate: {
    position: 'absolute',
    top: 80,
    right: 25,
    margin: '20px',
    cursor: 'pointer',
  },
  styledCells: {
    padding: '6px 6px',
  },
  typoIntablehead: {
    margin: '0',
    padding: '6px 2px',
  },
}

const ProjectResourceReport = (props: any) => {
  const getColumnData = [
    { id: '1', label: 'PROJECT NAME' },
    { id: '2', label: 'PROJECT SOURCE' },
    { id: '3', label: 'PROJECT DOMAIN' },
    { id: '4', label: 'CLIENTS' },
    { id: '5', label: 'PROJECT STATUS' },
    { id: '6', label: 'PROJECT LOCATION' },
    { id: '7', label: 'PROJECT RESOURCE' },
    { id: '8', label: 'RESOURCE TYPE' },
    { id: '9', label: 'IS BILLABLE' },
    { id: '10', label: 'START DATE' },
    { id: '11', label: 'END DATE' },
    { id: '12', label: 'EMP TYPE' },
    { id: '13', label: 'WORKED TIME (in hrs.)' },
    { id: '14', label: ' BALANCE TIME (in hrs.)' },
  ]

  const { getAllProjectResourceReport, fetchResourceReport, fetchSelectData, isLoadingStateTrue } =
    props

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [expand, setExpand] = useState(false)
  const [allData, setAllData] = useState(fetchResourceReport?.data?.projects ?? [])
  const selectData = fetchSelectData?.data ?? []
  const [startDate, setStartDate] = useState(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
  )
  const currentDate = dayjs()
  const lastDateOfMonth = currentDate.endOf('month')
  const [endDate, setEndDate] = useState(lastDateOfMonth)
  const [projectState, setProjectState] = useState('')
  const [location, setLocation] = useState('')
  const [employmentType, setEmploymentType] = useState('')
  const [selectForSource, setSelectForSource] = useState<string>('')

  useEffect(() => {
    getAllProjectResourceReport({
      startDate: startDate.format('YYYY-MM-DD').toString(),
      endDate: endDate.format('YYYY-MM-DD').toString(),
      project: projectState === 'all' ? '' : projectState,
      projectSource: selectForSource === 'all' ? '' : selectForSource,
      location: location === 'all' ? '' : location,
      employmentType: employmentType === 'all' ? '' : employmentType.toLowerCase(),
    })
  }, [
    selectForSource,
    projectState,
    page,
    rowsPerPage,
    location,
    employmentType,
    startDate,
    endDate,
  ])

  useEffect(() => {
    setAllData(fetchResourceReport?.projects ?? [])
  }, [fetchResourceReport, allData])

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage)
  }
  const startIndex = (page - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const rowsToDisplay = allData?.slice(startIndex, endIndex)

  const navigate = useNavigate()

  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)

  const getWidth = (index: number) => {
    switch(index) {
      case 3:
      case 4:
      case 7:
      case 10:
      case 12:
        return '50px';
      case 6:
      case 9:
        return '50px';
      case 8:
        return '50px';
      case 11:
        return '60px';
      default:
        return '140px';
    }
  };

  return (
    <Paper sx={{ ...style.container }}>
      <Box sx={styles.backNavigate} onClick={() => navigate(-1)}>
        <ArrowBack />
      </Box>
      <Box sx={style.pageHead}>
        <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
          <Typography width='fit-content' className='heading' sx={style.primaryHeading}>
            {' '}
            {CompanyName}{' '}
          </Typography>
          <Typography className='heading' sx={style.headerHeading}>
            {' '}
            Project Resources report from {startDate.format('MM/DD/YYYY')} to{' '}
            {endDate.format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </Box>
      <ActionBar
        selectData={selectData}
        expand={expand}
        setExpand={setExpand}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        projectState={projectState}
        location={location}
        employmentType={employmentType}
        setProjectState={setProjectState}
        setLocation={setLocation}
        setEmploymentType={setEmploymentType}
        setSelectForSource={setSelectForSource}
        selectForSource={selectForSource}
        fetchResourceReport={fetchResourceReport}
      />
      <Box>
        <Box width='98%' overflow='scroll' margin='20px  13px' sx={style.tableContainer}>
          <Dialog open={isLoadingStateTrue} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <TableContainer component={Paper} id='export'>
            <Table>
              <TableHead>
                <TableRow id='head' >
                  {getColumnData?.map((data: any, index: any) => (
                    <StyledTableCell sx={styles.styledCells}>
                      <Typography
        width={getWidth(index)}
        fontSize={'0.800rem'}
                        fontFamily={'Montserrat-Medium'}
                        sx={styles.typoIntablehead}
                      >
                        {data.label}
                      </Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsToDisplay?.map((row: any, idx: any) => {
                  return (
                    <TableRowContainer key={idx} row={row} idx={idx} resource={row.resources} />
                  )
                })}
              </TableBody>
            </Table>
            {rowsToDisplay.length === 0 && <NoProjectRow value={'project found for this date'} />}
          </TableContainer>
          {/* } */}
        </Box>
        {rowsToDisplay.length > 0 && (
          <Stack
            width='98.5%'
            spacing={2}
            direction='row'
            justifyContent='flex-end'
            margin={'15px 0'}
          >
            <Box width='auto' display='flex' alignItems='center'>
              <Pagination
                count={Math.ceil(fetchResourceReport?.totalProjects / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color='primary'
              />
            </Box>
          </Stack>
        )}
      </Box>
    </Paper>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    fetchResourceReport:
      projectManagementEntity.getAllProjectManagement(state).getResourceReportData,
    isLoadingStateTrue: projectManagementUI.getProjectManagemet(state).isLoadingStateTrue,
    resourceReportDropdownData:
      projectManagementEntity.getAllProjectManagement(state).getResourceReportDropdowndata,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllProjectResourceReport: (data: {
      startDate: string
      endDate: string
      project: string
      projectSource: string
      location: string
      employmentType: string
    }) => dispatch(getAllResourceReport.request(data)),
    projectResourceDropdownAPI: () => dispatch(getAllResourceReportDropdownData.request()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectResourceReport)
