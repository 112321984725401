import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  EMPLOYEE_HISTORY,
  FETCH_DESIGNATION,
  FETCH_DESIGNATIONBAND,
  FETCH_EMPINFO,
  FETCH_EMPLOYMENTTYPE,
  FETCH_QUALIFICATION,
  FETCH_QUALIFICATION_SKILL,
  FETCH_SAPERIOD,
  FETCH_TIMING,
  MANAGER_HISTORY,
} from '../actions/actiontypes'
import { send } from 'process'
const { REQUEST } = actionTypes

//write sagas function

function* handleGetEmpInfoData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmpInfoDataEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_EMPINFO)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_EMPINFO)
  }
}

function* handleGetDesignationData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDesignationEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_DESIGNATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DESIGNATION)
  }
}

function* handleGetDesignationBandData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDesignationBandEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_DESIGNATIONBAND)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DESIGNATIONBAND)
  }
}

function* handleGetEmployementTypeData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmployementTypeEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_EMPLOYMENTTYPE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_EMPLOYMENTTYPE)
  }
}

function* handleGetSAPeriodData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSAPeriodEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_SAPERIOD)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_SAPERIOD)
  }
}

function* handleGetTimingData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getTimingEndPoint)
    yield sendPayload(apiResponse, FETCH_TIMING)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_TIMING)
  }
}

function* handleQualification() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getQualificationEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_QUALIFICATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_QUALIFICATION)
  }
}

function* handleQualificationSkill() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getQualificationSkillEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_QUALIFICATION_SKILL)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_QUALIFICATION_SKILL)
  }
}

function* handleEmpHistory() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmpHistory,
  
    )
    yield sendPayload(apiResponse, EMPLOYEE_HISTORY)
  } catch (e) {
    yield sendPayloadFailure(e, EMPLOYEE_HISTORY)
  }
}

function* handleManagerHistory(){
  try{
    const apiResponse: Generator<string ,number, string> = yield call(
      ApiService.getManagerHistoryData,
    )
    yield sendPayload(apiResponse,MANAGER_HISTORY)
  } catch (e){
    yield sendPayloadFailure(e,MANAGER_HISTORY)
  }
}

export const sagas = {
  //watcher come here
  watchGetDesignationData: takeLatest(FETCH_DESIGNATION[REQUEST], handleGetDesignationData),
  watchGetDesignationBandData: takeLatest(
    FETCH_DESIGNATIONBAND[REQUEST],
    handleGetDesignationBandData,
  ),
  watchGetEmployementTypeData: takeLatest(
    FETCH_EMPLOYMENTTYPE[REQUEST],
    handleGetEmployementTypeData,
  ),
  watchGetSAPeriodData: takeLatest(FETCH_SAPERIOD[REQUEST], handleGetSAPeriodData),
  watchGetTimingData: takeLatest(FETCH_TIMING[REQUEST], handleGetTimingData),
  watchGetEmpInfoData: takeLatest(FETCH_EMPINFO[REQUEST], handleGetEmpInfoData),
  watchGetQualificationData: takeLatest(FETCH_QUALIFICATION[REQUEST], handleQualification),
  watchGetQualificationSkillData: takeLatest(
    FETCH_QUALIFICATION_SKILL[REQUEST],
    handleQualificationSkill,
  ),
  watchHandleEmpHistory: takeLatest(
    EMPLOYEE_HISTORY[REQUEST],
    handleEmpHistory,
  ),
  watchHandleManagerHistory: takeLatest(MANAGER_HISTORY[REQUEST],handleManagerHistory),
}
