import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { ADD_COMMENT_PLANFORTHEDAY, ADD_COMMENT_TIMESHEET, APPROVE_PLANFORTHEDAY, FETCH_ATTENDANCE, FETCH_ATTENDANCE_TIMESHEET, GET_COMMENT_PLANFORTHEDAY, GET_COMMENT_TIMESHEET } from '../actions/actiontypes'
const { REQUEST } = actionTypes

function* getEmployeeAttendance(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendance,
      data,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE)
  }
}

function* getCommentTimesheetSaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCommentTimesheet,
      data,
    )
    yield sendPayload(apiResponse, GET_COMMENT_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, GET_COMMENT_TIMESHEET)
  }
}

function* getCommentPlanForTheDaySaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCommentPlanForTheday,
      data,
    )
    yield sendPayload(apiResponse,GET_COMMENT_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, GET_COMMENT_PLANFORTHEDAY)
  }
}

function* getAttendanceTimesheet(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendanceTimesheet,
      data.userId,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE_TIMESHEET)
  }
}

function* createCommentPlanForTheDay(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createCommentPlanForTheDay,
      data
    )
    yield sendPayload(apiResponse, ADD_COMMENT_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_COMMENT_PLANFORTHEDAY)
  }
}

function* createCommentTimesheet(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createCommentTimesheet,
      data
    )
    yield sendPayload(apiResponse, ADD_COMMENT_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_COMMENT_TIMESHEET)
  }
}

function* approvePlanForTheDaySaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.approvePlanForTheDayAPI,
      data
    )
    yield sendPayload(apiResponse, APPROVE_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, APPROVE_PLANFORTHEDAY)
  }
}

export const sagas = {
  watchEmployeeAttendance: takeLatest(actionTypes.FETCH_ATTENDANCE[REQUEST], getEmployeeAttendance),
  watchGetCommentTimesheet: takeLatest(actionTypes.GET_COMMENT_TIMESHEET[REQUEST], getCommentTimesheetSaga),
  watchGetCommentPlanForTheDay: takeLatest(actionTypes.GET_COMMENT_PLANFORTHEDAY[REQUEST], getCommentPlanForTheDaySaga),
  watchAddCommentPlanForTheDay: takeLatest(actionTypes.ADD_COMMENT_PLANFORTHEDAY[REQUEST], createCommentPlanForTheDay),
  watchAddCommentTimesheet: takeLatest(actionTypes.ADD_COMMENT_TIMESHEET[REQUEST], createCommentTimesheet),
  watchApprovePlanForTheDay: takeLatest(actionTypes.APPROVE_PLANFORTHEDAY[REQUEST], approvePlanForTheDaySaga),
  watchAttendanceTimesheet: takeLatest(
    actionTypes.FETCH_ATTENDANCE_TIMESHEET[REQUEST],
    getAttendanceTimesheet,
  ),
}
