// import './IDSR.scss'
import { Box, IconButton, Typography, Modal, Tooltip } from '@mui/material'

import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import 'react-quill/dist/quill.snow.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const SheetOverview = (props: any) => {
  const handleCopyContent = () => {
    const stripHTML = (html: any) => {
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*')
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '')

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n')
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n')
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }
    const subject = props.selectedPlanForTheDay?.subject || ''
    const body = stripHTML(props.selectedPlanForTheDay?.body || '')
    const content = `${subject}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      props.setCopyTooltipOpen(true)
      setTimeout(() => {
        props.setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  return (
    <Modal
      open={!!props.selectedPlanForTheDay}
      onClose={props.handleClosePlanModal}
      sx={{ outline: 'none' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70vw',
          maxWidth: '75%',
          bgcolor: 'background.paper',
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
          borderRadius: '10px',
          outline: 'none',
        }}
      >
        <IconButton
          aria-label='close'
          onClick={props.handleClosePlanModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[600],
            marginTop: '15px',
            outline: 'none',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Tooltip
          title={props.copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'}
          open={props.hoverTooltipOpen || props.copyTooltipOpen}
        >
          <IconButton
            aria-label='copy'
            onClick={handleCopyContent}
            onMouseEnter={() => props.setHoverTooltipOpen(true)}
            onMouseLeave={() => props.setHoverTooltipOpen(false)}
            sx={{
              position: 'absolute',
              right: 50,
              top: 22,
              zIndex: 999,
              outline: 'none',
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Heading
          style={{
            fontSize: '18px',
            textAlign: 'center',
            fontFamily: 'sans-serif',
            letterSpacing: '0px',
            position: 'relative',
            outline: 'none',
          }}
          dangerouslySetInnerHTML={{
            __html: props.selectedPlanForTheDay?.subject || '',
          }}
        />
        <Typography
          variant='body1'
          style={{ fontSize: '12px', wordBreak: 'break-word', outline: 'none' }}
        >
          <div>{props.convertTextToHTML(props.selectedPlanForTheDay?.body || '')}</div>
          {props.selectedPlanForTheDay?.hasOwnProperty("approve_status") ? (props.selectedPlanForTheDay?.approve_status === 0 ? props.selectedPlanForTheDay?.comment && <Box width={'98%'} padding={'15px 0 0 0'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'}>
            <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment : </Typography>
            <Typography width={'100%'} border={'1px solid #D3D3D3'} padding={'0 10px'} sx={{
              wordWrap: 'break-word',
              fontSize: '12px',
              borderBottomColor: 'coral',
            }}>{convertTextToHTML(props.selectedPlanForTheDay?.comment || '')}</Typography>
          </Box> :
            <Box width={'98%'} padding={'15px 0 0 0'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'}>
              <Typography width={'100%'} border={'1px solid #D3D3D3'} padding={'10px'} sx={{
                wordWrap: 'break-word',
                fontSize: '15px',
                borderBottomColor: '#53AC6D',
              }}>Plan for the day is Approved! </Typography>
            </Box>
          ) : props.selectedPlanForTheDay?.comment ? <Box width={'98%'} padding={'15px 0 0 0'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'}> <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment : </Typography>
          <Typography width={'100%'} border={'1px solid #D3D3D3'} padding={'0 10px'} sx={{
            wordWrap: 'break-word',
            fontSize: '12px',
            borderBottomColor: 'coral',
          }}>{convertTextToHTML(props.selectedPlanForTheDay?.comment || '')}</Typography> </Box> : '' }
        </Typography>
      </Box>
    </Modal>
  )
}

export default SheetOverview
