import { saveAs } from "file-saver";
import { convertHtmlToString } from "./ConvertHtmlToString";

// Function to escape values for CSV
const escapeForCSV = (value: string): string => {
  if (!value) return '';
  // Escape double quotes by doubling them
  value = value.replace(/"/g, '""');
  // Encapsulate the value in double quotes if it contains commas, line breaks, or quotes
  if (/[",\n\r]/.test(value)) {
    value = `"${value}"`;
  }
  return value;
};

// Function to convert users data to CSV
export const convertToCSV = (users: any[], selectedTab: any): string => {

  let csvContent = "";

  // Add headers for CSV
  const headersForPlanForTheDay = [
    "Name (Employee ID)",
    "Subject",
    "Project Name",
    "Mandate Type",
    "Task Description",
    "Priority",
    "Estimated Time (mins)",
    "Status",
    "Comment",
  ];

  const headersForTimeSheet = [
    "Name (Employee ID)",
    "Subject",
    "Project Name",
    "Mandate Type",
    "Task Description",
    "Task Status",
    "Priority",
    "Time Spent (mins)",
    "Comment",
  ];

	

  csvContent += selectedTab === 1 ? headersForTimeSheet.join(",") + "\r\n" : headersForPlanForTheDay.join(",") + "\r\n"

  // Process each user
  users.forEach((user: any) => {
    console.log("user", user.approve_status)
    const parser = new DOMParser();
    const doc = parser.parseFromString(user.body, "text/html");
    const tables = doc.querySelectorAll("table");

    // Process each table
    tables.forEach((table) => {
      const rows = table.querySelectorAll("tr");

      // Process each row
      rows.forEach((row, rowIndex) => {
        const cols = row.querySelectorAll("td, th");
        let rowContent = [
          escapeForCSV(rowIndex === 1 ? `${user.name} (${user.employee_id})` ?? "" : '' ),
          escapeForCSV(rowIndex === 1 ? user.subject ?? "" : '' )
        ];

        // Only add data from table rows
        if (rowIndex > 0) { // Skip the header row in the table
          const cellContents: string[] = Array.from(cols).map((col: any) => escapeForCSV(col.innerText.trim()));
           
          // Extract the text content
          const text = escapeForCSV(convertHtmlToString(user?.comment));

          // Ensure that the rowContent length matches the number of expected columns
          const expectedColumns = (selectedTab === 1 ? headersForTimeSheet : headersForPlanForTheDay).length - rowContent.length; // Subtract pre-filled columns

          while (cellContents.length < expectedColumns) {
            if(selectedTab!==1) cellContents[5]=(user?.approve_status === 1 ? "Approved" : "Pending");
             cellContents[6]=(rowIndex === 1 ? text : '');
          }

          rowContent = rowContent.concat(cellContents);
          csvContent += rowContent.join(",") + "\r\n"; // Add the joined rowContent
        }
      });

    });

  });

  return csvContent;
};

export const downloadCSV = (users: any[], selectedTab: any): void => {
  const csv = convertToCSV(users, selectedTab);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, selectedTab === 1 ? 'timesheet.csv' : 'plan_for_the_day.csv');
};
