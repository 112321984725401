import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import { useEffect } from 'react'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { getLoanInstallment } from '../../actions'
import { LoanRequestEntity, dashboardUI } from '../../reducers'
import { loaderProps } from '../Common/CommonStyles'
import moment from 'moment'
import { Primary } from '../../utils'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '30px 48px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },

  '& .MuiButton-root:hover': {},
  '&.MuiButtonBase-root-MuiButton-root:hover': {},
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: '5px 1px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: '40px',
  marginRight: 'auto',
  marginBottom: '20px',
}))

const PaperBlock = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: '15px 15px',
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  overflow: 'auto',
}))

const SplitDiv: React.CSSProperties = {
  display: 'flex',
  width: '559px',
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    fontSize: '11px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    color: '#483f3f',
    height: 'auto',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {},
  '&:last-child td, &:last-child th': {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    border: '1px solid #d1cbcbf2',
    borderBottom: 'none',
  },
}))

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const NewLoanInstallmentHistoryDialog = ({
  rowData,
  open,
  onClose,
  loaderState,
  fetchLoanInstallmentHistoryData,
  LoanInstallmentHistoryData,
}: any) => {
  const useStyles = makeStyles({
    tableRow: {
      fontWeight: 'bold',
    },
  })
  const classes = useStyles()
  const loanId = rowData?.id

  useEffect(() => {
    if (loanId) {
      fetchLoanInstallmentHistoryData(loanId)
    }
  }, [loanId])

  const handleClose = () => {
    onClose()
  }

  return (
    <div style={{ width: '100%' }} id='pdf-export'>
      <>
        {loaderState && (
          <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        )}
      </>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Heading>Installment History</Heading>
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 12, md: 6 }}>
            <StyledPaper
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '20px',
              }}
            >
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Id:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.employeeId}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Name:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.employeeName}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Request Description:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.request_description}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Approval Description:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.approval_description}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Total Loan Amount (In Rs.):
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.loan_amount}
                </PaperBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Balance:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {LoanInstallmentHistoryData?.remainingBalance}
                </PaperBlock>
              </div>
            </StyledPaper>
            <TableContainer
              sx={{
                margin: '9px -25px 3px 17px',
              }}
              component={Paper}
            >
              <Table
                sx={{
                  minWidth: 530,
                }}
                aria-label='customized table'
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>LOAN / INSTALLMENT DATE</StyledTableCell>
                    <StyledTableCell>INSTALLMENT AMOUNT</StyledTableCell>
                    <StyledTableCell>BALANCE</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {LoanInstallmentHistoryData &&
                Object.keys(LoanInstallmentHistoryData).length &&
                LoanInstallmentHistoryData.userLoanRepayment.length > 0 ? (
                  LoanInstallmentHistoryData.userLoanRepayment.map((item: any) => (
                    <TableBody key={`${item.payment_date}${item.instalment_amount}`}>
                      <StyledTableRow>
                        <StyledTableCell component='th' scope='row'>
                          {moment(item.payment_date).format('DD-MM-YYYY')}
                        </StyledTableCell>
                        <StyledTableCell align='left'>{item.instalment_amount}</StyledTableCell>
                        <StyledTableCell align='left'>{item.balance}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    {LoanInstallmentHistoryData.loan_request === 0 && (
                      <StyledTableRow>
                        <StyledTableCell align='center' colSpan={10}>
                          <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                            No matching records found.
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                )}
                {LoanInstallmentHistoryData.loan_request === 1 && (
                  <>
                    <StyledTableRow>
                      <StyledTableCell component='th' scope='row' className={classes.tableRow}>
                        Total:
                      </StyledTableCell>
                      <StyledTableCell className={classes.tableRow} align='left'>
                        {LoanInstallmentHistoryData?.totalPayedAmount}
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row' align='left'>
                        {LoanInstallmentHistoryData?.remainingBalance}
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </Table>
            </TableContainer>
            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    LoanInstallmentHistoryData: LoanRequestEntity.sendLoanRequestData(state).getLoanInstallment,
    loaderState: dashboardUI.getDashboard(state).isCommentData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchLoanInstallmentHistoryData: (data: any) => {
      dispatch(getLoanInstallment.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLoanInstallmentHistoryDialog)
