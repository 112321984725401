import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import {
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { Primary } from '../../utils'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import {
  employeeHistory,
  fetchDesignation,
  fetchDesignationBand,
  fetchEmpInfo,
  fetchEmplymentType,
  fetchSAPeriod,
  fetchTiming,
  getManagerDataById,
  getRefDataById,
  managerHistory,
} from '../../actions'
import { dashboardEntity, dashboardUI, employeePortalEntity, employeePortalUI } from '../../reducers'
import getHardCodedData from './Info.json'
import { StyledTableCell, loaderProps } from '../Common/CommonStyles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '85%',
  padding: '30px 25px 30px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  border: '1px solid #DDDDDD',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    cursor: 'not-allowed',
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
    fontSize: '14px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const HeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
  margin: '15px 0',
}))

const TableHeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  color: 'black',
  padding: '0px 0px 20px',
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    cursor: 'not-allowed',
    padding: '13px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat-Medium',
    fontSize: '14px',
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  paddingLeft: '10px',
  fontSize: '13px',
  marginLeft: '10px',
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '13px',
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: '13px',
  },
}))

const Label = styled(FormLabel)({
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  opacity: '1',
  color: 'black',
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

export interface EmpHistoryDataTypes {
  start_date: string;  
  end_date: string;    
  previous_designation: string;
  managers: string[];
}

export interface EmpManagerDataTypes {
  start_date: string;  
  end_date: string;    
  managers: string[];
}

const BasicInfo = (props: any) => {
  const data = props.json
  const isDisabled = true

  return (
    <>
      <StyledPaper elevation={0}>
        <HeaderHeading sx={{ margin: '0px 0px 30px 0px' }}>Employee Information</HeaderHeading>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              InputProps={{
                readOnly: true,
              }}
              label='Aadhaar Number'
              value={data.aadhaar_card_no ?? 'NA'}
              size='small'
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='PAN Number'
              value={data.pan_card_no ? data.pan_card_no : 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <InputField
              label='Passport Number'
              value={data.passport_no ? data.passport_no : 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ required: false }}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <StyledFormControl>
              <StyledFormLabel>PF Applicable</StyledFormLabel>
              <RadioGroup row>
                <StyledFormControlLabel
                  value='Yes'
                  control={<Radio />}
                  label='Yes'
                  checked={data.pf_applicable === 1}
                  disabled={isDisabled}
                />
                <StyledFormControlLabel
                  value='No'
                  control={<Radio />}
                  label='No'
                  checked={data.pf_applicable === 0}
                  disabled={isDisabled}
                />
              </RadioGroup>
            </StyledFormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='PF Number'
              value={data.pf_no ?? 'NA'}
              size='small'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              InputLabelProps={{ required: false }}
              label='UAN Number'
              value={data.uan_no ?? 'NA'}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  )
}

const ProfessionalInfo = (props: any) => {
  useEffect(() => {
    // Set the value of temp when the component mounts
    setFirstWeekend(props.json.first_weekend)
    setSecondWeekend(props.json.second_weekend)
    setSAmount(props.json.service_agreement_amount)
    setWorkingExperience(props.json.working_experience)
  }, [
    props.json.first_weekend,
    props.json.second_weekend,
    props.json.service_agreement_amount,
    props.json.working_experience,
  ])
  const { getAllWorkingExperience } = getHardCodedData
  const { getAllWeek } = getHardCodedData
  const data = props.json

  const designation = props.designation
    .filter((user: any) => user.id === data.id_grade)
    .map((user: any) => `${user.grade_name}-${user.desc}`)
    .join('-')

  const designationband = props.designationband
    .filter((user: any) => user.id === data.designation_band)
    .map((user: any) => user.band_title)

  const service_agreement_period = props.service_agreement_period
    .filter((user: any) => user.id === data.id_service_agreement)
    .map((user: any) => user.month)

  const isDisabled = true
  const [manager, setManager] = useState(props.getUserById?.id || 'NA')
  const [firstWeekend, setFirstWeekend] = useState('')
  const [secondWeekend, setSecondWeekend] = useState('')
  const [SAAmount, setSAmount] = useState('')
  const [workingexperience, setWorkingExperience] = useState(props.json.working_experience || 'NA')
  const inputDateStr = data.hire_date
  const dateObject = new Date(inputDateStr)
  const formattedDate = dateObject.toLocaleDateString('en-US')

  useEffect(() => {
    setManager(props?.getUserById?.id || 'NA')
  }, [props.getUserById?.id, props.getReferredById?.id])

  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '-1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Designation</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={designation}
              label='Designation'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={designation}>{designation}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Designation Band</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={designationband}
              label='Designation Band'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={designationband}>{designationband}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Manager</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={manager}
              label='Manager'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value={'NA'}>{'NA'}</MenuItem>
              <MenuItem value={props.getUserById?.id}>{props.getUserById?.name}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>First Weekend</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={firstWeekend}
              label='First Weekend'
              inputProps={{ readOnly: true }}
            >
              {getAllWeek.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Second Weekend</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              label='Second Weekend'
              value={secondWeekend}
              inputProps={{ readOnly: true }}
            >
              {getAllWeek.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            label='Hire Date'
            value={formattedDate || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Experience While Joining</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={workingexperience}
              label='Experience While Joining'
              inputProps={{ readOnly: true }}
            >
              {getAllWorkingExperience.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Service Agreement Period</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={service_agreement_period}
              label='Service Agreement Period'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={service_agreement_period}>
                {service_agreement_period + ' month'}
              </MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            InputLabelProps={{ required: false }}
            required
            label='Service Agreement Amount'
            value={SAAmount || 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Notice</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.under_maternity === 1}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.under_maternity === 0}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Notice Period</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={props.json.noticePeriod}
              label='Notice Period'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>1 months</MenuItem>
              <MenuItem value={2}>2 months</MenuItem>
              <MenuItem value={3}>3 months</MenuItem>
              <MenuItem value={4}>4 months</MenuItem>
              <MenuItem value={5}>5 months</MenuItem>
              <MenuItem value={6}>6 months</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Type of Relieving</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={props.json.termination_type}
              label='Type of Relieving'
              inputProps={{ readOnly: true }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Select</MenuItem>
              <MenuItem value={1}>Terminated</MenuItem>
              <MenuItem value={2}>Absconded</MenuItem>
              <MenuItem value={3}>Relieved</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const OtherInfo = (props: any) => {
  const { getAllTshirtSize } = getHardCodedData
  const [tshirtSize, setTshirtSize] = useState('')
  const data = props.json
  useEffect(() => {
    setTshirtSize(props.json.tshirt_size)
  }, [props.json.tshirt_size])
  const isDisabled = true
  const [refer, setRefer] = useState(props.getReferredById?.id || 'NA')
  const poorPerformanceReview = props.json.poor_performance_review === 1 ? 'Yes' : 'No'

  const handleRefer = (event: any) => {
    setRefer(event.target.value)
  }

  useEffect(() => {
    setRefer(props.getReferredById?.id || 'NA')
  }, [props.getUserById?.id, props.getReferredById?.id])

  return (
    <StyledPaper elevation={0}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>First Performance Review</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.first_performance_review === 1}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.first_performance_review === 0}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>ID Card Issued</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.id_card_issued === 1}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.id_card_issued === 0}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Mediclaim Issued</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.mediclaim_issued === 1}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.mediclaim_issued === 0}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Performance Improvement Plan</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={poorPerformanceReview === 'Yes'}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={poorPerformanceReview === 'No'}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <StyledFormControl>
            <StyledFormLabel>Under Maternity</StyledFormLabel>
            <RadioGroup row>
              <StyledFormControlLabel
                value='Yes'
                control={<Radio />}
                label='Yes'
                checked={data.under_maternity === 1}
                disabled={isDisabled}
              />
              <StyledFormControlLabel
                value='No'
                control={<Radio />}
                label='No'
                checked={data.under_maternity === 0}
                disabled={isDisabled}
              />
            </RadioGroup>
          </StyledFormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <InputField
            label='Mediclaim Number'
            value={data.mediclaim_no ? data.mediclaim_no : 'NA'}
            size='small'
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ required: false }}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-readonly-label'>T-shirt Size</InputLabel>
            <SelectField
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={tshirtSize}
              label='T-shirt Size'
              inputProps={{ readOnly: true }}
            >
              {getAllTshirtSize.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </SelectField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl sx={{ m: 1, minWidth: 120, marginLeft: '-1px' }}>
            <InputLabel id='demo-simple-select-readonly-label'>Referred By</InputLabel>
            <SelectField
              required
              labelId='demo-simple-select-readonly-label'
              id='demo-simple-select-readonly'
              value={refer}
              label='Referred By'
              onChange={handleRefer}
              inputProps={{ readOnly: true }}
            >
              <MenuItem value={'NA'}>{'NA'}</MenuItem>
              <MenuItem value={props.getReferredById?.id}>{props.getReferredById?.name}</MenuItem>
            </SelectField>
          </FormControl>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}


const EmpHistory =(props: any)=>{
  const {getEmpHistoryData,isGettingEmpHistoryData} = props

  return(
    <>
    <StyledPaper>
    <TableHeaderHeading>Employee Designation History</TableHeaderHeading>

      <Dialog open={isGettingEmpHistoryData} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>DESIGNATION</StyledTableCell>  
              <StyledTableCell> DESIGNATION SINCE</StyledTableCell>
              <StyledTableCell> TILL DATE</StyledTableCell>            
            </StyledTableRow>
          </TableHead>
          <TableBody>
          { getEmpHistoryData?.length > 0 ? (
              getEmpHistoryData?.map((data: any, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell>{data.previous_designation}{data?.band ? ` (${data?.band})` : ''}</TableCell>
                  <TableCell>
                  {data.start_date ? 
                  `${new Date(data.start_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                      })}` : "NA"}
                  </TableCell>
                  <TableCell>
                  { data.end_date ?
                  `${new Date(data.end_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                      })}` : "NA"}
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <Typography align='center'>No data available</Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </StyledPaper>
    </>
  )
}

const ManagerHistory =(props: any)=>{

  const {managerHistoryData,isGettingmManagerHistoryData} = props

  return(
    <>
    <StyledPaper>
    <TableHeaderHeading>Employee Manager History</TableHeaderHeading>

      <Dialog open={isGettingmManagerHistoryData} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>MANAGER</StyledTableCell>
              <StyledTableCell> MANAGER SINCE</StyledTableCell>
              <StyledTableCell> TILL DATE</StyledTableCell>
              
            </StyledTableRow>
          </TableHead>
          <TableBody>
          { managerHistoryData?.length > 0 ? (
              managerHistoryData?.map((data: any, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell>{data.manager_name}</TableCell>
                  <TableCell>
                  {data.start_date ? 
                  `${new Date(data.start_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                      })}` : "NA"}
                  </TableCell>
                  <TableCell>
                  { data.end_date ?
                  `${new Date(data.end_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                      })}` : "NA"}
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <Typography align='center'>No data available</Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </StyledPaper>
    </>
  )
}

const Info = (props: any) => {
  const {
    loaderState,
    fetchUserData,
    userData,
    fetchDesignationData,
    designationData,
    fetchDesignationBandData,
    designationBandData,
    fetchEmploymentTypeData,
    employmentTypeData,
    fetchSAPeriodData,
    saPeriodData,
    timingData,
    getManagerDataById,
    getRefDataById,
    getUserById,
    getReferredById,
    empHistoryApi,
    getEmpHistoryData,
    isGettingEmpHistoryData,
    managerHistoryApi,
    getManagerHistoryData,
    isGettingmManagerHistoryData

  } = props
  useEffect(() => {
    fetchUserData()
    fetchDesignationData()
    fetchDesignationBandData()
    fetchEmploymentTypeData()
    fetchSAPeriodData()
    empHistoryApi()
    managerHistoryApi()
    // fetchTimingData()
  }, [])
  // Inside the component Info
  useEffect(() => {
    if (userData.id_manager) {
      getManagerDataById({ id: userData.id_manager })
    }
  }, [userData.id_manager])

  useEffect(() => {
    if (userData.referred_by) {
      getRefDataById({ id: userData.referred_by })
    }
  }, [userData.referred_by])

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div
          style={{
            backgroundColor: 'rgb(231, 235, 240)',
            width: '100%',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BasicInfo json={userData} />
              <ProfessionalInfo
                json={userData}
                timing_id={timingData}
                service_agreement_period={saPeriodData}
                designation={designationData}
                designationband={designationBandData}
                employment_type={employmentTypeData}
                getUserById={getUserById}
                getReferredById={getReferredById}
              />
              <OtherInfo
                json={userData}
                getReferredById={getReferredById}
                getUserById={getUserById}
              />
              <EmpHistory
              getEmpHistoryData={getEmpHistoryData}
              isGettingEmpHistoryData={isGettingEmpHistoryData}
              />
              <ManagerHistory
              managerHistoryData={getManagerHistoryData}
              isGettingmManagerHistoryData={isGettingmManagerHistoryData}
              />
              {/* <OfficeTimingLogs empData={userData} /> */}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: employeePortalEntity.getEmployeePortal(state).getEmpInfoData,
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    employmentTypeData: employeePortalEntity.getEmployeePortal(state).getEmploymentTypeData,
    saPeriodData: employeePortalEntity.getEmployeePortal(state).getSAPeriodData,
    timingData: employeePortalEntity.getEmployeePortal(state).getTimingData,
    getUserById: dashboardEntity.getDashboard(state).getManagerDataById,
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    getReferredById: dashboardEntity.getDashboard(state).getRefDataById,
    loaderState: dashboardUI.getDashboard(state).isGetEmpInfoData,
    getEmpHistoryData: employeePortalEntity.getEmployeePortal(state).getEmpHistoryData,
    getManagerHistoryData: employeePortalEntity.getEmployeePortal(state).getManagerHistoryData,
    isGettingEmpHistoryData:employeePortalUI.getAllEmpPortalUI(state).isGettingEmpHistoryData,
    isGettingmManagerHistoryData: employeePortalUI.getAllEmpPortalUI(state).isGettingmManagerHistoryData
  }
}

// Define mapDispatchToProps function
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    empHistoryApi: (data: EmpHistoryDataTypes)=> dispatch(employeeHistory.request(data)),
    managerHistoryApi:(data: EmpManagerDataTypes)=> dispatch(managerHistory.request(data)),
    fetchUserData: () => dispatch(fetchEmpInfo.request()),
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchEmploymentTypeData: () => dispatch(fetchEmplymentType.request()),
    fetchSAPeriodData: () => dispatch(fetchSAPeriod.request()),
    fetchTimingData: () => dispatch(fetchTiming.request()),
    getManagerDataById: (data: any) => {
      dispatch(getManagerDataById.request(data))
    },
    getRefDataById: (data: any) => {
      dispatch(getRefDataById.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)
