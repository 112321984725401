import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Box, CircularProgress, Dialog, ThemeProvider } from '@mui/material'
import {
  AppBarTheme,
  HeaderLogo,
  HeaderProfileName,
  HeaderRightContent,
  HeaderToolbar,
  loaderProps,
} from './CommonStyles'
import { useNavigate } from 'react-router-dom'

import SearchBox from './SearchBox'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import { connect, useSelector } from 'react-redux'
import { companyId, companyLogo, getUserDetails, getUserLoginUser } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { googleLogout } from '@react-oauth/google'

const settings = ['Sign Out']

function Header({
  userDetails,
  getUser,
  getUserLogOut,
  companyLogoApi,
  companyLogo,
  companyIdApi,
  companyIds,
  isGettingCompaniesId,
  resetCompanyIdApi,
  isGettingLogo,
}: any) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [processing, setProcessing] = React.useState<boolean>(false)

  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)


  localStorage.setItem('roles', userDetails?.roles)

  const getCompanyId = (CompanyName: string) => {
    const Company = companyIds?.find(
      (name: { company_name: string; id: number }) => name.company_name === CompanyName,
    )
    return Company ? Company.id : ''
  }

  const CompanyId = getCompanyId(CompanyName)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const navigate = useNavigate()

  const handleCloseUserMenu = () => {
    setProcessing(true)
    getUserLogOut()
    googleLogout()
    localStorage.clear()
    setTimeout(() => {
      setProcessing(false)
      navigate('/')
    }, 3000)
    setAnchorElUser(null)
  }

  React.useEffect(() => {
    getUser();
    companyIdApi();
  }, [getUser, companyIdApi]);

  React.useEffect(() => {
    if (isGettingCompaniesId && CompanyId) {
      companyLogoApi({ id: CompanyId });
      resetCompanyIdApi();
    }
  }, [isGettingCompaniesId, CompanyId, companyLogoApi, resetCompanyIdApi]);


  return (
    <>
      {(processing || isGettingLogo) && (
        <Dialog open={processing || isGettingLogo} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <ThemeProvider theme={AppBarTheme}>
        <AppBar position='static'>
          <Container maxWidth='xl'>
            <HeaderToolbar>
              <Box>
                <a style={{ margin: -10 }} href='/home/dashboard'>
                  <HeaderLogo src={companyLogo} />
                </a>
              </Box>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                <SearchBox />
              </Box>

              <HeaderRightContent>
                <span style={HeaderProfileName}> {userDetails.name} </span>
                <Tooltip title=''>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt='Remy Sharp'
                      src={
                        userDetails?.image_path ? userDetails?.image_path : CurrentLoginUserProfile
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={(e) => setAnchorElUser(null)}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign='center'>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </HeaderRightContent>
            </HeaderToolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    companyLogo: dashboardEntity.getDashboard(state).getLogo,
    companyIds: dashboardEntity.getDashboard(state).getCompanyId,
    isGettingCompaniesId: dashboardUI.getDashboard(state).isGettingCompaniesId,
    isGettingLogo: dashboardUI.getDashboard(state).isGettingCompanyLogo
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getUser: () => {
      dispatch(getUserDetails.request())
    },
    getUserLogOut: () => {
      dispatch(getUserLoginUser.reset())
    },
    companyLogoApi: (data: any) => {
      dispatch(companyLogo.request(data))
    },
    companyIdApi: () => {
      dispatch(companyId.request())
    },
    resetCompanyIdApi: () => {
      dispatch(companyId.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
