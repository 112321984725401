import * as Yup from 'yup'

export const validationSchema = Yup.object({
  first_name: Yup.string().required('Required'),
  middle_name: Yup.string(),
  last_name: Yup.string().required('Required'),
  father_name: Yup.string().required('Required'),
  mother_name: Yup.string().required('Required'),
  employee_id: Yup.string().required('Required'),
  client_location: Yup.string().required('Required'),
  location_client: Yup.number().required('Required'),
  under_prob: Yup.number().required('Required'),
  probation_period: Yup.string().required('Required'),
  location: Yup.number().required('Required'),
  under_probation: Yup.number().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  floor: Yup.string().required('Required'),
  work_station: Yup.string().required('Required'),

  designation: Yup.string().required('Required'),
  desig_band: Yup.string().required('Required'),
  manager: Yup.string().required('Required'),

  telephone_no: Yup.string().required('Required'),
  mobile_no: Yup.string().required('Required'),
  spouse_mobile: Yup.string().required('Required'),
  emergency_contact_phone: Yup.string().required('Required'),
  loginId: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  confirm_password: Yup.string().required('Required'),
  status: Yup.number().required('Required'),
  login_id: Yup.string().required('Required'),
  present_address: Yup.string().required('Required'),
  present_city: Yup.string().required('Required'),
  present_postal_code: Yup.string().required('Required'),
  present_state: Yup.string().required('Required'),

  permanent_address: Yup.string().required('Required'),
  permanent_city: Yup.string().required('Required'),
  permanent_postal_code: Yup.string().required('Required'),
  permanent_state: Yup.string().required('Required'),
  id_country_permanent: Yup.string().required('Required'),
  official_birth_date: Yup.date().required('Required'),
  actual_birth_date: Yup.date().required('Required'),
  birth_date: Yup.date().required('Required'),
  gender: Yup.string().required('Required'),
  age: Yup.number().required('Required'),
  marital_status: Yup.string().required('Required'),
  blood_group: Yup.string().required('Required'),
  emergency_contact_person: Yup.string().required('Required'),
  next_kin: Yup.string().required('Required'),
  spouse_name: Yup.string().required('Required'),

  floor_id: Yup.number().required('Required'),
  citizenship: Yup.string().required('Required'),
  id_relationship: Yup.string().required('Required'),
  dependentname: Yup.string().required('Required'),
  is_deleted: Yup.string().required('Required'),
  id_country: Yup.string().required('Required'),
  id_roles: Yup.array().of(Yup.number()).min(1, 'At least one role is required'),
  track_attendance: Yup.number().required('Required'),
  Workstation: Yup.number().required('Required'),
  dependents: Yup.array().of(
    Yup.object({
      name: Yup.string().required('Name is required'),
      relationship: Yup.string().required('Relationship is required'),
      age: Yup.number().required('Age is required').positive().integer(),
    }),
  ),
})