import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import PendingIcon from '@mui/icons-material/Pending'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Box
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  HeaderHeading,
  SearchBoxCustom,
  SearchIconStyle,
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { acceptLeaveData, getLeaveData, getLeaveTimesheets, rejectLeaveData } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { SelectField } from '../Common/ComponentCommonStyles'
import moment from 'moment'
import { toast } from 'react-toastify'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '23px 23px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const DrsListTableData = ({
  acceptLeave,
  isLeaveAcceptOrReject,
  isGettingLeaveData,
  LeaveData,
  timesheetData,
  fetchLeaveData,
  isFinancial,
}: {
  acceptLeave: any
  rejectLeave: any
  acceptLeaveData: any
  rejectLeaveData: any
  isLeaveAcceptOrReject: any
  isGettingLeaveData: any
  LeaveData: any
  timesheetData: any
  fetchLeaveData: any
  isFinancial:boolean
}) => {
  const useStyles = makeStyles({
    checkIcon: {
      color: 'green',
      marginLeft: 12,
    },
    crossIcon: {
      color: 'red',
      marginLeft: 12,
    },
    pendingIcon: {
      marginLeft: 12,
      color: 'brown',
    },

    heading: {
      marginLeft: 80,
    },
    headingFinancial: {
      marginTop: 50,
    },
    textField: {
      borderRadius: '4px',
    },
    dialogTitle: {
      fontWeight: 'normal',
    },
    dialogTitleName: {
      fontWeight: 'bold',
    },
    boxContainer: {
      textAlign: 'left',
    },
    boxSubContainer: {
      float: 'right',
      marginRight: '8px',
      marginTop: '0px',
    },
    noCursor: {
      cursor: 'auto !important',
    },
    cursor: {
      cursor: 'pointer !important',
    },
  })
  const classes = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [leaveId, setLeaveId] = useState('') 
  const [selectedLeaveDateOption, setLeaveDateOption] = useState('')
  const [status, setStatus] = useState(0)
  const [timeSheetId, setTimesheetId] = useState()

  useEffect(() => {
    if (isGettingLeaveData) {
      let date = new Date()
      let currentMonth = date.getMonth()
      let data = timesheetData.find(
        (sheet: any) => new Date(sheet.start_date).getMonth() === currentMonth,
      )
      fetchLeaveData({
        status,
        timeSheetId: data?.id,
      })
      setTimesheetId(data?.id)
      setLeaveDateOption(data?.id)
    }
    if (isLeaveAcceptOrReject) {
      fetchLeaveData({ status, timeSheetId })
    }
  }, [isGettingLeaveData, isLeaveAcceptOrReject])

  const handleLeaveStatusChange = (event: any) => {
    const status = event.target.value
    setStatus(status)
    fetchLeaveData({
      status,
      timeSheetId,
    })
  }

  const handleLeaveDateChange = (event: any, timesheets: any[]) => {
    const selectedId = event.target.value
    const selectedTimesheet = timesheets?.find((timesheet) => timesheet?.id === selectedId)
    if (selectedTimesheet) {
      setLeaveDateOption(selectedTimesheet?.id)
      setTimesheetId(selectedTimesheet?.id)
      fetchLeaveData({
        status,
        timeSheetId: selectedTimesheet?.id,
      })
    }
  }

  const handleOpen = (type: any, employeeName: any, leaveId: any, leaveStatus: any) => {
    setActionType(type)
    setOpen(true)
    setEmployeeName(employeeName)
    setLeaveId(leaveId)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    if (actionType === 'accept') {
      acceptLeave({ leaveId, leaveStatus: 1 })
      setLeaveId('')
      toast.success('Leave Accepted.')
    } else if (actionType === 'reject') {
      acceptLeave({ leaveId, leaveStatus: 2 })
      setLeaveId('')
      toast.success('Leave Rejected.')
    }
    setOpen(false)
  }

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])

  useEffect(() => {
    const filteredLeaveData = LeaveData?.leaveData?.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
    setFilteredRows(filteredLeaveData);
  }, [searchQuery, LeaveData]);
  

  const displayedRows = searchQuery ? filteredRows : LeaveData.leaveData;
  const rowsToDisplay = displayedRows?.slice()

  const aprilMonthLeave = searchQuery ? filteredRows : LeaveData.AprilData;
  const aprilMonthLeaveData = aprilMonthLeave?.slice()
  
    
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>
          {actionType === 'accept' ? (
            <>
              Are you sure you want to accept{' '}
              <span className={classes.dialogTitleName}>{employeeName}'s</span> leave?
            </>
          ) : (
            <>
              Are you sure you want to reject{' '}
              <span className={classes.dialogTitleName}>{employeeName}'s</span> leave?
            </>
          )}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>CANCEL</CancelButton>
          <ActionButton onClick={handleConfirm}>OK</ActionButton>
        </DialogActions>
      </Dialog>

      <Box className={classes.boxContainer}>
        <Box className={classes.boxSubContainer} onClick={() => navigate(-1)}>
          <ArrowBack />
        </Box>
      </Box>
      <HeaderHeading className={classes.heading}>DRs On Leave In Selected Month</HeaderHeading>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchBoxCustom
          id='outlined-basic'
          placeholder='Search'
          variant='outlined'
          size='small'
          fullWidth
          value={searchQuery}
          onChange={(e: any) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <SearchIconStyle />,
          }}
        />

        <Box sx={{ width: '220px', position: 'relative', right: '10px' }}>
          <StyledFormControl>
            <InputLabel id='demo-simple-select-readonly-label'>Select Status</InputLabel>
            <SelectField
              sx={{ borderRadius: '22px' }}
              variant='outlined'
              value={status}
              label='Select Status:'
              onChange={(event) => handleLeaveStatusChange(event)}
            >
              <MenuItem value={0}>Pending</MenuItem>
              <MenuItem value={1}>Accepted</MenuItem>
              <MenuItem value={2}>Rejected</MenuItem>
            </SelectField>
          </StyledFormControl>
        </Box>

        <Box sx={{ width: '160px' }}>
          <StyledFormControl>
            <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
            <SelectField
              sx={{ borderRadius: '22px' }}
              variant='outlined'
              value={selectedLeaveDateOption}
              label='Select Month:'
              onChange={(event) => handleLeaveDateChange(event, timesheetData)}
            >
              {timesheetData?.map((option: any) => (
                <StyledMenuItem key={`${option?.start_date}+${option?.id}`} value={option?.id}>
                  {moment(option?.start_date).format('MMMM YYYY')}
                </StyledMenuItem>
              ))}
            </SelectField>
          </StyledFormControl>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{}}>
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label='customized table'
        >
          <TableHead>
            <StyledTableRow className={classes.noCursor}>
              <StyledTableCell>EMPLOYEE ID</StyledTableCell>
              <StyledTableCell>EMPLOYEE NAME</StyledTableCell>
              <StyledTableCell>LEAVE START DATE</StyledTableCell>
              <StyledTableCell>LEAVE END DATE</StyledTableCell>
              <StyledTableCell>DESCRIPTION</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>ACTION</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {rowsToDisplay && rowsToDisplay?.length ? (
            <TableBody>
              {rowsToDisplay?.map((item: any) => (
                <StyledTableRow key={`${item.name}+${item.id_employee}`} className={classes.noCursor}>
                  <StyledTableCell>{item.id_employee}</StyledTableCell>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(item.leave_start_date).toLocaleDateString('en-GB')}
                  </StyledTableCell>{' '}
                  <StyledTableCell>
                    {new Date(item.leave_end_date).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ maxWidth: '350px' }}>{item.desc}</div>
                  </StyledTableCell>
                  <StyledTableCell>
                    {' '}
                    {item.status === 1 ? (
                      <Tooltip title='Accepted'>
                        <CheckIcon className={classes.checkIcon} />
                      </Tooltip>
                    ) : item.status === 2 ? (
                      <Tooltip title='Rejected'>
                        <CloseIcon className={classes.crossIcon} />
                      </Tooltip>
                    ) : (
                      <Tooltip title='Pending'>
                        <PendingIcon className={classes.pendingIcon} />
                      </Tooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={classes.cursor}>
                    {item.status === 2 ? (
                      <>
                        <Tooltip title='Accept'>
                          <CheckIcon
                            onClick={() => handleOpen('accept', item.name, item.id, item.status)}
                            className={classes.checkIcon}
                          />
                        </Tooltip>
                      </>
                    ) : item.status === 1 ? (
                      <>
                        <Tooltip title='Reject'>
                          <CloseIcon
                            onClick={() => handleOpen('reject', item.name, item.id, item.status)}
                            className={classes.crossIcon}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title='Accept'>
                          <CheckIcon
                            onClick={() => handleOpen('accept', item.name, item.id, item.status)}
                            className={classes.checkIcon}
                          />
                        </Tooltip>
                        <Tooltip title='Reject'>
                          <CloseIcon
                            onClick={() => handleOpen('reject', item.name, item.id, item.status)}
                            className={classes.crossIcon}
                          />
                        </Tooltip>
                      </>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography align='center' variant='subtitle1' sx={{ color: '#483f3f' }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isFinancial && (
        <>
          <HeaderHeading className={classes.heading} sx={{ marginTop: 4 }}>Future Financial Year</HeaderHeading>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table
              sx={{
                minWidth: 700,
              }}
              aria-label='customized table'
            >
              <TableHead>
                <StyledTableRow className={classes.noCursor}>
                  <StyledTableCell>EMPLOYEE ID</StyledTableCell>
                  <StyledTableCell>EMPLOYEE NAME</StyledTableCell>
                  <StyledTableCell>LEAVE START DATE</StyledTableCell>
                  <StyledTableCell>LEAVE END DATE</StyledTableCell>
                  <StyledTableCell>DESCRIPTION</StyledTableCell>
                  <StyledTableCell>STATUS</StyledTableCell>
                  <StyledTableCell>ACTION</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {aprilMonthLeaveData && aprilMonthLeaveData?.length ? (
                <TableBody>
                  {aprilMonthLeaveData.map((item: any) => (
                    <StyledTableRow key={`${item.name}${item.id_employee}`} className={classes.noCursor}>
                      <StyledTableCell>{item.id_employee}</StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(item.leave_start_date).toLocaleDateString('en-GB')}
                      </StyledTableCell>{' '}
                      <StyledTableCell>
                        {new Date(item.leave_end_date).toLocaleDateString('en-GB')}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div style={{ maxWidth: '350px' }}>{item.desc}</div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {' '}
                        {item.status === 1 ? (
                          <Tooltip title='Accepted'>
                            <CheckIcon className={classes.checkIcon} />
                          </Tooltip>
                        ) : item.status === 2 ? (
                          <Tooltip title='Rejected'>
                            <CloseIcon className={classes.crossIcon} />
                          </Tooltip>
                        ) : (
                          <Tooltip title='Pending'>
                            <PendingIcon className={classes.pendingIcon} />
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell className={classes.cursor}>
                        {item.status === 2 ? (
                          <>
                            <Tooltip title='Accept'>
                              <CheckIcon
                                onClick={() => handleOpen('accept', item.name, item.id, item.status)}
                                className={classes.checkIcon}
                              />
                            </Tooltip>
                          </>
                        ) : item.status === 1 ? (
                          <>
                            <Tooltip title='Reject'>
                              <CloseIcon
                                onClick={() => handleOpen('reject', item.name, item.id, item.status)}
                                className={classes.crossIcon}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip title='Accept'>
                              <CheckIcon
                                onClick={() => handleOpen('accept', item.name, item.id, item.status)}
                                className={classes.checkIcon}
                              />
                            </Tooltip>
                            <Tooltip title='Reject'>
                              <CloseIcon
                                onClick={() => handleOpen('reject', item.name, item.id, item.status)}
                                className={classes.crossIcon}
                              />
                            </Tooltip>
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align='center' colSpan={10}>
                      <Typography align='center' variant='subtitle1' sx={{ color: '#483f3f' }}>
                        No matching records found.
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
const DrsList = (props: any) => {
  const {
    acceptLeave,
    resetLeaveData,
    rejectLeave,
    rejectLeaveData,
    acceptLeaveData,
    LeaveData,
    fetchLeaveData,
    isLeaveAcceptOrReject,
    isGettingLeaveData,
    timesheetData,
    fetchLeaveTimesheets,
    loaderState,
  } = props

  useEffect(() => {
    fetchLeaveTimesheets()
    return () => {
      resetLeaveData()
    }
  }, [])
    return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <div style={MainContainer}>
        <StyledPaper style={{ minHeight: '70vh' }}>
          <DrsListTableData
            isLeaveAcceptOrReject={isLeaveAcceptOrReject}
            acceptLeave={acceptLeave}
            rejectLeave={rejectLeave}
            rejectLeaveData={rejectLeaveData}
            acceptLeaveData={acceptLeaveData}
            isGettingLeaveData={isGettingLeaveData}
            LeaveData={LeaveData}
            fetchLeaveData={fetchLeaveData}
            timesheetData={timesheetData}
            isFinancial={LeaveData.isFinancial}
          />
        </StyledPaper>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    timesheetData: dashboardEntity.getDashboard(state).getLeaveTimesheets,
    loaderState: dashboardUI.getDashboard(state).isLeavesData,
    LeaveData: dashboardEntity.getDashboard(state).getLeaveData,
    isGettingLeaveData: dashboardUI.getDashboard(state).isGettingLeaveData,
    acceptLeaveData: dashboardEntity.getDashboard(state).acceptLeaveData,
    rejectLeaveData: dashboardEntity.getDashboard(state).rejectLeaveData,
    isLeaveAcceptOrReject: dashboardUI.getDashboard(state).isLeaveAcceptOrReject,
  }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchLeaveTimesheets: () => dispatch(getLeaveTimesheets.request()),
    fetchLeaveData: (data: any) => dispatch(getLeaveData.request(data)),
    resetLeaveData: () => dispatch(getLeaveData.reset()),
    acceptLeave: (data: any) => dispatch(acceptLeaveData.request(data)),
    rejectLeave: (data: any) => dispatch(rejectLeaveData.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrsList)
