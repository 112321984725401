import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_BANKINFO,
  FETCH_COMPENSATION_DETAILS,
  GET_PAY_SLIP,
  GET_TIMESHEET,
  RESET,
  SEND_LOAN_REQUEST,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE } = actionTypes

const ui = () => {
  const isCompensationData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_COMPENSATION_DETAILS[SUCCESS]:
        return false
      case FETCH_COMPENSATION_DETAILS[FAILURE]:
        return false
      case FETCH_COMPENSATION_DETAILS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isLoanRequestSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case SEND_LOAN_REQUEST[SUCCESS]:
        return true
      case SEND_LOAN_REQUEST[FAILURE]:
      case SEND_LOAN_REQUEST[REQUEST]:
      case SEND_LOAN_REQUEST[RESET]:
        return false
      default:
        return state
    }
  }

  const isPayslipData = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_PAY_SLIP[SUCCESS]:
        return false
      case GET_PAY_SLIP[FAILURE]:
        return false
      case GET_PAY_SLIP[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingBankInfo = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_BANKINFO[SUCCESS]:
        return false
      case FETCH_BANKINFO[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isTimesheetData = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_TIMESHEET[SUCCESS]:
        return true
      case GET_TIMESHEET[FAILURE]:
      case GET_TIMESHEET[REQUEST]:
        return false
      default:
        return state
    }
  }
  return combineReducers({
    isCompensationData,
    isPayslipData,
    isTimesheetData,
    isLoanRequestSuccess,
    isGettingBankInfo
  })
}

export default ui

export const getFinance = (state: RootState) => state.ui.finance
