import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard'
import ProtectedRoute from './auth/ProtectedRoute'
import Login from './components/Pages/Login'
import { Box } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import '../src/style.css'
import ForgotPassword from './components/Pages/ForgotPassword'
import MasterSetUp from './components/Dashboard/MasterSetUp'
import BasicInfo from './components/Dashboard/BasicInfo'
import BasicInfoOnSearch from './components/Dashboard/BasicInfoOnSearch'
import HolidayList from './components/Dashboard/HolidayList'
import Info from './components/EmployeeInfo/Info'
import BackgroundInfo from './components/EmployeeInfo/BackgroundInfo'
import AssignedAsset from './components/Dashboard/AssignedAsset'
import TodaysHighlights from './components/Dashboard/TodaysHighlights'
import LeaveDetails from './components/Dashboard/LeaveDetails'
import PaySlips from './components/Finance/PaySlips'
import Compensation from './components/Finance/Compensation'
import InvestmentForm from './components/Finance/InvestmentForm'
import BankInfo from './components/Finance/BankInfo'
import Loan from './components/Finance/Loan'
import ServiceRequest from './components/Dashboard/ServiceRequest'
import ProjectDetails from './components/Dashboard/ProjectDetails'
import EmployeeList from './components/EmployeeInfo/EmployeeList'
import DRs from './components/EmployeeInfo/DRs'
import DRsList from './components/EmployeeInfo/DRsList'
import StatusSummary from './components/Status/StatusSummary'
import AttendanceList from './components/EmployeeInfo/AttendanceList'
import RCAList from './components/Rca/RcaList'
import IDSR from './components/IDSR/IDSR'
import AssignedSR from './components/Dashboard/AssignedSR'
import ManagerView from './components/EmployeeInfo/ManagerView'
import ProjectGraph from './components/ProjectManagement/ProjectGraph'
import ProjectSheet from './components/ProjectManagement/ProjectSheet/Index'
import ProjectResourceReport from './components/ProjectManagement/ProjectResourceReport/ResourceReport'
import ProjectReports from './components/ProjectManagement/ProjectSheet/ProjectSheet'

import { useSelector } from 'react-redux'

import ProjectManagementReport from './components/ProjectManagement/ProjectManageReport/index'
import ProjectQAReports from './components/ProjectManagement/ProjectQAReport/index'
import OrgCharts from './components/OrgCharts'
import UserDetails from './components/UserDetails/UserDetails'
import NewUser from './components/UserDetails/NewUser'
import Reports from './components/Report'

function App() {
  //This useEffect Is an event lister that is responsible for page reload when user clears local storage.
  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === null) {
        window.location.reload()
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const latestRoles = useSelector((state:{entities:{dashboard:{getUserDetails:{roles:string[]}}}})=> state?.entities?.dashboard?.getUserDetails?.roles)
  
  const drsCount = useSelector((state: { entities: { dashboard: { getHomePageDetails: { DRS: { count: number } } } } }) => state?.entities?.dashboard?.getHomePageDetails?.DRS?.count);

  const role = latestRoles?? localStorage.getItem('roles')

  return (
    <Box height={'100%'} width={'100%'}>
      <Routes>
        <Route path='/' element={<Login />} />
        {/* <Route path='home/basic-info' element={<BasicInfo />} />
        <Route path='home/holiday-list' element={<HolidayList />} />
        <Route path='home/assigned-asset' element={<AssignedAsset />} />
        <Route path='home/leave-details' element={<LeaveDetails />} />
        <Route path='home/service-request' element={<ServiceRequest />} />
        <Route path='home/project-details' element={<ProjectDetails />} /> */}
        <Route path='/home' element={<ProtectedRoute component={<Dashboard />} />}>
          <Route path='basic-info' element={<BasicInfo />} />
          <Route path='basic-info-on-search' element={<BasicInfoOnSearch />} />
          <Route path='holiday-list' element={<HolidayList />} />
          <Route path='assigned-asset' element={<AssignedAsset />} />
          <Route path='leave-details' element={<LeaveDetails />} />
          <Route path='service-request' element={<ServiceRequest />} />
          <Route path='assigned-request' element={<AssignedSR />} />
          <Route path='project-details' element={<ProjectDetails />} />
          <Route path='todays-highlights' element={<TodaysHighlights />} />
          <Route path='drs' element={<DRs />} />
          <Route path='drs-list' element={<DRsList />} />
          <Route path='drs/leave-details' element={<LeaveDetails />} />
          <Route path='attendanceList' element={<AttendanceList />} />

          <Route path='dashboard/basic-info' element={<BasicInfo />} />
          <Route path='dashboard/holiday-list' element={<HolidayList />} />
          <Route path='dashboard/assigned-asset' element={<AssignedAsset />} />
          <Route path='dashboard/leave-details' element={<LeaveDetails />} />
          <Route path='dashboard/service-request' element={<ServiceRequest />} />
          <Route path='dashboard/project-details' element={<ProjectDetails />} />
          <Route path='dashboard/todays-highlights' element={<TodaysHighlights />} />
          <Route path='dashboard/drs' element={<DRs />} />
          <Route path='dashboard/drs/manager-view' element={<ManagerView />} />
          <Route path='dashboard/drs-list' element={<DRsList />} />
          <Route path='dashboard/drs/leave-details' element={<LeaveDetails />} />
          <Route path='dashboard/attendance-list' element={<AttendanceList />} />

          <Route path='/home' element={<MasterSetUp />} />
          <Route path='employees' element={<UserDetails />} />
          <Route path='employees/new-employees' element={<NewUser />} />
          <Route path='dashboard' element={<MasterSetUp />} />
          <Route path='employeeportal/employeeinfo' element={<Info />} />
          <Route path='employeeportal' element={<Info />} />
          <Route path='employeeportal/backgroundinfo' element={<BackgroundInfo />} />
          <Route path='employeeportal/employeelist' element={<EmployeeList />} />
          <Route path='employeeportal/rca' element={<RCAList />} />
          <Route path='employeeportal/idsr' element={<IDSR />} />

          <Route path='finance' element={<Compensation />} />
          <Route path='finance/PaySlips' element={<PaySlips />} />
          <Route path='finance/Compensation' element={<Compensation />} />
          <Route path='finance/InvestmentForm' element={<InvestmentForm />} />
          <Route path='finance/BankInfo' element={<BankInfo />} />
          <Route path='finance/Loan' element={<Loan />} />
          <Route path='statusSummary' element={<StatusSummary />} />
          <Route path='timesheet' element={<IDSR />} />
          <Route path='organizationalchart' element={<OrgCharts />} />
          {
            (role?.includes('Admin') || drsCount > 0) && <Route path='myTeam' element={<Reports />} />
          }
          
          {role?.includes('Project Manager') && 
          <Route path='projectmanagement' element={<ProjectGraph />} />  
           }
          <Route path='*' element={<Navigate to="/home" />} />
          {/* <Route path='projectmanagement/Projects' element={<Projects />} /> */}
          <Route path='projectmanagement/Projectgraphs' element={<ProjectGraph />} />
          
          <Route path='projectmanagement/projectreport' element={<ProjectSheet />} />
          <Route path='projectmanagement/ProjectResourceReport' element={<ProjectResourceReport />} />
          <Route path='projectmanagement/ProjectReports' element={<ProjectReports />} />
          <Route path='projectmanagement/ProjectManagementReport' element={<ProjectManagementReport />} />
          <Route path='projectmanagement/ProjectQAReports' element={<ProjectQAReports />} />


          {/* <Route path='*' element={<Navigate to='/home' />} /> */}
        </Route>
        <Route path='/site/forgotPassword' element={<ForgotPassword />} />
      </Routes>
    </Box>
  )
}

export default App
