import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  SearchBoxCustom,
  SearchIconStyle,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../../Common/CommonStyles'
import { SelectField } from '../../Common/ComponentCommonStyles'
import { RootState } from '../../../configureStore'
import { Dispatch } from 'redux'
import {
  addNewProject,
  fetchDeletedRecords,
  fetchDeletingProject,
  fetchEditedProject,
  fetchEmpBasedOnRoles,
  fetchProjectCustomers,
  fetchProjectDetails,
  fetchProjectLocation,
  fetchRestartedProject,
  fetchTechnoligies,
  fetchUpdatedProject,
  fetchWorkingEmp,
  projectDomain,
  projectSource,
  projectTypes,
} from '../../../actions'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import { connect } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import ReplayIcon from '@mui/icons-material/Replay'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ClearIcon, DatePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { toast } from 'react-toastify'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'

const style = {
  autoCompleteStyle: {
    '.MuiInputBase-root': {
      padding: '19px 11px',
      borderRadius: '20px',
      fontSize: '13px',
      fontFamily: 'Montserrat-Medium',
      height: '42px',
    },
    '& .MuiFormControl-root': {
      margin: '0',
      marginTop: '5px',
    },
    '& .MuiFormLabel-root ': {
      backgroundColor: 'white',
      width: '165px',
    },
    '.MuiFormLabel-asterisk': {
      color: 'red',
    },
  },
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
}))

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))
const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
}))
const InputField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))
const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))
const RestartDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '30px 40px',
    borderRadius: '25px',
    marginBottom: '-21px',
  },
  '& .MuiDialog-paper': {
    width: '80%',
  },
  '& .MuiButton-root': {},
}))

const StyledSelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '9px 11px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
    padding: '9px 11px',
    marginLeft: '-1px',
  },
}))

const CustomFormControll = styled(FormControl)(() => ({
  marginLeft: '1px',
  marginTop: '5px',
}))

const styles = {
  tableCellsStyles: {
    padding: '6px 16px',
  },
}

const StyledFormControl = styled(FormControl)(() => ({}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const RestartDialogBox = ({
  onClose,
  open,
  restartProjectApi,
  selectedProjectId,
  selectedProjectName,
  resetRestart,
}: {
  open: boolean
  onClose: () => void
  allProjectTypes: string[]
  selectedProjectId: any
  selectedProjectName: any
  resetRestart: any
  isRestartingProject: any
  restartProjectApi: (id: any) => number
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleStartDateChange = (date: any) => {
    setStartDate(date)
  }

  const haddleEndDate = (date: any) => {
    setEndDate(date)
  }

  const handleClose = () => {
    onClose()
    setStartDate(null)
    setEndDate(null)
  }
  const handleRestart = () => {
    restartProjectApi({
      id: selectedProjectId,
      start_date: startDate,
      end_date: endDate ? endDate : null,
    })
    resetRestart()
    resetRestartForm()
    handleClose()
  }

  const resetRestartForm = () => {
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <>
      <RestartDialog open={open} onClose={onClose} key={1}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
          <Heading>Restart {selectedProjectName} Project</Heading>
        </BootstrapDialogTitle>
        <Divider orientation='horizontal' sx={{ marginBottom: '0px' }} flexItem></Divider>
        <DialogContent sx={{ marginTop: '-6px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <Box>
                    <DatePicker
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '44px',
                          marginTop: '-10px',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          marginTop: '-15px',
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                      }}
                      label='Start Date'
                      value={dayjs(startDate)}
                      onChange={handleStartDateChange}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <Box>
                    <DatePicker
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '44px',
                          marginTop: '-10px',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          marginTop: '-15px',
                        },
                      }}
                      label='End Date'
                      value={endDate}
                      onChange={haddleEndDate}
                      slotProps={{
                        textField: {
                          required: false,
                          error: false,
                        },
                      }}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: '0px', marginRight: '25px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <CancelButton
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '36px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
                background: '#E2E2E2',
                color: '#000000',
                '&:hover': {
                  background: '#E2E2E2',
                  color: '#000000',
                },
              }}
              onClick={handleClose}
            >
              CANCEL
            </CancelButton>
            <Button
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '36px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
                '&.Mui-disabled': {
                  opacity: 1,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handleRestart}
              disabled={!startDate}
            >
              RESTART
            </Button>
          </Box>
        </DialogActions>
      </RestartDialog>
    </>
  )
}

const AddNewEmployee = ({
  onClose,
  open,
  technologies,
  selectedRoleLabel,
  AllEmpList,
  fetchAddEmpBasedOnRoleAPI,
  resetAddDeveloper,
  projectId,
}: {
  open: boolean
  onClose: () => void
  technologies: string[]
  selectedRoleLabel: any
  AllEmpList: any
  fetchAllEmpAPI: any
  fetchAddEmpBasedOnRoleAPI: any
  resetAddDeveloper: any
  projectId: any
  getEmpBasedOnRoles: any
}) => {
  const [workingEmployee, setWorkingEmployee] = useState('')
  const [type, setType] = useState('')
  const [timeCount, setTimeCount] = useState('')
  const [billable, setBillable] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [projectTechnologies, setProjectTechnologies] = useState<string[]>([])
  const [isBillable, setIsBillable] = useState(false)

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const handleAddEmp = () => {
    let projTech: number[] = []
    projectTechnologies.forEach((item) =>
      technologies.forEach((data: any) => {
        if (item === data.name) {
          projTech.push(data.id)
        }
      }),
    )

    let id_member_type
    switch (selectedRoleLabel) {
      case 'MEMBER':
        id_member_type = 1
        break
      case 'REVIEWER 1':
        id_member_type = 2
        break
      case 'REVIEWER 2':
        id_member_type = 3
        break
      // case 'Reviewer':
      //   id_member_type = 4
      //   break
      // case 'BA':
      //   id_member_type = 5
      //   break
      // case 'Designer':
      //   id_member_type = 6
      //   break
      default:
        id_member_type = ''
    }

    const data = {
      id_member_type: id_member_type,
      id_project: projectId,
      employee_id: workingEmployee,
      work_in_place_of: billable,
      is_billable: isBillable ? 1 : 0,
      technologies: projTech,
      total_time_count: timeCount !== '' ? timeCount : 0,
      start_date: startDate,
      end_date: endDate ? endDate : null,
      employment_type: type,
    }

    fetchAddEmpBasedOnRoleAPI(data)
    resetAddDeveloper()

    // resetForm(),
    handleClose()
  }

  const resetForm = () => {
    setWorkingEmployee('')
    setBillable('')
    setProjectTechnologies([])
    setTimeCount('')
    setStartDate(null)
    setEndDate(null)
    setTimeCount('')
    setType('')
    setIsBillable(false)
  }

  const handleTypeChange = (event: any) => {
    const selectedType = event?.target.value
    setType(selectedType)
    if (selectedType === '2') {
      setTimeCount('160')
    }
  }

  const handleTimeCountChange = (event: any) => {
    setTimeCount(event.target.value)
  }

  const handleChangeMultiple = (event: any) => {
    const {
      target: { value },
    } = event
    setProjectTechnologies(typeof value === 'string' ? value.split(',') : value)
  }

  const handleStartDateChange = (date: any) => {
    setStartDate(date)
  }
  const haddleEndDateChange = (date: any) => {
    setEndDate(date)
  }

  const handleCheckboxChange = (event: any) => {
    setIsBillable(event.target.checked)
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Heading>ADD {selectedRoleLabel}</Heading>
      </BootstrapDialogTitle>
      <Divider orientation='horizontal' sx={{ marginBottom: '17px' }} flexItem></Divider>
      <Box sx={{ padding: '0 16px', marginTop: '1px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={4} sm={4}>
            <Autocomplete
              autoFocus={false}
              size='small'
              disablePortal
              id='select-working-employees'
              options={AllEmpList.map(
                (employee: { employeeid: string; name: string }) =>
                  `${employee.employeeid ?? ''}${employee.employeeid ? '-' : ''}${employee.name}`,
              )}
              sx={style.autoCompleteStyle}
              renderInput={(params) => (
                <TextField {...params} label='Working Employees' variant='outlined' />
              )}
              onChange={(event: any, newValue: string | null) => {
                const empId = newValue?.split('-')[0]
                const userInfo = AllEmpList.find(
                  (employee: { employeeid: string }) => employee.employeeid === empId,
                )
                setWorkingEmployee(userInfo?.userId ?? '')
              }}
              ListboxProps={{
                style: {
                  maxHeight: '100px',
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={4} sm={4}>
            <Autocomplete
              size='small'
              disablePortal
              id='select-billable-employee'
              options={AllEmpList.map(
                (employee: { employeeid: string; name: string }) =>
                  `${employee.employeeid ?? ''}${employee.employeeid ? '-' : ''}${employee.name}`,
              )}
              sx={style.autoCompleteStyle}
              renderInput={(params) => (
                <TextField {...params} label='Billable Employee' variant='outlined' />
              )}
              onChange={(event: any, newValue: string | null) => {
                const empId = newValue?.split('-')[0]
                const userInfo = AllEmpList.find(
                  (employee: { employeeid: string }) => employee.employeeid === empId,
                )
                setBillable(userInfo?.userId ?? '')
              }}
              ListboxProps={{
                style: {
                  maxHeight: '140px',
                },
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-multiple-checkbox-label' sx={{ marginTop: -0.7 }}>
                Technologies <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                variant='outlined'
                label='Project Technologies'
                multiple
                input={<OutlinedInput label='Project Technologies' />}
                value={projectTechnologies}
                onChange={handleChangeMultiple}
                renderValue={(selected: any) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {technologies.map((technology: any, index: number) => (
                  <MenuItem key={technology?.name} value={technology.name}>
                    <Checkbox checked={projectTechnologies.includes(technology.name)} />
                    <ListItemText primary={technology.name} />.
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid> */}
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Type
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Type'
                type='small'
                // sx={{ marginTop: '12px' }}
                fullWidth
                value={type}
                onChange={handleTypeChange}
                required={false}
              >
                <MenuItem value='0'>Fixed</MenuItem>
                <MenuItem value='1'>Hourly</MenuItem>
                <MenuItem value='2'>Monthly</MenuItem>
              </StyledSelectField>
            </CustomFormControll>
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              id='outlined-required'
              label='Time Count'
              size='small'
              fullWidth
              type='number'
              // sx={{ marginTop: '2px' }}
              value={timeCount}
              onChange={handleTimeCountChange}
              margin='normal'
              placeholder='Please enter time count'
              required={false}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '42px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                    }}
                    label='Start Date'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '44px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                      },
                    }}
                    label='End Date'
                    value={endDate}
                    onChange={haddleEndDateChange}
                    slotProps={{
                      textField: {
                        required: false,
                      },
                    }}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} sm={4} sx ={{display:'flex',alignItems:'center'}}>
            <div >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBillable}
                    onChange={handleCheckboxChange}
                    name='billable'
                    color='primary'
                  />
                }
                label='Is Billable'
                sx={{ color: '#666669', fontFamily: 'Montserrat-SemiBold'}}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '2px' }}>
        <CancelButton
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
          }}
          onClick={handleClose}
        >
          CANCEL
        </CancelButton>
        <Button
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
            '&.Mui-disabled': {
              opacity: 1,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleAddEmp}
          disabled={
            !workingEmployee ||
            // !timeCount ||
            // !billable ||
            // !projectTechnologies ||
            // !type ||
            !startDate
          }
        >
          ADD
        </Button>
      </Box>
    </Dialog>
  )
}

const EditNewEmployee = ({
  onClose,
  open,
  technologies,
  selectedRoleLabel,
  AllEmpList,
  projectId,
  employeeData,
  fetchUpdatedProjectAPI,
  isGettingWorkingEmpData,
}: {
  open: boolean
  onClose: () => void
  technologies: string[]
  selectedRoleLabel: any
  AllEmpList: any
  fetchAllEmpAPI: any
  fetchAddEmpBasedOnRoleAPI: any
  resetAddDeveloper: any
  projectId: any
  getEmpBasedOnRoles: any
  employeeData: any
  fetchUpdatedProjectAPI: any
  isGettingWorkingEmpData: any
}) => {
  const [workingEmployee, setWorkingEmployee] = useState('')
  const [type, setType] = useState('')
  const [timeCount, setTimeCount] = useState('')
  const [billable, setBillable] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [projectTechnologies, setProjectTechnologies] = useState<string[]>([])
  const [isBillable, setIsBillable] = useState(false)
  const [id, setId] = useState()

  useEffect(() => {
    if (employeeData) {
      setId(employeeData?.id)
      setWorkingEmployee(employeeData?.working_user_id || '')
      setBillable(employeeData?.billable_user_id || '')
      setProjectTechnologies(employeeData?.technologies || [])
      setType(employeeData?.employment_type || '')
      setIsBillable(employeeData?.is_billable || false)
      setTimeCount(employeeData?.time_count || '')
      setStartDate(
        employeeData.start_date ? dayjs(new Date(employeeData.start_date)).toDate() : null,
      )
      setEndDate(employeeData.end_date ? dayjs(new Date(employeeData.end_date)).toDate() : null)
    }
  }, [employeeData])

  const handleClose = () => {
    onClose()
  }

  const handleEditEmp = () => {
    let projTech: number[] = []
    projectTechnologies.forEach((item) =>
      technologies.forEach((data: any) => {
        if (item === data.name) {
          projTech.push(data.id)
        }
      }),
    )

    let id_member_type
    switch (selectedRoleLabel) {
      case 'MEMBER':
        id_member_type = 1
        break
      case 'REVIEWER 1':
        id_member_type = 2
        break
      case 'REVIEWER 2':
        id_member_type = 3
        break
      // case 'Reviewer':
      //   id_member_type = 4
      //   break
      // case 'BA':
      //   id_member_type = 5
      //   break
      // case 'Designer':
      //   id_member_type = 6
      //   break
      default:
        id_member_type = ''
    }

    const data = {
      id: id,
      id_member_type: id_member_type,
      id_project: projectId,
      employee_id: workingEmployee,
      work_in_place_of: billable.toString(),
      is_billable: isBillable ? 1 : 0,
      technologies: projTech,
      total_time_count: timeCount !== '' ? timeCount : 0,
      start_date: startDate,
      end_date: endDate ? endDate : null,
      employment_type: type.toString(),
    }

    fetchUpdatedProjectAPI(data)

    handleClose()
  }

  const handleTypeChange = (event: any) => {
    const selectedType = event?.target.value
    setType(selectedType)
    if (selectedType === '2') {
      setTimeCount('160')
    }
  }

  const handleTimeCountChange = (event: any) => {
    setTimeCount(event.target.value)
  }

  const handleChangeMultiple = (event: any) => {
    const {
      target: { value },
    } = event
    setProjectTechnologies(typeof value === 'string' ? value.split(',') : value)
  }

  const handleStartDateChange = (date: any) => {
    setStartDate(date)
  }
  const haddleEndDateChange = (date: any) => {
    setEndDate(date)
  }

  const handleCheckboxChange = (event: any) => {
    setIsBillable(event.target.checked)
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <Dialog open={isGettingWorkingEmpData} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Heading>Update {selectedRoleLabel}</Heading>
      </BootstrapDialogTitle>
      <Divider orientation='horizontal' sx={{ marginBottom: '17px' }} flexItem></Divider>
      <Box sx={{ padding: '0 16px', marginTop: '1px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={4} sm={4}>
            <Autocomplete
              autoFocus={false}
              size='small'
              disablePortal
              id='select-working-employees'
              options={AllEmpList.map(
                (employee: { employeeid: string; name: string }) =>
                  `${employee.employeeid ?? ''}${employee.employeeid ? '-' : ''}${employee.name}`,
              )}
              sx={style.autoCompleteStyle}
              renderInput={(params) => (
                <TextField {...params} label='Working Employees' variant='outlined' />
              )}
              defaultValue={`${employeeData?.working_emp_id}-${employeeData?.working_full_name}`}
              onChange={(event: any, newValue: string | null) => {
                const empId = newValue?.split('-')[0]
                const userInfo = AllEmpList.find(
                  (employee: { employeeid: string }) => employee.employeeid === empId,
                )
                setWorkingEmployee(userInfo?.userId ?? '')
              }}
              ListboxProps={{
                style: {
                  maxHeight: '100px',
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={4} sm={4}>
            <Autocomplete
              size='small'
              disablePortal
              id='select-billable-employee'
              options={AllEmpList.map(
                (employee: { employeeid: string; name: string }) =>
                  `${employee.employeeid ?? ''}${employee.employeeid ? '-' : ''}${employee.name}`,
              )}
              sx={style.autoCompleteStyle}
              renderInput={(params) => (
                <TextField {...params} label='Billable Employee' variant='outlined' />
              )}
              defaultValue={`${employeeData?.billable_emp_id}-${employeeData?.billable_full_name}`}
              onChange={(event: any, newValue: string | null) => {
                const empId = newValue?.split('-')[0]
                const userInfo = AllEmpList.find(
                  (employee: { employeeid: string }) => employee.employeeid === empId,
                )
                setBillable(userInfo?.userId ?? '')
              }}
              ListboxProps={{
                style: {
                  maxHeight: '140px',
                },
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-multiple-checkbox-label' sx={{ marginTop: -0.7 }}>
                Technologies <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                variant='outlined'
                label='Project Technologies'
                multiple
                input={<OutlinedInput label='Project Technologies' />}
                value={projectTechnologies}
                onChange={handleChangeMultiple}
                renderValue={(selected: any) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {technologies.map((technology: any, index: number) => (
                  <MenuItem key={technology?.name} value={technology.name}>
                    <Checkbox checked={projectTechnologies.includes(technology.name)} />
                    <ListItemText primary={technology.name} />.
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid> */}
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Type
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Type'
                type='small'
                // sx={{ marginTop: '12px' }}
                fullWidth
                value={type}
                onChange={handleTypeChange}
                required={false}
              >
                <MenuItem value='0'>Fixed</MenuItem>
                <MenuItem value='1'>Hourly</MenuItem>
                <MenuItem value='2'>Monthly</MenuItem>
              </StyledSelectField>
            </CustomFormControll>
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              id='outlined-required'
              label='Time Count'
              size='small'
              fullWidth
              type='number'
              // sx={{ marginTop: '2px' }}
              value={timeCount}
              onChange={handleTimeCountChange}
              margin='normal'
              placeholder='Please enter time count'
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '42px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                    }}
                    label='Start Date'
                    value={dayjs(startDate)}
                    onChange={handleStartDateChange}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '44px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                      },
                    }}
                    label='End Date'
                    value={dayjs(endDate)}
                    onChange={haddleEndDateChange}
                    slotProps={{
                      textField: {
                        required: false,
                        error: false,
                      },
                    }}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} sm={4} sx ={{display:'flex',alignItems:'center'}}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBillable}
                    onChange={handleCheckboxChange}
                    name='billable'
                    color='primary'
                  />
                }
                label='Is Billable'
                sx={{ color: '#666669', fontFamily: 'Montserrat-SemiBold', marginTop: -0.3 }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '2px' }}>
        <CancelButton
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
          }}
          onClick={handleClose}
        >
          CANCEL
        </CancelButton>
        <Button
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
            '&.Mui-disabled': {
              opacity: 1,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleEditEmp}
          disabled={!workingEmployee || !timeCount || /*!billable || !projectTechnologies ||*/ !type}
        >
          UPDATE
        </Button>
      </Box>
    </Dialog>
  )
}

const EditProjectDialog = ({
  porjectDomainList,
  projectSourceList,
  onClose,
  open,
  technologies,
  projectLocation,
  projectCustomers,
  selectedProjectName,
  projectId,
  projectDetailsData,
  AllEmpList,
  fetchAllEmpAPI,
  fetchAddEmpBasedOnRoleAPI,
  resetAddDeveloper,
  isAddingEmp,
  getEmpBasedOnRoles,
  fetchUpdatedProjectAPI,
  resetUpdatedProject,
  isProjectUpdated,
  deleteApiCall,
  resetDeleteAPI,
  isRecordsdeleted,
  fetchEditedProjectsAPI,
  isGettingProjectDetailsData,
  isGettingWorkingEmpData,
  isProjectUpdating,
  allProjectTypes
}: {
  projectSourceList: []
  porjectDomainList: []
  open: boolean
  onClose: () => void
  AddNewProjects: (data: any) => void
  technologies: string[]
  projectLocation: string[]
  allProjectTypes: [
    data: {}
]
  projectCustomers: any
  resetCreateproject: any
  isCreatingproject: any
  selectedSource: any
  selectedProjectName: any
  selectedCustomer: any
  projectId: any
  projectDetailsData: any
  AllEmpList: any
  fetchAllEmpAPI: any
  fetchAddEmpBasedOnRoleAPI: any
  resetAddDeveloper: any
  isAddingEmp: any
  getEmpBasedOnRoles: any
  fetchUpdatedProjectAPI: any
  resetUpdatedProject: any
  isProjectUpdated: any
  deleteApiCall: any
  isRecordsdeleted: any
  resetDeleteAPI: any
  fetchEditedProjectsAPI: any
  isGettingProjectDetailsData: any
  isGettingWorkingEmpData: any
  isProjectUpdating:any
}) => {
  const [projectCustomer, setProjectCustomer] = useState<string>('')
  const [projectSource, setProjectSource] = useState(1)
  const [projectsType, setProjectsType] = useState('')
  const [projectStatus, setProjectStatus] = useState('')
  const [projectName, setProjectName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [projectLocations, setProjectLocations] = useState('')
  const [projectDomain, setProjectDomain] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [projectTechnologies, setProjectTechnologies] = useState<string[]>([])
  const [draggingStartDate, setDraggingStartDate] = useState<Date | null>(null)
  const [draggingEndDate, setDraggingEndDate] = useState<Date | null>(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [userId, setUserId] = useState()
  const [empName, setEmpName] = useState('')
  const [prStatus, setPrStatus] = useState('')

  const [isAddEmployee, setIsAddEmployee] = useState(false)
  const [isEditEmployee, setIsEditEmployee] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0)

  const getFilteredMembers = () => {
    const roles = ['Member', 'First Reviewer', 'Second Reviewer']
    return projectDetailsData?.formattedMembers?.filter(
      (member: any) => member.role === roles[selectedTab],
    )
  }  

  const filteredMembers = getFilteredMembers()

  const getCountByRole = (role: any) => {
    return projectDetailsData?.formattedMembers?.filter((member: any) => member.role === role)
      .length
  }

  const roleLabels = ['MEMBER', 'REVIEWER 1', 'REVIEWER 2']
  const selectedRoleLabel = roleLabels[selectedTab]

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const isongoing = projectDetailsData.projectStatus

  useEffect(() => {
    if (projectDetailsData && projectCustomers) {
      setProjectCustomer(projectDetailsData.id_project_customer || '')
      // setProjectSource(projectDetailsData.id_project_source || '')
      setProjectsType(projectDetailsData.projectsType || '')
      setProjectStatus(
        projectDetailsData.projectStatus === '0'
          ? 'On Going'
          : projectDetailsData.projectStatus === '1'
          ? 'Completed'
          : projectDetailsData.projectStatus === '2'
          ? 'Dragging'
          : projectDetailsData.projectStatus === '3'
          ? 'Paused'
          : '',
      )
      setProjectName(projectDetailsData.projectName || '')
      setDescription(projectDetailsData.description || '')

      const techno = projectDetailsData.projectTechnologies?.map((v: any) => v.name)

      setProjectTechnologies(techno || [])

      setStartDate(
        projectDetailsData.start_date
          ? dayjs(new Date(projectDetailsData.start_date)).toDate()
          : null,
      )
      setEndDate(projectDetailsData.end_date ? dayjs(projectDetailsData.end_date).toDate() : null)
      setDraggingStartDate(
        projectDetailsData.dragging_start_date
          ? dayjs(projectDetailsData.dragging_start_date).toDate()
          : null,
      )
      setDraggingEndDate(
        projectDetailsData.dragging_end_date
          ? dayjs(projectDetailsData.dragging_end_date).toDate()
          : null,
      )
      setProjectLocations(projectDetailsData.projectLocations || '')
      setProjectDomain(projectDetailsData.id_domain || '')
      setPrStatus(projectDetailsData.project_status || '')
    }
  }, [projectDetailsData, projectCustomers])

  const handleClose = () => {
    onClose()
    setSelectedTab(0)
  }

  const handleProjectLocationChange = (event: any) => {
    setProjectLocations(event.target.value)
  }

  const handleProjectStatusChange = (event: any) => {
    setPrStatus(event.target.value)
  }

  const handleProjectDomainChange = (event: any) => {
    setProjectDomain(event.target.value)
  }

  const handleProjectsType = (event: any) => {
    setProjectsType(event?.target.value)
  }

  const handleChangeMultiple = (event: any) => {
    const {
      target: { value },
    } = event
    setProjectTechnologies(typeof value === 'string' ? value.split(',') : value)
  }

  const handleDeleteTechnology = (technology: string) => {
    const updatedTechnologies = projectTechnologies.filter((tech) => tech !== technology)
    setProjectTechnologies(updatedTechnologies)
  }

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === 'Dragging') {
      setProjectStatus('Dragging')
    } else {
      setProjectStatus(value)
    }
  }
  const handleProjectCustomerChange = (event: any) => {
    setProjectCustomer(event.target.value)
  }

  const handleProjectSourceChange = (event: any) => {
    setProjectSource(1)
  }
  const handleStartDateChange = (date: any) => {
    setStartDate(date)
  }
  const haddleEndDate = (date: any) => {
    setEndDate(date)
  }
  const haddleDraggingEndDateChange = (date: any) => {
    setDraggingEndDate(date)
  }
  const haddleDraggingStartDateChange = (date: any) => {
    setDraggingStartDate(date)
  }
  const handleCloseAddEmployee = () => {
    setIsAddEmployee(false)
  }
  const handleCloseEditEmployee = () => {
    setIsEditEmployee(false)
  }

  const handleAddButton = () => {
    fetchAllEmpAPI()
    setIsAddEmployee(true)
  }

  useEffect(() => {
    if (isAddingEmp) {
      toast.success('Added successfully')
      resetAddDeveloper()
    }
  }, [isAddingEmp])

  useEffect(() => {
    if (isProjectUpdated) {
      toast.success('Updated successfully')
      resetUpdatedProject()
    }
  }, [isProjectUpdated])

  useEnhancedEffect(() => {
    if (isRecordsdeleted) {
      toast.success('Deleted successfully')
      resetDeleteAPI()
    }
  }, [isRecordsdeleted])

  const handleEditChange = (member: any) => {
    fetchAllEmpAPI()
    setSelectedMember(member)
    setIsEditEmployee(true)
  }

  const handleDeleteConfirm = () => {
    deleteApiCall({
      id_users: userId,
      id_project: projectId,
    })
    setDeleteConfirmationOpen(false)
  }

  const handleDeletenDialog = (userId: any, empname: any) => {
    setDeleteConfirmationOpen(true)
    setUserId(userId)
    setEmpName(empname)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmationOpen(false)
  }

  const handleProjectUpdate = () => {
    let projTech: number[] = []
    projectTechnologies.forEach((item) =>
      technologies.forEach((data: any) => {
        if (item === data.name) {
          projTech.push(data.id)
        }
      }),
    )

    const data = {
      id: projectId,
      id_project_customer: projectCustomer,
      projectName: projectName,
      id_project_source: 1,
      projectsType: projectsType,
      project_status: projectStatus,
      description: description,
      projectTechnologies: projTech,
      start_date: startDate,
      end_date: endDate ? endDate : null,
      projectLocations: projectLocations,
      dragging_start_date: draggingStartDate,
      dragging_end_date: draggingEndDate,
      id_domain: projectDomain,
    }
    fetchEditedProjectsAPI(data)
    handleClose()
  }

  const role = localStorage.getItem('roles')
    const isAdmin = role === 'Admin'


  
  return (
    <>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ fontFamily: 'Montserrat-Medium' }}>
          <>
            Are you sure you want to Delete the{' '}
            <span style={{ fontFamily: 'Monter Medium' }}>{empName}</span> ?{' '}
          </>
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '-10px' }}>
            <CancelButton
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '38px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
              }}
              onClick={handleCloseDeleteDialog}
            >
              CANCEL
            </CancelButton>
            <Button
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '38px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
                '&.Mui-disabled': {
                  opacity: 1,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handleDeleteConfirm}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <AddNewEmployee
        open={isAddEmployee}
        onClose={handleCloseAddEmployee}
        technologies={technologies}
        selectedRoleLabel={selectedRoleLabel}
        AllEmpList={AllEmpList}
        fetchAllEmpAPI={fetchAllEmpAPI}
        fetchAddEmpBasedOnRoleAPI={fetchAddEmpBasedOnRoleAPI}
        resetAddDeveloper={resetAddDeveloper}
        projectId={projectId}
        getEmpBasedOnRoles={getEmpBasedOnRoles}
      />
      <EditNewEmployee
        open={isEditEmployee}
        onClose={handleCloseEditEmployee}
        technologies={technologies}
        selectedRoleLabel={selectedRoleLabel}
        AllEmpList={AllEmpList}
        fetchAllEmpAPI={fetchAllEmpAPI}
        fetchAddEmpBasedOnRoleAPI={fetchAddEmpBasedOnRoleAPI}
        resetAddDeveloper={resetAddDeveloper}
        projectId={projectId}
        getEmpBasedOnRoles={getEmpBasedOnRoles}
        employeeData={selectedMember}
        fetchUpdatedProjectAPI={fetchUpdatedProjectAPI}
        isGettingWorkingEmpData={isGettingWorkingEmpData}
      />
      <Dialog open={open} maxWidth='md'>
        <Dialog open={isGettingProjectDetailsData || isProjectUpdating} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Heading>{selectedProjectName} Project</Heading>
        </BootstrapDialogTitle>
        <Divider orientation='horizontal' sx={{ marginBottom: '25px' }} flexItem></Divider>
        <Box sx={{ padding: '5px 16px', marginTop: '-10px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.1 }}>
                  Client <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label=' Client'
                  type='small'
                  fullWidth
                  disabled={prStatus !== '0'}
                  value={projectCustomer}
                  onChange={handleProjectCustomerChange}
                >
                  {projectCustomers.map((customer: any) => (
                    <MenuItem key={customer?.id} value={customer.id}>
                      {customer.customer_name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
            <Grid item xs={4} sm={4}>
              <InputField
                id='outlined-required'
                label='Project Name'
                size='small'
                fullWidth
                disabled={prStatus !== '0'}
                value={projectName}
                onChange={(e: any) => setProjectName(e.target.value)}
                margin='normal'
                placeholder='Please enter project name'
              />
            </Grid>
            {/* <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.1 }}>
                Project Source <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Project Source'
                  type='small'
                  fullWidth
                  disabled={isongoing !== '0'}
                  value={projectSource}
                  onChange={handleProjectSourceChange}
                >
                  {projectSourceList?.map((source: any) => (
                    <MenuItem key={source?.id} value={source?.id}>
                      {source?.source_name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </CustomFormControll>
            </Grid> */}
            <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                  Project Type<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Project Type'
                  type='small'
                  fullWidth
                  disabled={prStatus !== '0'}
                  value={projectsType}
                  onChange={handleProjectsType}
                >
                  <MenuItem value='Billable'>Billable</MenuItem>
                  <MenuItem value='Non-Billable'>Non-Billable</MenuItem>
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
            <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Area Of Practice <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Area Of Practice'
                  type='small'
                  fullWidth
                  disabled={prStatus !== '0'}
                  value={projectDomain}
                  onChange={handleProjectDomainChange}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '250px',
                      },
                    },
                  }}
                >
                  {porjectDomainList.map((domain: any) => (
                    <MenuItem key={domain?.id} value={domain?.id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
            <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                  Project Location <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Project Location'
                  type='small'
                  fullWidth
                  disabled={prStatus !== '0'}
                  value={projectLocations}
                  onChange={handleProjectLocationChange}
                >
                  {projectLocation.map((location: any) => (
                    <MenuItem key={location?.id} value={location.id}>
                      {location.location}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </CustomFormControll>
            </Grid>

            <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                  Project Status <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Project Status'
                  type='small'
                  fullWidth
                  disabled={prStatus !== '0'}
                  value={prStatus}
                  onChange={handleProjectStatusChange}
                >
                  <MenuItem value={'1'}>Completed</MenuItem>
                  <MenuItem value={'2'}>Dragging</MenuItem>
                  <MenuItem value={'0'}>OnGoing</MenuItem>
                  <MenuItem value={'3'}>Paused</MenuItem>
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
                marginTop: '9px',
              }}
            >
               <FormLabel id='ProjectStatusLabel'>Project Status :</FormLabel>
              <div style={{ display: 'flex', marginLeft: '1rem' }}>
                <RadioGroup
                  row
                  aria-labelledby='ProjectStatusLabel'
                  name='ProjectStatus'
                  value={projectStatus}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value='Completed'
                    disabled={isongoing !== '0'}
                    control={<Radio size='small' />}
                    label={<Typography variant='body2'>Completed</Typography>}
                  />
                  <FormControlLabel
                    value='Dragging'
                    disabled={isongoing !== '0'}
                    control={<Radio size='small' />}
                    label={<Typography variant='body2'>Dragging</Typography>}
                  />
                  <FormControlLabel
                    value='On Going'
                    disabled={isongoing !== '0'}
                    control={<Radio size='small' />}
                    label={<Typography variant='body2'>On Going</Typography>}
                  />
                  <FormControlLabel
                    value='Paused'
                    control={<Radio size='small' />}
                    disabled={isongoing !== '0'}
                    label={<Typography variant='body2'>Paused</Typography>}
                  />
                </RadioGroup>
              </div> 
            </div> */}
            <Grid item xs={12} sm={12}>
              <InputField
                id='outlined-required'
                label='Description'
                size='small'
                fullWidth
                multiline
                required={false}
                value={description}
                disabled={prStatus !== '0'}
                onChange={(e: any) => setDescription(e.target.value)}
                margin='normal'
                placeholder='Please enter description'
              />
            </Grid>

            {/* <Grid item xs={6} sm={6}>
              <CustomFormControll>
                <InputLabel id='demo-multiple-checkbox-label' sx={{ marginTop: -0.7 }}>
                  Project Technologies<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='demo-multiple-checkbox-label'
                  id='demo-multiple-checkbox'
                  variant='outlined'
                  label='Project Technologies'
                  multiple
                  disabled={isongoing !== '0'}
                  input={<OutlinedInput label='Project Technologies' />}
                  value={projectTechnologies}
                  onChange={handleChangeMultiple}
                  renderValue={(selected: any) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {technologies.map((technology: any) => (
                    <MenuItem key={technology?.id} value={technology.name}>
                      <Checkbox checked={projectTechnologies.includes(technology.name)} />
                      <ListItemText primary={technology.name} />.
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                <FormLabel id='ProjectStatusLabel'>Selected Tech. </FormLabel>
                <Stack direction='row' spacing={1} marginLeft={1}>
                  {projectTechnologies.map((data: any, index: number) =>
                    index < 3 ? (
                      <Chip
                        key={data}
                        label={data}
                        disabled={isongoing !== '0'}
                        variant='outlined'
                        onDelete={() => handleDeleteTechnology(data)}
                      />
                    ) : index === 3 ? (
                      <Tooltip title={projectTechnologies.join(', ')} key={`...`}>
                        <Chip label='...' variant='outlined' />
                      </Tooltip>
                    ) : null,
                  )}
                </Stack>
              </Box>
            </Grid> */}

            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <Box width={'100%'}>
                    <DatePicker
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '42px',
                          marginTop: '-10px',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          marginTop: '-15px',
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                      }}
                      label='Start Date'
                      value={dayjs(startDate)}
                      disabled={prStatus !== '0'}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <Box width={'100%'}>
                    <DatePicker
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '44px',
                          marginTop: '-10px',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          marginTop: '-15px',
                        },
                      }}
                      label='End Date'
                      value={dayjs(endDate)}
                      disabled={prStatus !== '0'}
                      onChange={haddleEndDate}
                      slotProps={{
                        textField: {
                          required: false,
                          error: false,
                        },
                      }}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            {projectStatus === 'Dragging' && (
              <>
                <Grid item xs={4} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <Box>
                        <DatePicker
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '20px',
                              height: '42px',
                              marginTop: '-14px',
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '14px',
                              marginTop: '-18px',
                            },
                          }}
                          label='Dragging Start Date'
                          value={dayjs(draggingStartDate)}
                          disabled={prStatus !== '0'}
                          onChange={haddleDraggingStartDateChange}
                          slotProps={{
                            textField: {
                              required: true,
                              error: false,
                            },
                          }}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <Box>
                        <DatePicker
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '20px',
                              height: '42px',
                              marginTop: '-14px',
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '14px',
                              marginTop: '-18px',
                            },
                          }}
                          label='Dragging End Date'
                          value={dayjs(draggingEndDate)}
                          disabled={prStatus !== '0'}
                          onChange={haddleDraggingEndDateChange}
                          slotProps={{
                            textField: {
                              required: false,
                              error: false,
                            },
                          }}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </>
            )}
          </Grid>

          {prStatus === '0' && (
            <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
              <CancelButton
                variant='contained'
                color='primary'
                sx={{
                  fontSize: '13px',
                  height: '38px',
                  borderRadius: '20px',
                  padding: '5px 20px',
                  marginRight: '10px',
                  marginBottom: 2,
                  width: '100px',
                }}
                onClick={handleClose}
              >
                CANCEL
              </CancelButton>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  fontSize: '13px',
                  height: '38px',
                  borderRadius: '20px',
                  padding: '5px 20px',
                  marginRight: '10px',
                  marginBottom: 2,
                  width: '100px',
                  '&.Mui-disabled': {
                    opacity: 1,
                    color: '#ffffff',
                    cursor: 'not-allowed',
                  },
                }}
                onClick={handleProjectUpdate}
                disabled={
                  !projectName ||
                  !projectsType ||
                  !startDate ||
                  !projectCustomer ||
                  // !projectSource ||
                  !projectLocations ||
                  // !projectTechnologies.length ||
                  !projectDomain
                }
              >
                UPDATE
              </Button>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginTop: '15px',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons={false}
              aria-label='Tabs for different tables'
            >
              <Tab
                label={`MEMBER (${getCountByRole('Member')})`}
                onClick={() => setSelectedTab(0)}
              />
              <Tab
                label={`REVIEWER 1 (${getCountByRole('First Reviewer')})`}
                onClick={() => setSelectedTab(1)}
              />
              <Tab
                label={`REVIEWER 2 (${getCountByRole('Second Reviewer')})`}
                onClick={() => setSelectedTab(2)}
              />
              {/* <Tab
                label={`DESIGNER DETAILS (${getCountByRole('Designer')})`}
                onClick={() => setSelectedTab(3)}
              />
              <Tab
                label={`LEAD DETAILS (${getCountByRole('Lead')})`}
                onClick={() => setSelectedTab(4)}
              />
              <Tab
                label={`REVIEWER DETAILS (${getCountByRole('Reviewer')})`}
                onClick={() => setSelectedTab(5)}
              /> */}
            </Tabs>
          </Box>
          <Button
            sx={{
              fontSize: '15px',
              height: '42px',
              float: 'right',
              letterSpacing: '0px',
              borderRadius: '20px',
              marginTop: '12px',
              marginBottom: '12px',
              padding: '0 20px',
              width: 'auto',
              '&.Mui-disabled': {
                opacity: 0.6,
                color: '#ffffff',
                cursor: 'not-allowed',
              },
            }}
            onClick={handleAddButton}
            disabled={prStatus !== '0'}
            color='primary'
          >
            <AddTwoToneIcon />
            ADD {selectedRoleLabel}
          </Button>

          <TableContainer sx={{ margin: '16px 5px', marginTop: '10px' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>WORKING EMPLOYEE(EMP. ID)</StyledTableCell>
                  {/* <StyledTableCell>BILLABLE EMPLOYEE</StyledTableCell> */}
                  {/* <StyledTableCell>TECHNOLOGIES</StyledTableCell> */}
                  <StyledTableCell>TYPE</StyledTableCell>
                  <StyledTableCell>TIME COUNT</StyledTableCell>
                  <StyledTableCell>START DATE</StyledTableCell>
                  <StyledTableCell>END DATE</StyledTableCell>
                  <StyledTableCell>ACTIONS</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredMembers?.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell align='center' colSpan={7}>
                      <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 43 }}>
                        No records found.
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  filteredMembers?.map((member: any) => (
                    <StyledTableRow key={member.id}>
                      <StyledTableCell>
                        {member.working_full_name || 'NA'} {`(${member.working_emp_id})`|| 'NA'}
                      </StyledTableCell>
                      {/* <StyledTableCell>{member.billable_full_name || 'NA'}</StyledTableCell>
                      <StyledTableCell>
                        {member.technologies.length > 0 ? member.technologies.join(', ') : 'N/A'}
                      </StyledTableCell> */}
                      <StyledTableCell>
                        {member.employment_type === '0'
                          ? 'Fixed'
                          : member.employment_type === '1'
                          ? 'Hourly'
                          : member.employment_type === '2'
                          ? 'Monthly'
                          : 'Unknown'}
                      </StyledTableCell>
                      <StyledTableCell>{member.time_count}</StyledTableCell>
                      <StyledTableCell>
                        {`${new Date(member.start_date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {member.end_date
                          ? `${new Date(member.end_date).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}`
                          : 'NA'}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            color='primary'
                            disabled={prStatus !== '0'}
                            onClick={() => handleEditChange(member)}
                          >
                            <BorderColorIcon />
                          </IconButton>

                          <IconButton
                            color='error'
                            disabled={prStatus !== '0'}
                            sx={prStatus !== '0' ? { cursor: 'not-allowed' } : {}}
                            onClick={() =>
                              handleDeletenDialog(member.working_emp_id, member.working_full_name)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    </>
  )
}

const AddNewProjectDialog = ({
  onClose,
  open,
  technologies,
  projectLocation,
  AddNewProjects,
  projectCustomers,
  porjectDomainList,
  projectSourceList,
}: {
  open: boolean
  onClose: () => void
  AddNewProjects: (data: any) => void
  technologies: string[]
  projectLocation: string[]
  allProjectTypes: string[]
  projectCustomers: any
  resetCreateproject: any
  isCreatingproject: any
  selectedSource: any
  selectedProjectName: any
  selectedCustomer: any
  porjectDomainList: []
  projectSourceList: []
}) => {
  const [projectCustomer, setProjectCustomer] = useState('')
  const [projectSource, setProjectSource] = useState(1)
  const [projectsType, setProjectsType] = useState('')
  const [projectStatus, setProjectStatus] = useState('On Going')
  const [projectName, setProjectName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [projectLocations, setProjectLocations] = useState('')
  const [projectDomain, setProjectDomain] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [projectTechnologies, setProjectTechnologies] = useState<string[]>([])
  const [draggingStartDate, setDraggingStartDate] = useState<Date | null>(null)
  const [draggingEndDate, setDraggingEndDate] = useState<Date | null>(null)
  const [prStatus, setPrStatus] = useState<string>('0');

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const handleCreateProject = () => {
    // projectTechnologies

    let projTech: number[] = []
    projectTechnologies.forEach((item) =>
      technologies.forEach((data: any) => {
        if (item === data.name) {
          projTech.push(data.id)
        }
      }),
    )

    const data = {
      id_project_customer: projectCustomer,
      projectName: projectName,
      id_project_source: 1,
      projectsType: projectsType,
      project_status: prStatus,
      description: description,
      projectTechnologies: projTech,
      start_date: startDate,
      end_date: endDate ? endDate : null,
      projectLocations: projectLocations,
      dragging_start_date: draggingStartDate,
      dragging_end_date: draggingEndDate,
      id_domain: projectDomain,
    }
    AddNewProjects(data)
    resetForm()
    handleClose()
  }

  const resetForm = () => {
    setProjectCustomer('')
    setProjectSource(1)
    setPrStatus('0')
    setProjectName('')
    setProjectsType('')
    setProjectLocations('')
    setProjectDomain('')
    setProjectTechnologies([])
    setDescription('')
    setStartDate(null)
    setEndDate(null)
    setDraggingEndDate(null)
    setDraggingStartDate(null)
  }

  const handleProjectLocationChange = (event: any) => {
    setProjectLocations(event.target.value)
  }

  const handleProjectDomainChange = (event: any) => {
    setProjectDomain(event.target.value)
  }

  const handleProjectsType = (event: any) => {
    setProjectsType(event?.target.value)
  }

  const handleChangeMultiple = (event: any) => {
    const {
      target: { value },
    } = event
    setProjectTechnologies(typeof value === 'string' ? value.split(',') : value)
  }

  const handleDeleteTechnology = (technology: string) => {
    const updatedTechnologies = projectTechnologies.filter((tech) => tech !== technology)
    setProjectTechnologies(updatedTechnologies)
  }

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === 'Dragging') {
      setProjectStatus('Dragging')
    } else {
      setProjectStatus(value)
    }
  }
  const handleProjectCustomerChange = (event: any) => {
    setProjectCustomer(event.target.value)
  }

  const handleProjectSourceChange = (event: any) => {
    setProjectSource(1)
  }
  const handleStartDateChange = (date: any) => {
    setStartDate(date)
  }
  const haddleEndDate = (date: any) => {
    setEndDate(date)
  }
  const haddleDraggingEndDateChange = (date: any) => {
    setDraggingEndDate(date)
  }
  const haddleDraggingStartDateChange = (date: any) => {
    setDraggingStartDate(date)
  }
  const handleProjectStatusChange = (event: any) => {
    setPrStatus(event.target.value)
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Heading>New Project Details</Heading>
      </BootstrapDialogTitle>
      <Divider orientation='horizontal' sx={{ marginBottom: '19px' }} flexItem></Divider>
      <Box sx={{ padding: '0 16px', marginTop: '1px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Client <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label=' Client'
                type='small'
                fullWidth
                value={projectCustomer}
                onChange={handleProjectCustomerChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '250px',
                    },
                  },
                }}
              >
                {projectCustomers.map((customer: any) => (
                  <MenuItem key={customer?.id} value={customer.id}>
                    {customer.customer_name}
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputField
              id='outlined-required'
              label='Project Name'
              size='small'
              fullWidth
              value={projectName}
              onChange={(e: any) => setProjectName(e.target.value)}
              margin='normal'
              placeholder='Please enter project name'
            />
          </Grid>
          {/* <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Project Source <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Project Source'
                type='small'
                fullWidth
                value={projectSource}
                onChange={handleProjectSourceChange}
              >
                {projectSourceList?.map((source: any) => (
                  <MenuItem key={source?.id} value={source?.id}>
                    {source?.source_name}
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid> */}
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Project Type<span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Project Type'
                type='small'
                fullWidth
                value={projectsType}
                onChange={handleProjectsType}
              >
                <MenuItem value='Billable'>Billable</MenuItem>
                <MenuItem value='NonBillable'>Non-Billable</MenuItem>
              </StyledSelectField>
            </CustomFormControll>
          </Grid>

          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Area Of Practice <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Area Of Practice'
                type='small'
                fullWidth
                value={projectDomain}
                onChange={handleProjectDomainChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '250px',
                    },
                  },
                }}
              >
                {porjectDomainList.map((domain: any) => (
                  <MenuItem key={domain?.id} value={domain.id}>
                    {domain?.domain_name}
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid>
          <Grid item xs={4} sm={4}>
            <CustomFormControll>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Project Location <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='select-project-label'
                variant='outlined'
                label='Project Location'
                type='small'
                fullWidth
                value={projectLocations}
                onChange={handleProjectLocationChange}
              >
                {projectLocation.map((location: any) => (
                  <MenuItem key={location?.id} value={location.id}>
                    {location.location}
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid>

          <Grid item xs={4} sm={4}>
              <CustomFormControll>
                <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                  Project Status <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelectField
                  labelId='select-project-label'
                  variant='outlined'
                  label='Project Status'
                  type='small'
                  fullWidth
                  // disabled={isongoing !== '0'}
                  value={prStatus}
                  onChange={handleProjectStatusChange}
                >
                  <MenuItem value={'1'}>Completed</MenuItem>
                  <MenuItem value={'2'}>Dragging</MenuItem>
                  <MenuItem value={'0'}>OnGoing</MenuItem>
                  <MenuItem value={'3'}>Paused</MenuItem>
                </StyledSelectField>
              </CustomFormControll>
            </Grid>
          {/* <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '9px' }}
          >
            <FormLabel id='ProjectStatusLabel'>Project Status :</FormLabel>
            <div style={{ display: 'flex', marginLeft: '1rem' }}>
              <RadioGroup
                row
                aria-labelledby='ProjectStatusLabel'
                name='ProjectStatus'
                value={projectStatus}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value='Completed'
                  control={<Radio size='small' />}
                  label={<Typography variant='body2'>Completed</Typography>}
                />
                <FormControlLabel
                  value='Dragging'
                  control={<Radio size='small' />}
                  label={<Typography variant='body2'>Dragging</Typography>}
                />
                <FormControlLabel
                  value='On Going'
                  control={<Radio size='small' />}
                  label={<Typography variant='body2'>On Going</Typography>}
                />
                <FormControlLabel
                  value='Paused'
                  control={<Radio size='small' />}
                  label={<Typography variant='body2'>Paused</Typography>}
                />
              </RadioGroup>
            </div>
          </div> */}

          <Grid item xs={12} sm={12}>
            <InputField
              id='outlined-required'
              label='Description'
              size='small'
              fullWidth
              multiline
              required={false}
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              margin='normal'
              placeholder='Please enter description'
            />
          </Grid>

          {/* <Grid item xs={6} sm={6}>
            <CustomFormControll>
              <InputLabel id='demo-multiple-checkbox-label' sx={{ marginTop: -0.7 }}>
                Project Technologies<span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <StyledSelectField
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                variant='outlined'
                label='Project Technologies'
                multiple
                input={<OutlinedInput label='Project Technologies' />}
                value={projectTechnologies}
                // defaultValue={}
                onChange={handleChangeMultiple}
                renderValue={(selected: any) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {technologies.map((technology: any) => (
                  <MenuItem key={technology?.id} value={technology.name}>
                    <Checkbox checked={projectTechnologies.includes(technology.name)} />
                    <ListItemText primary={technology.name} />.
                  </MenuItem>
                ))}
              </StyledSelectField>
            </CustomFormControll>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
              <FormLabel id='ProjectStatusLabel'>Selected Tech. </FormLabel>
              <Stack direction='row' spacing={1} marginLeft={1}>
                {projectTechnologies.map((data: any, index: number) =>
                  index < 3 ? (
                    <Chip
                      key={data}
                      label={data}
                      variant='outlined'
                      onDelete={() => handleDeleteTechnology(data)}
                    />
                  ) : index === 3 ? (
                    <Tooltip title={projectTechnologies.join(', ')} key={`...`}>
                      <Chip label='...' variant='outlined' />
                    </Tooltip>
                  ) : null,
                )}
              </Stack>
            </Box>
          </Grid> */}

          <Grid item xs={6} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box width={'100%'}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '42px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                    }}
                    label='Start Date'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Box width={'100%'}>
                  <DatePicker
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        height: '44px',
                        marginTop: '-10px',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        marginTop: '-15px',
                      },
                    }}
                    label='End Date'
                    slotProps={{
                      textField: {
                        required: false,
                      },
                    }}
                    value={endDate}
                    onChange={haddleEndDate}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          {projectStatus === 'Dragging' && (
            <>
              <Grid item xs={4} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <Box>
                      <DatePicker
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '20px',
                            height: '42px',
                            marginTop: '-14px',
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: '14px',
                            marginTop: '-18px',
                          },
                        }}
                        label='Dragging Start Date'
                        value={draggingStartDate}
                        onChange={haddleDraggingStartDateChange}
                        slotProps={{
                          textField: {
                            required: true,
                            error: false,
                          },
                        }}
                      />
                    </Box>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <Box>
                      <DatePicker
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '20px',
                            height: '42px',
                            marginTop: '-14px',
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: '14px',
                            marginTop: '-18px',
                          },
                        }}
                        label='Dragging End Date'
                        value={draggingEndDate}
                        onChange={haddleDraggingEndDateChange}
                        slotProps={{
                          textField: {
                            required: false,
                            error: false,
                          },
                        }}
                      />
                    </Box>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '2px' }}>
        <CancelButton
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
          }}
          onClick={handleClose}
        >
          CANCEL
        </CancelButton>
        <Button
          variant='contained'
          color='primary'
          sx={{
            fontSize: '13px',
            height: '38px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginRight: '10px',
            marginBottom: 2,
            width: '100px',
            '&.Mui-disabled': {
              opacity: 1,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreateProject}
          disabled={
            !projectCustomer ||
            !projectName ||
            !projectsType ||
            !startDate ||
            // !projectSource ||
            !projectLocations ||
            // !projectTechnologies ||
            !projectDomain 
            // !prStatus
          }
        >
          CREATE
        </Button>
      </Box>
    </Dialog>
  )
}

const Projects = (props: any) => {
  const {
    projectSourceList,
    porjectDomainList,
    fetchProjectDomain,
    fetchProjectSource,
    fetchProjectTypes,
    allProjectTypes,
    isGettingProjectsData,
    technologies,
    fetchAllTechnologies,
    fetchProjectLocation,
    projectLocation,
    AddNewProjects,
    resetCreateproject,
    fetchDeletedproject,
    projectCustomersApi,
    projectCustomers,
    restartProjectApi,
    isDeletingProject,
    resetDeleteType,
    resetRestart,
    isRestartingProject,
    isCreatingproject,
    projectDetailsData,
    fetchProjectDetails,
    AllEmpList,
    fetchAllEmpAPI,
    fetchAddEmpBasedOnRoleAPI,
    getEmpBasedOnRoles,
    resetAddDeveloper,
    isAddingEmp,
    fetchUpdatedProjectAPI,
    resetUpdatedProject,
    isProjectUpdated,
    deleteApiCall,
    isRecordsdeleted,
    resetDeleteAPI,
    fetchEditedProjectsAPI,
    isEditingProject,
    resetEditProject,
    isGettingProjectDetailsData,
    isGettingWorkingEmpData,
    isProjectUpdating,
    isProjectEditing,
    isProjectCreating,
  } = props

  const [searchQuery, setSearchQuery] = useState('')
  const [projectType, setProjectType] = useState('All_Projects')
  const [filteredRowsData, setFilteredRowsData] = useState<any[]>([])
  const [isNewIDSRDialogOpen, setisNewIDSRDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [restartDialogOpen, setRestarDialogOpen] = useState(false)
  const [page, setPage] = useState(1)
  const limit = 100
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null)
  const [selectedProjectName, setSelectedProjectName] = useState<string | null>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null)
  const [selectedSource, setselectedSource] = useState<any | null>(null)
  const [projectId, setProjectId] = useState<any | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [value, setValue] = useState('')

  const handleProjectTypeChange = (event: any) => {
    setProjectType(event.target.value)
    setPage(1)
  }

  const handleCloseNewIDSRialog = () => {
    setisNewIDSRDialogOpen(false)
  }
  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false)
  }

  const handleCloseRestartDialog = () => {
    setRestarDialogOpen(false)
  }

  useEffect(() => {
    setPage(1)
  }, [searchQuery])

  const handleOpenNewProjectDialog = () => {
    setisNewIDSRDialogOpen(true)
    fetchAllTechnologies()
    fetchProjectLocation()
    projectCustomersApi()
    fetchProjectDomain()
    fetchProjectSource()
    // projectCustomersApi()
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (isEditingProject) {
      toast.success('Project updated successfully')
      resetEditProject()
    }
  }, [isEditingProject])

  useEffect(() => {
    if (isDeletingProject) {
      toast.success('Project deleted successfully')
      resetDeleteType()
    }
  }, [isDeletingProject])

  useEffect(() => {
    if (isCreatingproject) {
      toast.success('Project created successfully')
      resetCreateproject()
    }
  }, [isCreatingproject])

  useEffect(() => {
    if (isRestartingProject) {
      toast.success('Project restarted successfully')
      resetRestart()
    }
  }, [isRestartingProject])

  useEffect(() => {
    let statusValue
    switch (projectType) {
      case 'Ongoing_Projects':
        statusValue = 0
        break
      case 'Completed_Projects':
        statusValue = 1
        break
      case 'Dragging_Projects':
        statusValue = 2
        break
      case 'Paused_Projects':
        statusValue = 3
        break
      default:
        statusValue = null
    }

    fetchProjectTypes({
      status: statusValue,
      page: page,
      limit: limit,
      search: searchQuery ? searchQuery : '',
    })
  }, [
    projectType,
    searchQuery,
    page,
    limit,
    isCreatingproject,
    isRestartingProject,
    isDeletingProject,
    isEditingProject,
  ])

  const UserRole = localStorage.getItem('roles')

  const actionButtons = (projectStatus: any, projectId: number, projectName: string) => {
    if (projectStatus === '1') {
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color='primary'
            onClick={() => handleRestartOpenDialog(projectId, projectName)}
          >
            <ReplayIcon />
          </IconButton>
        </Box>
      )
    } else {
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color='error'
            disabled={!UserRole?.includes('Admin')}
            onClick={() => handleDeleteOpenDialog(projectId, projectName)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  }

  const handleDeleteConfirm = () => {
    fetchDeletedproject({ id: selectedProjectId })
    resetDeleteType()
    setDeleteDialogOpen(false)
  }

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedProjectId(null)
  }

  const handleRestartOpenDialog = (projectId: number, projectName: string) => {
    setRestarDialogOpen(true)
    setSelectedProjectName(projectName)
    setSelectedProjectId(projectId)
  }

  const handleDeleteOpenDialog = (projectId: number, projectName: string) => {
    setDeleteDialogOpen(true)
    setSelectedProjectId(projectId)
    setSelectedProjectName(projectName)
  }

  const handleOnRowClick = (
    projectId: number,
    projectName: string,
    customer: string,
    source: string,
    type: string,
  ) => {
    setProjectId(projectId)
    setIsEditDialogOpen(true)
    fetchProjectDetails({ id: projectId })
    fetchAllTechnologies()
    fetchProjectLocation()
    projectCustomersApi()
    setSelectedCustomer(customer)
    setSelectedProjectName(projectName)
    setselectedSource(source)
    fetchProjectDomain()
    fetchProjectSource()
  }
  useEffect(() => {
    if (isAddingEmp || isProjectUpdated || isRecordsdeleted) {
      fetchProjectDetails({ id: projectId })
    }
  }, [isAddingEmp, isProjectUpdated, isRecordsdeleted])

  const handleClear = () => {
    setValue('')
    setSearchQuery('')
  }

  return (
    <>
      {' '}
      <RestartDialogBox
        open={restartDialogOpen}
        onClose={handleCloseRestartDialog}
        allProjectTypes={allProjectTypes.data}
        restartProjectApi={restartProjectApi}
        selectedProjectId={selectedProjectId}
        selectedProjectName={selectedProjectName}
        resetRestart={resetRestart}
        isRestartingProject={isRestartingProject}
      />
      <Dialog
        open={deleteDialogOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <>
            Are you sure you want to delete the{' '}
            <span style={{ fontFamily: 'Monter Medium' }}>{selectedProjectName}</span> project ?{' '}
          </>
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '-10px' }}>
            <CancelButton
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '38px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
              }}
              onClick={handleCloseDialog}
            >
              CANCEL
            </CancelButton>
            <Button
              variant='contained'
              color='primary'
              sx={{
                fontSize: '13px',
                height: '38px',
                borderRadius: '20px',
                padding: '5px 20px',
                marginRight: '10px',
                marginBottom: 2,
                width: '100px',
                '&.Mui-disabled': {
                  opacity: 1,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handleDeleteConfirm}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <AddNewProjectDialog
        open={isNewIDSRDialogOpen}
        onClose={handleCloseNewIDSRialog}
        technologies={technologies}
        projectLocation={projectLocation}
        AddNewProjects={AddNewProjects}
        allProjectTypes={allProjectTypes.data}
        projectCustomers={projectCustomers}
        resetCreateproject={resetCreateproject}
        isCreatingproject={isCreatingproject}
        selectedSource={selectedSource}
        selectedProjectName={selectedProjectName}
        selectedCustomer={selectedCustomer}
        porjectDomainList={porjectDomainList}
        projectSourceList={projectSourceList}
      />
      <EditProjectDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        technologies={technologies}
        projectLocation={projectLocation}
        AddNewProjects={AddNewProjects}
        allProjectTypes={allProjectTypes.data}
        projectCustomers={projectCustomers}
        resetCreateproject={resetCreateproject}
        isCreatingproject={isCreatingproject}
        selectedSource={selectedSource}
        selectedProjectName={selectedProjectName}
        selectedCustomer={selectedCustomer}
        projectId={projectId}
        projectDetailsData={projectDetailsData}
        AllEmpList={AllEmpList}
        fetchAllEmpAPI={fetchAllEmpAPI}
        fetchAddEmpBasedOnRoleAPI={fetchAddEmpBasedOnRoleAPI}
        resetAddDeveloper={resetAddDeveloper}
        isAddingEmp={isAddingEmp}
        getEmpBasedOnRoles={getEmpBasedOnRoles}
        fetchUpdatedProjectAPI={fetchUpdatedProjectAPI}
        resetUpdatedProject={resetUpdatedProject}
        isProjectUpdated={isProjectUpdated}
        deleteApiCall={deleteApiCall}
        isRecordsdeleted={isRecordsdeleted}
        resetDeleteAPI={resetDeleteAPI}
        fetchEditedProjectsAPI={fetchEditedProjectsAPI}
        isGettingProjectDetailsData={isGettingProjectDetailsData}
        isGettingWorkingEmpData={isGettingWorkingEmpData}
        porjectDomainList={porjectDomainList}
        projectSourceList={projectSourceList}
        isProjectUpdating={isProjectUpdating}
      />
      <StyledPaper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-15px',
          }}
        >
          <SearchBoxCustom
            id='outlined-basic'
            placeholder='Search Project'
            variant='outlined'
            size='small'
            value={value}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                setSearchQuery((e.target as HTMLInputElement).value)
                setValue((e.target as HTMLInputElement).value)
              }
            }}
            onChange={(e: any) => {
              if (e.target.value === '') setSearchQuery('')
              return setValue(e.target.value)
            }}
            width='40%'
            InputProps={{
              startAdornment: <SearchIconStyle />,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='clear-icon' onClick={handleClear} edge='end'>
                    {value && <ClearIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box display={'flex'}>
            <StyledFormControl>
              <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                Select Project Type
              </InputLabel>
              <SelectField
                sx={{ borderRadius: '20px', width: '250px', height: '42px', marginRight: '-90px' }}
                variant='outlined'
                type='small'
                label='Select Project Type'
                value={projectType}
                onChange={handleProjectTypeChange}
              >
                <MenuItem value='All_Projects'>All Projects</MenuItem>
                <MenuItem value='Dragging_Projects'>Dragging Projects</MenuItem>
                <MenuItem value='Paused_Projects'>Paused Projects</MenuItem>
                <MenuItem value='Completed_Projects'>Completed Projects</MenuItem>
                <MenuItem value='Ongoing_Projects'>Ongoing Projects</MenuItem>
              </SelectField>
            </StyledFormControl>
            <Button
              sx={{
                fontSize: '13px',
                letterSpacing: '0px',
                borderRadius: '20px',
                padding: '5px 20px',
                width: '280px',
                height: 'auto',
                whiteSpace: 'nowrap',
                fontFamily: 'Montserrat-Medium',
              }}
              onClick={handleOpenNewProjectDialog}
              color='primary'
              // disabled={}
            >
              <AddTwoToneIcon />
              ADD NEW PROJECT
            </Button>
          </Box>
        </Box>
        <TableContainer sx={{ margin: '16px 0', marginTop: '2px' }} component={Paper}>
          <Dialog open={isGettingProjectsData || isProjectEditing || isProjectCreating} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>PROJECT</StyledTableCell>
                <StyledTableCell>CLIENTS</StyledTableCell>
                <StyledTableCell> SOURCE</StyledTableCell>
                <StyledTableCell>DOMAIN</StyledTableCell>
                <StyledTableCell>PROJECT TYPE </StyledTableCell>
                <StyledTableCell> STATUS </StyledTableCell>
                <StyledTableCell> START DATE </StyledTableCell>
                <StyledTableCell> END DATE </StyledTableCell>
                <StyledTableCell> ACTIONS </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {allProjectTypes?.data?.map((project: any, index: number) => (
                <StyledTableRow
                  key={index}
                  onClick={() =>
                    handleOnRowClick(
                      project.project_id,
                      project.project,
                      project.customer,
                      project.source,
                      project.projectType,
                    )
                  }
                >
                  <StyledTableCell sx={styles.tableCellsStyles}>{project.project}</StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>{project.customer}</StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>{project.source}</StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>
                    {project.domain ?? 'NA'}
                  </StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>
                    {project.projectType}
                  </StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>
                    {project.status === '0'
                      ? 'Ongoing'
                      : project.status === '1'
                      ? 'Completed'
                      : project.status === '2'
                      ? 'dragging'
                      : project.status === '3'
                      ? 'Paused'
                      : ''}
                  </StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>
                    {`${new Date(project.startDate).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}`}
                  </StyledTableCell>
                  <StyledTableCell sx={styles.tableCellsStyles}>
                    {project.endDate
                      ? `${new Date(project.endDate).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}`
                      : 'NA'}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={styles.tableCellsStyles}
                    onClick={(event: any) => event.stopPropagation()}
                  >
                    {actionButtons(project.status, project.project_id, project.project)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {allProjectTypes?.data?.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={8}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No records found.
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Pagination
            count={Math.ceil(allProjectTypes.count / limit)}
            page={page}
            onChange={handleChangePage}
            color='primary'
          />
        </Box>
      </StyledPaper>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    allProjectTypes: projectManagementEntity.getAllProjectManagement(state).fetchProjectTypes,
    isGettingProjectsData: projectManagementUI.getProjectManagemet(state).isGettingProjectsData,
    isGettingProjectTypes: projectManagementUI.getProjectManagemet(state).isGettingProjectTypes,
    isProjectUpdating: projectManagementUI.getProjectManagemet(state).isProjectUpdating,
    isProjectEditing: projectManagementUI.getProjectManagemet(state).isProjectEditing,
    technologies: projectManagementEntity.getAllProjectManagement(state).fetchAllTechnologies,
    projectLocation: projectManagementEntity.getAllProjectManagement(state).fetchAllProjectLocation,
    projectCustomers: projectManagementEntity.getAllProjectManagement(state).getAllProjectCustomers,
    isDeletingProject: projectManagementUI.getProjectManagemet(state).isDeletingProject,
    isRestartingProject: projectManagementUI.getProjectManagemet(state).isRestartingProject,
    isCreatingproject: projectManagementUI.getProjectManagemet(state).isCreatingproject,
    isProjectCreating: projectManagementUI.getProjectManagemet(state).isProjectCreating,
    projectDetailsData: projectManagementEntity.getAllProjectManagement(state).getAllprojectDetails,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,
    isAddingEmp: projectManagementUI.getProjectManagemet(state).isAddingEmp,
    getEmpBasedOnRoles: projectManagementEntity.getAllProjectManagement(state).getEmpBasedOnRoles,
    isProjectUpdated: projectManagementUI.getProjectManagemet(state).isProjectUpdated,
    isRecordsdeleted: projectManagementUI.getProjectManagemet(state).isRecordsdeleted,
    isEditingProject: projectManagementUI.getProjectManagemet(state).isEditingProject,
    isGettingProjectDetailsData:
      projectManagementUI.getProjectManagemet(state).isGettingProjectDetailsData,
    isGettingWorkingEmpData: projectManagementUI.getProjectManagemet(state).isGettingWorkingEmpData,
    porjectDomainList: projectManagementEntity.getAllProjectManagement(state).fetchProjectDomain,
    projectSourceList: projectManagementEntity.getAllProjectManagement(state).fetchProjectSource,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchProjectSource: () => dispatch(projectSource.request()),
    fetchProjectDomain: () => dispatch(projectDomain.request()),
    fetchProjectTypes: (data: any) => dispatch(projectTypes.request(data)),
    fetchAllTechnologies: () => dispatch(fetchTechnoligies.request()),
    fetchProjectLocation: () => dispatch(fetchProjectLocation.request()),
    AddNewProjects: (data: any) => dispatch(addNewProject.request(data)),
    resetCreateproject: () => dispatch(addNewProject.reset()),
    fetchDeletedproject: (data: any) => dispatch(fetchDeletingProject.request(data)),
    resetDeleteType: () => dispatch(fetchDeletingProject.reset()),
    projectCustomersApi: () => dispatch(fetchProjectCustomers.request()),
    restartProjectApi: (data: any) => dispatch(fetchRestartedProject.request(data)),
    resetRestart: () => dispatch(fetchRestartedProject.reset()),
    fetchProjectDetails: (data: any) => dispatch(fetchProjectDetails.request(data)),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    fetchAddEmpBasedOnRoleAPI: (data: any) => dispatch(fetchEmpBasedOnRoles.request(data)),
    resetAddDeveloper: () => dispatch(fetchEmpBasedOnRoles.reset()),
    fetchUpdatedProjectAPI: (data: any) => dispatch(fetchUpdatedProject.request(data)),
    resetUpdatedProject: () => dispatch(fetchUpdatedProject.reset()),
    deleteApiCall: (data: any) => dispatch(fetchDeletedRecords.request(data)),
    resetDeleteAPI: () => dispatch(fetchDeletedRecords.reset()),
    fetchEditedProjectsAPI: (data: any) => dispatch(fetchEditedProject.request(data)),
    resetEditProject: () => dispatch(fetchEditedProject.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
