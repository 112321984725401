import styled from 'styled-components'
import { StyledTableCell } from '../Common/CommonStyles'
import { TableRow, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import PendingIcon from '@mui/icons-material/Pending'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '15px',
  boxShadow: '0px 5px 2px #6c6c6c10',
  opacity: '1',
}))

const style = {
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
    cursor: 'pointer'
  },
}

const TableRowContainer = (props: any) => {
  const { row, idx, onRowClick, statusType } = props

  const stripHtmlTags = (html: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = html

    let textContent = tempElement.textContent || tempElement.innerText || ''

    return textContent
  }

  const hasApprovedStatus = row.hasOwnProperty('approve_status');
  console.log(hasApprovedStatus, 'hasApprovedStatus');
  

  return (
    hasApprovedStatus ? <StyledTableRow id='body' key={idx} sx={style.border} onClick={() => onRowClick(row)}>
      <StyledTableCell>
        {row.employee_id ? `${row.name} (${row.employee_id})` : 'N/A'}
      </StyledTableCell>
      <StyledTableCell>{row.subject ? row.subject : 'N/A'}</StyledTableCell>
      <StyledTableCell>{row.project_name === 'Other' ? row.other_project_name : row.project_name}</StyledTableCell>
      <StyledTableCell>{row.mandate_type_id ? statusType.get(row.mandate_type_id) : 'N/A'}</StyledTableCell>
      <StyledTableCell>{row.time_spent ? row.time_spent : 'N/A'}</StyledTableCell>
      <StyledTableCell align='left'>{row.approve_status === 1 ? <Tooltip title={'Approved'}><CheckIcon fontSize='medium' style={{ color: 'green' }} /></Tooltip>
        : <Tooltip title={'Pending'}><PendingIcon fontSize='medium' style={{ color: 'red' }} /></Tooltip>}
      </StyledTableCell>
    </StyledTableRow> :
      <StyledTableRow id='body' key={idx} sx={style.border} onClick={() => onRowClick(row)}>
        <StyledTableCell>
          {row.employee_id ? `${row.name} (${row.employee_id})` : 'N/A'}
        </StyledTableCell>
        <StyledTableCell>{row.subject ? row.subject : 'N/A'}</StyledTableCell>
        <StyledTableCell>{row.project_name === 'Other' ? row.other_project_name : row.project_name}</StyledTableCell>
        <StyledTableCell>{row.mandate_type_id ? statusType.get(row.mandate_type_id) : 'N/A'}</StyledTableCell>
        <StyledTableCell>{row.time_spent ? row.time_spent : 'N/A'}</StyledTableCell>
      </StyledTableRow>
    
  )
}

export default TableRowContainer
