import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import './IDSR.scss'
import PendingIcon from '@mui/icons-material/Pending'
import CheckIcon from '@mui/icons-material/Check'
import priority from './constant'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Modal,
  // FormControlLabel, 
  // Checkbox, 
  // FormGroup,
  Tooltip,
  InputAdornment,
} from '@mui/material'
import {
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import {
  createNewPlanForTheDay,
  createNewIDSR,
  fetchProjectsName,
  getAttendanceTimesheet,
  getIDSRs,
  getPlans,
  getStatusType,
  getTaskStatus,
  fetchMandateType,
} from '../../actions'
import { attendanceEntity, attendanceUI, dashboardEntity, projectManagementEntity } from '../../reducers'
import { fetchPlans, fetchIDSRs, fetchRCAs, fetchStatusType } from '../../reducers/entities'
import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Grow from '@mui/material/Grow'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface IFormValues {
  projectName: string
  otherProjectName: string
  timeSpend: string
  taskDescription: string
  mandateId: string
  no_of_pr_raised?: number | null
  no_of_pr_approved?: number | null
  no_of_reworked_pr?: number | null
  isYesterdayTask?: boolean
  statusId?: string
  priority?: string
}
const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: 'Montserrat-Medium',
  marginLeft: '1rem',
}))


const CustomFormControll = styled(FormControl)(() => ({
  marginLeft: '1px',
  marginTop: '5px',
}))

const InputField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))
const InputField1 = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'white',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface ICancelButton {
  fontSize?: string
  height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: `${fontSize}`,
  height: `${height}`,
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const SelectField = styled(Select)(() => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
  },
}))
const StyledSelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '9px 11px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
    padding: '9px 11px',
    marginLeft: '-1px',
  },
}))

const IDSRTable = (props: any) => {
  const { isFetchingIDSRs, getStatusType, getTaskStatus, mandateData, getMandateType } = props

  let statusType = new Map<number, string>()

  useEffect(()=>{
    getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
  },[])

  mandateData?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
    statusType.set(mandate.id, mandate.mandate_name);
  });

  let taskStatus = new Map<number, string>()

  for (const key in getTaskStatus) {
    taskStatus.set(getTaskStatus[key]?.id, getTaskStatus[key]?.status_name)
  }

  return (
    <>
      <Dialog open={isFetchingIDSRs} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <>
        <TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE (DAY)</StyledTableCell>
                <StyledTableCell>SUBJECT</StyledTableCell>
                <StyledTableCell>PROJECT NAME</StyledTableCell>
                <StyledTableCell>TYPE</StyledTableCell>
                <StyledTableCell>STATUS</StyledTableCell>
                <StyledTableCell>TIME SPENT (MINS)</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}`}{' '}
                      (
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}`}
                      )
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                    <StyledTableCell align='left'>
                      {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {data.status_id ? taskStatus.get(data.status_id) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.time_spent}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </>
    </>
  )
}

const PlanForTheDayTable = (props: any) => {
  const { isFetchingPlans, getStatusType, mandateData } = props

  let statusType = new Map<number, string>()

  mandateData?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
    statusType.set(mandate.id, mandate.mandate_name);
  });
  
  return (
    <>
      <Dialog open={isFetchingPlans} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <>
        <TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE (DAY)</StyledTableCell>
                <StyledTableCell>SUBJECT</StyledTableCell>
                <StyledTableCell>PROJECT NAME</StyledTableCell>
                <StyledTableCell>TYPE</StyledTableCell>
                <StyledTableCell>ESTIMATED TIME (MINS)</StyledTableCell>
                <StyledTableCell>STATUS</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}`}{' '}
                      (
                      {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}`}
                      )
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                    <StyledTableCell align='left'>
                      {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{data.time_spent}</StyledTableCell>
                    <StyledTableCell align='left'>{data.approve_status === 1 ? <Tooltip title={'Approved'}><CheckIcon fontSize='medium' style={{ color: 'green' }} /></Tooltip>
                    : data.approve_status === 0 ? <Tooltip title={'Pending'}><PendingIcon fontSize='medium' style={{ color: 'red' }} /></Tooltip> : ''}
                  </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </>
    </>
  )
}

const CreateIDSRDialog = ({
  open,
  onClose,
  createIDSR,
  AllProjectsName,
  getStatusType,
  getTaskStatus,
  getManagerDetails,
  mandateData
}: {
  mandateData: any
  open: boolean
  onClose: () => void
  createIDSR: (data: any) => void
  AllProjectsName: string[]
  getStatusType: [{ id: number; type_name: string }]
  getTaskStatus: [{ id: number; status_name: string }]
  getManagerDetails: {email:string; name: string}
}) => {
  
  const [to, setTo] = useState(getManagerDetails?.email || '')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)  
  const [cc, setCc] = useState('')
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      otherProjectName: '',
      timeSpend: '',
      taskDescription: '',
      statusId:'2',
      priority:'High',
      mandateId: String(mandateData?.data?.[0]?.id),
      no_of_pr_raised: null,
      no_of_pr_approved: null,
      no_of_reworked_pr: null,
      // isYesterdayTask: false,
    },
  ])

  useEffect(()=>{
    setTo(getManagerDetails?.email || '');
  },[getManagerDetails])
  
  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        statusId:'2',
        priority:'High',
        mandateId: String(mandateData?.data?.[0]?.id) ? String(mandateData?.data?.[0]?.id) : '' ,
        no_of_pr_raised: null,
        no_of_pr_approved: null,
        no_of_reworked_pr: null,
      },
    ])
  }

  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
        otherProjectName: value === 'Other' ? newData[index].otherProjectName : '',
      }
      return newData
    })
  }
  const handleOtherProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        otherProjectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    // Convert the value to a number
    const numericValue = Number(value)

    if (!isNaN(numericValue) && numericValue <= 240) {
      setFormValues((prevData) => {
        const newData = [...prevData]
        newData[index] = {
          ...newData[index],
          timeSpend: value,
        }
        return newData
      })
    }
  }

  const handlePRRaised = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_pr_raised: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handlePRApproved = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_pr_approved: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handleReworkedPR = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_reworked_pr: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return (
          value.projectName === '' ||
          value.timeSpend === '' ||
          value.taskDescription === '' ||
          (value.projectName === 'Other' && value.otherProjectName === '') ||
          value.mandateId === '' || value.statusId === '' || value.priority === ''
        )
      }) ||
      toError ||
      !to ||
      (cc !== '' && ccError)
    return hasError
  }

  const handleCreateIDSR = () => {
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      const data = {
        to: to,
        cc: cc,
        idsrData: formValues,
      }
      createIDSR(data)
      resetForm()
    }
  }

  const resetForm = () => {
    setTo(getManagerDetails?.email || '')
    setToError(false)
    setCcError(false)
    setCc('')
    setFormValues([
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        mandateId: String(mandateData?.data?.[0]?.id),
        statusId:'2',
        priority: 'High',
        no_of_pr_raised: null,
        no_of_pr_approved: null,
        no_of_reworked_pr: null,
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  const handleProjectTypeChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        mandateId: String(value),
      }
      return newData
    })
  }

  const handleProjectStatusChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        statusId: value,
      }
      return newData
    })
  }

  const handlePriorityChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        priority: value,
      }
      return newData
    })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1);
    return `${hours} hrs`;
  };

  // const handleCheckboxChange = (index: number, event:any) => {    
  //   setFormValues((prevData) => {
  //     const newData = [...prevData]
  //     newData[index] = {
  //       ...newData[index],
  //       isYesterdayTask: event.target.checked,
  //     }
  //     return newData
  //   })
  // };

  return (
    <Dialog open={open} maxWidth='lg' className='create_IDSR_wrapper'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }}>
          Create Timesheet
        </Typography>
      </BootstrapDialogTitle>
      <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField1
              id='outlined-required'
              label='Cc'
              size='small'
              fullWidth
              value={cc}
              onChange={(e) => handleCc(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={ccError}
              helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          {formValues.map((element, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              className='project_wrapper'
              sx={{
                paddingBottom: '20px',
                height: 'auto',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                xs={element.projectName === 'Other' ? 2 : 2.4}
                sm={element.projectName === 'Other' ? 2 : 2.4}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Select Project <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-project-label'
                    variant='outlined'
                    label='Select Project:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.projectName || ''}
                    onChange={(e) => handleProjectNameChange(index, e.target.value)}
                  >
                    {AllProjectsName.map((project: string) => (
                      <MenuItem key={project} value={project}>
                        {project}
                      </MenuItem>
                    ))}
                    <MenuItem value='Other'>Other</MenuItem>
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              {element.projectName === 'Other' && (
                <Grow in={element.projectName === 'Other'} timeout={500}>
                  <Grid
                    item
                    xs={element.projectName === 'Other' ? 2 : 2.4}
                    sm={element.projectName === 'Other' ? 2 : 2.4}
                  >
                    <InputField
                      id='outlined-required'
                      label='Project Name'
                      size='small'
                      fullWidth
                      autoFocus
                      value={element.otherProjectName || ''}
                      onChange={(e) => handleOtherProjectNameChange(index, e.target.value)}
                      margin='normal'
                    />
                  </Grid>
                </Grow>
              )}
              <Grid
                item
                xs={element.projectName === 'Other' ? 2 : 2.4}
                sm={element.projectName === 'Other' ? 2 : 2.4}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Select Type <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-type-label'
                    variant='outlined'
                    label='Select Type:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.mandateId }
                    onChange={(e) => handleProjectTypeChanges(index, e.target.value as string)}
                  >
                    {mandateData?.data?.map((type: any) => {
                      return (
                        <MenuItem key={`${type.id}${type.mandate_name}`} value={String(type.id)}>
                          {type.mandate_name}
                        </MenuItem>
                      )
                    })}
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              <Grid
                item
                xs={element.projectName === 'Other' ? 2 : 2.4}
                sm={element.projectName === 'Other' ? 2 : 2.4}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Task Status <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-type-label'
                    variant='outlined'
                    label='Task Status:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.statusId || '2'}
                    onChange={(e) => handleProjectStatusChanges(index, e.target.value as string)}
                  >
                    {getTaskStatus?.map((type, index) => {
                      return (
                        <MenuItem key={`${type.id}${type.status_name}`} value={type.id}>
                          {type.status_name}
                        </MenuItem>
                      )
                    })}
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              <Grid
                item
                xs={element.projectName === 'Other' ? 2 : 2.4}
                sm={element.projectName === 'Other' ? 2 : 2.4}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Priority <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-type-label'
                    variant='outlined'
                    label='Priority:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.priority}
                    onChange={(e) => handlePriorityChanges(index, e.target.value as string)}
                  >
                    {priority?.map((type:any, index:number) => {
                      return (
                        <MenuItem key={`${type}`} value={type}>
                          {type}
                        </MenuItem>
                      )
                    })}
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              <Grid
                item
                xs={element.projectName === 'Other' ? 2 : 2.4}
                sm={element.projectName === 'Other' ? 2 : 2.4}
              >
                <InputField
                  id='outlined-required'
                  label='Time Spent(Min)'
                  size='small'
                  fullWidth
                  value={formValues[index]?.timeSpend || ''}
                  onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                  margin='normal'
                  placeholder='Max. 240 minutes'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span style={{fontSize:'14px'}}>
                        {formValues[index]?.timeSpend
                          ? convertMinutesToHours(Number(formValues[index].timeSpend))
                          : ''}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item sm={3} md={3} lg={3}>
                <FormGroup>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                      checked={formValues[index]?.isYesterdayTask}
                      onChange={(e) => handleCheckboxChange(index, e)}
                        color="primary"
                      />
                    }
                    label="Is this yesterday's task?"
                  />
                </FormGroup>
              </Grid> */}
              <Grid item xs={12} sm={12} className='custom_ck'>
                <CKEditor
                  editor={ClassicEditor}
                  data={formValues[index]?.taskDescription || ''}
                  onReady={() => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleTaskDescriptionChange(index, data)
                  }}
                />
                <Box className='delete_wrapper'>
                  {index !== 0 && (
                    <CancelButton
                      fontSize='12px'
                      height='35px'
                      onClick={() => deleteFormFields(index)}
                    >
                      Delete Task
                    </CancelButton>
                  )}

                  {(index === formValues.length - 1 ||
                    (index === 0 && formValues.length === 1)) && (
                    <Button
                      className='delete_button'
                      sx={{
                        borderRadius: '20px',
                        fontSize: '12px',
                        height: '35px',
                        fontFamily: 'Montserrat-SemiBold',
                        width: '100px',
                        '&.Mui-disabled': {
                          opacity: 0.5,
                          color: '#ffffff',
                          cursor: 'not-allowed',
                        },
                      }}
                      onClick={addFormFields}
                    >
                      + Add Task
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      <DialogActions className='button_wrapper'>
        <CancelButton onClick={handleClose}>CANCEL</CancelButton>
        <Button
          sx={{
            borderRadius: '20px',
            fontSize: '13px',
            height: '42px',
            fontFamily: 'Montserrat-SemiBold',
            width: '100px',

            '&.Mui-disabled': {
              opacity: 0.5,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreateIDSR}
          disabled={handleCheckError()}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CreatePlanDialog = ({
  open,
  onClose,
  createPlanForTheDay,
  AllProjectsName,
  getStatusType,
  getManagerDetails,
  mandateData
}: {
  mandateData: any
  open: boolean
  onClose: () => void
  createPlanForTheDay: (data: any) => void
  AllProjectsName: string[]
  getStatusType: [{ id: number; type_name: string }]
  getManagerDetails:{email: string; name: string}
}) => {
  const [to, setTo] = useState(getManagerDetails?.email || '')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState('')
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      otherProjectName: '',
      timeSpend: '',
      taskDescription: '',
      priority:'High',
      mandateId: String(mandateData?.data?.[0]?.id),
    },
  ])

  useEffect(()=>{
    setTo(getManagerDetails?.email || '');
    setFormValues((prevFormValues) => {
      return prevFormValues.map((formValue: any) => ({
        ...formValue,
        mandateId: String(mandateData?.data?.[0]?.id),
      }));
    });
    
  },[getManagerDetails, mandateData])

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        priority:'High',
        mandateId: String(mandateData?.data?.[0]?.id),
      },
    ])
  }

  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
        otherProjectName: value === 'Other' ? newData[index].otherProjectName : '',
      }
      return newData
    })
  }

  const handleProjectTypeChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        mandateId: String(value),
      }
      return newData
    })
  }

  const handleOtherProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        otherProjectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    // Convert the value to a number
    const numericValue = Number(value)

    if (!isNaN(numericValue) && numericValue <= 240) {
      setFormValues((prevData) => {
        const newData = [...prevData]
        newData[index] = {
          ...newData[index],
          timeSpend: value,
        }
        return newData
      })
    }
  }
  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return (
          value.projectName === '' ||
          value.timeSpend === '' ||
          value.taskDescription === '' ||
          (value.projectName === 'Other' && value.otherProjectName === '') ||
          value.mandateId === '' || value.priority === ''
        )
      }) ||
      toError ||
      !to ||
      (cc !== '' && ccError)
    return hasError
  }

  const handleCreatePlanForTheDay = () => {
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      const data = {
        to: to,
        cc: cc,
        planfordayData: formValues,
      }
      createPlanForTheDay(data)
      resetForm()
    }
  }

  const resetForm = () => {
    setTo(getManagerDetails?.email || '')
    setToError(false)
    setCcError(false)
    setCc('')
    setFormValues([
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        priority:'High',
        mandateId: String(mandateData?.data?.[0]?.id) ?? '',
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  const handlePriorityChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        priority: value,
      }
      return newData
    })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1);
    return `${hours} hrs`;
  };

  return (
    <Dialog open={open} maxWidth='lg' className='create_IDSR_wrapper'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }}>
          Create Plan For The Day
        </Typography>
      </BootstrapDialogTitle>
      <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6} sm={6}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField1
              id='outlined-required'
              label='Cc'
              size='small'
              fullWidth
              value={cc}
              onChange={(e) => handleCc(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={ccError}
              helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          {formValues.map((element, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              className='project_wrapper'
              sx={{
                paddingBottom: '20px',
                height: 'auto',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                xs={element.projectName === 'Other' ? 2.4 : 3}
                sm={element.projectName === 'Other' ? 2.4 : 3}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Select Project <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-project-label'
                    variant='outlined'
                    label='Select Project:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.projectName || ''}
                    onChange={(e) => handleProjectNameChange(index, e.target.value)}
                  >
                    {AllProjectsName.map((project: string, index: number) => (
                      <MenuItem key={project} value={project}>
                        {project}
                      </MenuItem>
                    ))}
                    <MenuItem value='Other'>Other</MenuItem>
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              {element.projectName === 'Other' && (
                <Grow in={element.projectName === 'Other'} timeout={500}>
                  <Grid
                    item
                    xs={element.projectName === 'Other' ? 2.4 : 3}
                    sm={element.projectName === 'Other' ? 2.4 : 3}
                  >
                    <InputField
                      id='outlined-required'
                      label='Project Name'
                      size='small'
                      fullWidth
                      autoFocus
                      value={element.otherProjectName || ''}
                      onChange={(e) => handleOtherProjectNameChange(index, e.target.value)}
                      margin='normal'
                    />
                  </Grid>
                </Grow>
              )}
              <Grid
                item
                xs={element.projectName === 'Other' ? 2.4 : 3}
                sm={element.projectName === 'Other' ? 2.4 : 3}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Select Type <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-type-label'
                    variant='outlined'
                    label='Select Type:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.mandateId}
                    onChange={(e) => handleProjectTypeChanges(index, e.target.value as string)}
                  >
                    {mandateData?.data?.map((type: any) => {
                      return (
                        <MenuItem key={`${type.id}${type.id}`} value={String(type.id)}>
                          {type.mandate_name}
                        </MenuItem>
                      )
                    })}
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              <Grid
                item
                xs={element.projectName === 'Other' ? 2.4 : 3}
                sm={element.projectName === 'Other' ? 2.4 : 3}
              >
                <CustomFormControll>
                  <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                    Priority <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelectField
                    labelId='select-type-label'
                    variant='outlined'
                    label='Priority:'
                    type='small'
                    fullWidth
                    value={formValues[index]?.priority}
                    onChange={(e) => handlePriorityChanges(index, e.target.value as string)}
                  >
                    {priority?.map((type:any, index:number) => {
                      return (
                        <MenuItem key={`${type}`} value={type}>
                          {type}
                        </MenuItem>
                      )
                    })}
                  </StyledSelectField>
                </CustomFormControll>
              </Grid>
              <Grid
                item
                xs={element.projectName === 'Other' ? 2.4 : 3}
                sm={element.projectName === 'Other' ? 2.4 : 3}
              >
                <InputField
                  id='outlined-required'
                  label='Estimated Time(Minutes)'
                  size='small'
                  fullWidth
                  value={formValues[index]?.timeSpend || ''}
                  onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                  margin='normal'
                  placeholder='Max. 240 minutes'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span style={{fontSize:'14px'}}>
                        {formValues[index]?.timeSpend
                          ? convertMinutesToHours(Number(formValues[index].timeSpend))
                          : ''}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} className='custom_ck'>
                <CKEditor
                  editor={ClassicEditor}
                  data={formValues[index]?.taskDescription || ''}
                  onReady={() => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleTaskDescriptionChange(index, data)
                  }}
                />
                <Box className='delete_wrapper'>
                  {index !== 0 && (
                    <CancelButton
                      fontSize='12px'
                      height='35px'
                      onClick={() => deleteFormFields(index)}
                    >
                      Delete Task
                    </CancelButton>
                  )}

                  {(index === formValues.length - 1 ||
                    (index === 0 && formValues.length === 1)) && (
                    <Button
                      className='delete_button'
                      sx={{
                        borderRadius: '20px',
                        fontSize: '12px',
                        height: '35px',
                        fontFamily: 'Montserrat-SemiBold',
                        width: '100px',
                        '&.Mui-disabled': {
                          opacity: 0.5,
                          color: '#ffffff',
                          cursor: 'not-allowed',
                        },
                      }}
                      onClick={addFormFields}
                    >
                      + Add Task
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      <DialogActions className='button_wrapper'>
        <CancelButton onClick={handleClose}>CANCEL</CancelButton>
        <Button
          sx={{
            borderRadius: '20px',
            fontSize: '13px',
            height: '42px',
            fontFamily: 'Montserrat-SemiBold',
            width: '100px',

            '&.Mui-disabled': {
              opacity: 0.5,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreatePlanForTheDay}
          disabled={handleCheckError()}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const IDSR = (props: any) => {
  const {
    createPlanForTheDay,
    createIDSR,
    isCreatingIDSR,
    isCreatingPlanForTheDay,
    isIDSRCreated,
    getAllIDSRsList,
    getAllPlansList,
    resetIDSR,
    fetchAttendanceTimesheet,
    fetchPlansForTheDay,
    getUserById,
    AttendanceTimesheet,
    fetchAttendanceData,
    isFetchingIDSRs,
    isFetchingPlans,
    AllProjectsName,
    fetchProjectsName,
    fetchStatusType,
    fetchTaskStatus,
    getStatusType,
    getTaskStatus,
    isPlanForTheDayCreated,
    resetPlanForTheDay,
    getUserDetails,
    mandateTypeData,
    getMandateType
  } = props  


  const [selectedIDSR, setSelectedIDSR] = useState<any | null>(null)
  const [selectedPlanForTheDay, setSelectedPlanForTheDay] = useState<any | null>(null)
  const [isNewIDSRDialogOpen, setisNewIDSRDialogOpen] = useState(false)
  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)
  const [selectedTimeSheetMonth, setSelectedTimeSheetMonth] = useState('')
  const [selectedPlanForTheDayMonth, setSelectedPlanForTheDayMonth] = useState('')
  const [checkOpen, setCheckOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false)
  const [hoverTooltipOpen, setHoverTooltipOpen] = useState(false);

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleRowClick = (data: any) => {
    setSelectedIDSR(data)
  }

  const handlePlanRowClick = (data: any) => {
    setSelectedPlanForTheDay(data)
  }

  const handleCloseModal = () => {
    setSelectedIDSR(null)
  }

  const handleClosePlanModal = () => {
    setSelectedPlanForTheDay(null)
  }

  const handleOpenNewIDSRDialog = () => {
    setisNewIDSRDialogOpen(true)
  }

  const handleNewPlanDialog = () => {
    setNewPlanDialog(true)
  }

  const handleCloseNewIDSRialog = () => {
    setisNewIDSRDialogOpen(false)
  }

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }

  
  useEffect(()=>{
    getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
  },[])

  useEffect(() => {
    if (getUserById?.id) {
      fetchAttendanceTimesheet(getUserById.id)
      fetchProjectsName(getUserById?.id)
      fetchStatusType()
      fetchTaskStatus();
    }
  }, [getUserById])

  useEffect(() => {
    if (AttendanceTimesheet?.length && getUserById?.id) {
      switch (selectedTab) {
        case 0:
          setSelectedPlanForTheDayMonth(
            selectedPlanForTheDayMonth !== ''
              ? selectedPlanForTheDayMonth
              : AttendanceTimesheet[0].id,
          )
          setSelectedTimeSheetMonth('')
          fetchPlansForTheDay({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
          break
        case 1:
          setSelectedTimeSheetMonth(
            selectedTimeSheetMonth !== '' ? selectedTimeSheetMonth : AttendanceTimesheet[0].id,
          )
          setSelectedPlanForTheDayMonth('')
          fetchAttendanceData({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
          break
      }
    }
  }, [AttendanceTimesheet, selectedTab])

  useEffect(() => {
    if (isIDSRCreated) {
      toast.success('Timesheet sent successfully')
      fetchAttendanceTimesheet(getUserById.id)
      resetIDSR()
    }
  }, [isIDSRCreated])

  useEffect(() => {
    if (isPlanForTheDayCreated) {
      toast.success('Plan for the day sent successfully')
      fetchAttendanceTimesheet(getUserById.id)
      resetPlanForTheDay()
    }
  }, [isPlanForTheDayCreated])

  const handleSelectTimeSheetMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedTimeSheetMonth(selectedMonthId)
      fetchAttendanceData({
        userId: getUserById?.id,
        tId: selectedMonthId,
      })
    },
    [getUserById?.id, fetchAttendanceData],
  )

  const handleSelectPlanForTheDayMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedPlanForTheDayMonth(selectedMonthId)
      fetchPlansForTheDay({ userId: getUserById?.id, tId: selectedMonthId })
    },
    [getUserById?.id, fetchPlansForTheDay],
  )

  const fetchAttendanceData1 = (data: any) => {
    fetchAttendanceData({ userId: getUserById.id, tId: data.tId })
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  const handleCopyContent = () => {
    const stripHTML = (html: any) => {
        
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*');
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '');

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n');
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n');
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }

    const subject = selectedPlanForTheDay?.subject || ''
    const body = stripHTML(selectedPlanForTheDay?.body || '')
    const content = `${subject}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      setCopyTooltipOpen(true)
      setTimeout(() => {
        setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  const handleCopyTimesheetContent = () => {
    const stripHTML = (html: any) => {
        
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*');
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '');

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n');
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n');
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }
    const subject = selectedIDSR?.subject || ''
    const body = stripHTML(selectedIDSR?.body)
    const projectDetails = `
      Project Name: ${
        selectedIDSR?.project_name === 'Other'
          ? selectedIDSR?.other_project_name
          : selectedIDSR?.project_name
      }
      Time Spent: ${selectedIDSR?.time_spent} mins
      No. of PR Raised: ${selectedIDSR?.no_of_pr_raised ?? 0}
      No. of PR Approved: ${selectedIDSR?.no_of_pr_approved ?? 0}
      No. of Reworked PR: ${selectedIDSR?.no_of_reworked_pr ?? 0}
    `
    const content = `${subject}\n${projectDetails}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      setCopyTooltipOpen(true)
      setTimeout(() => {
        setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  return (
    <StyledPaper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '50px',
          justifyContent: 'space-between',
          marginTop: '-25px',
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label='Tabs for different tables'>
          <Tab label='Plan For The Day' onClick={() => setSelectedTab(0)} />
          <Tab label='Timesheet' onClick={() => setSelectedTab(1)} />
        </Tabs>
        <Box sx={{ width: 'auto',  padding: '5px'}}>
          <i>
            Priority Levels : High (Today), Medium (Tomorrow), Low (Day After)
          </i>
        </Box>
      </Box>
      {selectedTab === 0 && (
        <StyledPaper sx={{ padding: '1rem' }}>
          <Dialog open={isCreatingPlanForTheDay} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <FormControl>
                <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                <SelectField
                  sx={{ borderRadius: '100px', width: '150px' }}
                  variant='outlined'
                  type='small'
                  label='Select Month:'
                  value={selectedPlanForTheDayMonth}
                  onChange={handleSelectPlanForTheDayMonth}
                  open={checkOpen}
                  onOpen={() => {
                    window.addEventListener('scroll', () => {
                      setCheckOpen(false)
                      return window.removeEventListener('scroll', () => {})
                    })
                  }}
                  onClick={() => setCheckOpen((prev) => !prev)}
                >
                  {AttendanceTimesheet?.map((option: any) => (
                    <StyledMenuItem key={option?.id} value={option?.id}>
                      {moment(option?.start_date).format('MMMM YYYY')}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </FormControl>
            </Box>
            <ActionButton
              variant='outlined'
              startIcon={<AddTwoToneIcon />}
              onClick={handleNewPlanDialog}
            >
              NEW PLAN FOR THE DAY
            </ActionButton>
          </Box>

          <CreatePlanDialog
            open={isNewPlanDialogOpen}
            createPlanForTheDay={createPlanForTheDay}
            AllProjectsName={AllProjectsName}
            onClose={handleCloseNewPlanDialog}
            getStatusType={getStatusType}
            getManagerDetails={getUserDetails?.manager}
            mandateData = {mandateTypeData}
          />

          <PlanForTheDayTable
            data={getAllPlansList}
            onRowClick={handlePlanRowClick}
            fetchAttendanceData1={fetchAttendanceData1}
            isFetchingPlans={isFetchingPlans}
            getStatusType={getStatusType}
            mandateData = {mandateTypeData}
          />
          <Modal open={!!selectedPlanForTheDay} onClose={handleClosePlanModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleClosePlanModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Tooltip title={copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'} open={hoverTooltipOpen || copyTooltipOpen}>
              <IconButton
                  aria-label='copy'
                  onClick={handleCopyContent}
                  onMouseEnter={() => setHoverTooltipOpen(true)} 
                  onMouseLeave={() => setHoverTooltipOpen(false)} 
                  sx={{
                    position: 'absolute',
                    right: 50,
                    top: 22,
                    zIndex: 999,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedPlanForTheDay?.subject || '',
                }}
              />
              <Typography variant='body1' style={{ fontSize: '12px', wordBreak: 'break-word' }}>
                <div>{convertTextToHTML(selectedPlanForTheDay?.body || '')}</div>
              </Typography>

              {selectedPlanForTheDay?.approve_status === 0 ? selectedPlanForTheDay?.comment && <Box width={'98%'} padding={'15px 0 0 0'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'}>
                <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment : </Typography>
                <Typography width={'100%'} border={'1px solid #D3D3D3'} padding={'0 10px'} sx={{
                  wordWrap: 'break-word',
                  fontSize: '12px',
                  borderBottomColor: 'coral',
                }}>{convertTextToHTML(selectedPlanForTheDay?.comment || '')}</Typography>
              </Box> :
                <Box width={'98%'} padding={'15px 0 0 0'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'}>
                  {/* <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment : </Typography> */}
                  <Typography width={'100%'} border={'1px solid #D3D3D3'} padding={'10px'} sx={{
                    wordWrap: 'break-word',
                    fontSize: '15px',
                    borderBottomColor: '#53AC6D',
                  }}>Your Plan for the day is Approved! </Typography>
                </Box>
              }
            </Box>
          </Modal>
        </StyledPaper>
      )}
      {selectedTab === 1 && (
        <StyledPaper sx={{ padding: '1rem' }}>
          <Dialog open={isCreatingIDSR} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <FormControl>
                <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                <SelectField
                  sx={{ borderRadius: '100px', width: '150px' }}
                  variant='outlined'
                  type='small'
                  label='Select Month:'
                  value={selectedTimeSheetMonth}
                  onChange={handleSelectTimeSheetMonth}
                  open={checkOpen}
                  onOpen={() => {
                    window.addEventListener('scroll', () => {
                      setCheckOpen(false)
                      return window.removeEventListener('scroll', () => {})
                    })
                  }}
                  onClick={() => setCheckOpen((prev) => !prev)}
                >
                  {AttendanceTimesheet?.map((option: any) => (
                    <StyledMenuItem key={option?.id} value={option?.id}>
                      {moment(option?.start_date).format('MMMM YYYY')}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </FormControl>
            </Box>
            <ActionButton
              variant='outlined'
              startIcon={<AddTwoToneIcon />}
              onClick={handleOpenNewIDSRDialog}
            >
              NEW TIMESHEET
            </ActionButton>
          </Box>

          <CreateIDSRDialog
            open={isNewIDSRDialogOpen}
            createIDSR={createIDSR}
            AllProjectsName={AllProjectsName}
            onClose={handleCloseNewIDSRialog}
            getStatusType={getStatusType}
            getTaskStatus={getTaskStatus}
            getManagerDetails={getUserDetails?.manager}
            mandateData = {mandateTypeData}
          />

          <IDSRTable
            data={getAllIDSRsList}
            onRowClick={handleRowClick}
            fetchAttendanceData1={fetchAttendanceData1}
            isFetchingIDSRs={isFetchingIDSRs}
            getStatusType={getStatusType}
            getTaskStatus={getTaskStatus}
            mandateData = {mandateTypeData}
            getMandateType = {getMandateType}
          />
          <Modal open={!!selectedIDSR} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Tooltip title={copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'} open={hoverTooltipOpen || copyTooltipOpen}>
                <IconButton
                  aria-label='copy'
                  onClick={handleCopyTimesheetContent}
                  onMouseEnter={() => setHoverTooltipOpen(true)} 
                  onMouseLeave={() => setHoverTooltipOpen(false)} 
                  sx={{
                    position: 'absolute',
                    right: 50,
                    top: 22,
                    zIndex: 999,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedIDSR?.subject || '',
                }}
              />
              <Box display='flex' justifyContent='center' alignItems='center' marginBottom='0.5rem'>
                {/* <Typography variant='body1' fontSize='13px'>
                  Project Name:{' '}
                  {selectedIDSR?.project_name === 'Other'
                    ? selectedIDSR?.other_project_name
                    : selectedIDSR?.project_name}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} /> */}
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  Total time spent: {selectedIDSR?.time_spent} mins
                </Typography>
                {/* <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of PR Raised: {selectedIDSR?.no_of_pr_raised ?? 0}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of PR Approved: {selectedIDSR?.no_of_pr_approved ?? 0}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of Reworked PR: {selectedIDSR?.no_of_reworked_pr ?? 0}
                </Typography> */}
              </Box>
              <Divider />
              <Typography variant='body1' style={{ fontSize: '12px', wordBreak: 'break-word' }}>
                <div>{convertTextToHTML(selectedIDSR?.body || '')}</div>
              </Typography>
              {
                selectedIDSR?.comment && <Box width={'98%'} paddingTop={'15px'} >
                  <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment : </Typography>
                  <Typography border={'1px solid #D3D3D3'} padding={'0 10px'} width={'100%'} sx={{
                    wordWrap: 'break-word',
                    fontSize: '12px',
                    borderBottomColor: 'coral',
                  }}>{convertTextToHTML(selectedIDSR?.comment || '')}</Typography>
                </Box>
              }
            </Box>
          </Modal>
        </StyledPaper>
      )}
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingIDSR: attendanceUI.getEmpAttendanceList(state).isCreatingIDSR,
    isFetchingIDSRs: attendanceUI.getEmpAttendanceList(state).isFetchingIDSRs,
    isIDSRCreated: attendanceUI.getEmpAttendanceList(state).isIDSRCreated,
    getAllIDSRsList: fetchIDSRs.getAllIDSRList(state).fetchIDSRs,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    AllProjectsName: fetchRCAs.getAllIDSRList(state).fetchProjects,
    getAllPlansList: fetchPlans.getAllPlansList(state).fetchPlansForTheDay,
    getStatusType: fetchStatusType.getStatusTypeList(state).fetchStatusType,
    getTaskStatus: fetchStatusType.getStatusTypeList(state).fetchTaskStatus,
    isCreatingPlanForTheDay: attendanceUI.getEmpAttendanceList(state).isCreatingPlanForTheDay,
    isPlanForTheDayCreated: attendanceUI.getEmpAttendanceList(state).isPlanForTheDayCreated,
    isFetchingPlans: attendanceUI.getEmpAttendanceList(state).isFetchingPlanForTheDay,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createIDSR: (data: any) => dispatch(createNewIDSR.request({ data })),
    resetIDSR: () => dispatch(createNewIDSR.reset()),
    getAllIDSRs: (userId: any) => dispatch(getIDSRs.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getIDSRs.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchProjectsName: (userId: any) => dispatch(fetchProjectsName.request({ userId })),
    fetchPlansForTheDay: (data: any) => dispatch(getPlans.request(data)),
    fetchStatusType: () => dispatch(getStatusType.request()),
    fetchTaskStatus: () => dispatch(getTaskStatus.request()),
    createPlanForTheDay: (data: any) => dispatch(createNewPlanForTheDay.request({ data })),
    resetPlanForTheDay: () => dispatch(createNewPlanForTheDay.reset()),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IDSR)
