import React, { useEffect } from 'react'
import { useState } from 'react'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  loaderProps,
} from '../Common/CommonStyles'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './UserDetailsStyle'
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp'
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone'
import { validationSchema } from './ValidationSchema'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import { employeePortalEntity, fetchUserDetailsEntities, fetchUserDetailsUI, projectManagementEntity } from '../../reducers'
import {
  createUser,
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  updateUser,
  fetchAllClientLocation,
  getUserImage,
  fetchDesignation,
  fetchDesignationBand,
  getAllEmployees,
  fetchWorkingEmp,

} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { HeaderHeading } from '../Common/CommonStyles'
import { employeeDataRequest } from '../../reducers/entities'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '21px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}

const steps = ['Personal Information', 'Employment Details', 'Address Information']

const daysOfWeek = [
  { id: 1, value: 'Sunday' },
  { id: 2, value: 'Monday' },
  { id: 3, value: 'Tuesday' },
  { id: 4, value: 'Wednesday' },
  { id: 5, value: 'Thursday' },
  { id: 6, value: 'Friday' },
  { id: 7, value: 'Saturday' },
]

const StepperForm = (props: any) => {
  const { isUserCreating, isUserUpdating } = props

  const rowdata: any = useLocation()?.state
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [age, setAge] = useState(rowdata?.age ?? 0)

  const navigate = useNavigate()
  const location = useLocation()
  // const userData = location?.state?.data?.rowData || {}

  useEffect(() => {
    props.fetchAllRole()
    props.fetchAllState()
    props.fetchAllCountry()
    props.fetchAllFloor()
    props.fetchAllWorkstation()
    props.fetchAllLocation()
    props.fetchAllClientLocation()

    if (rowdata?.userid) {
      props.getUserImage(rowdata)
    }

    return () => {
      props.resetCreateUser()
      props.resetUpdateUser()
      props.resetUserImage()
    }
  }, [rowdata?.userid])

  useEffect(() => {
    props.fetchDesignationData();
    props.fetchAllEmpAPI();
    props.fetchDesignationBandData();
  }, []);



  useEffect(() => {
    if (props.isUserCreated) {
      toast.success('User added successfully')
      navigate('/home/employees')
    }

    // if (props.isUserCreateFailed) {
    //   toast.error('Failed to create user')
    // }

    if (props.isUserUpdated) {
      toast.success('User updated successfully')
      navigate('/home/employees')
    }

    // if (props.isUserUpdateFailed) {
    //   toast.error('Failed to update user')
    // }
  }, [props.isUserCreated, props.isUserUpdated, /* props.isUserCreateFailed,  props.isUserUpdateFailed */])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        toast.error('Video files are not allowed')
        formik.setFieldValue('image_path', null)
      } else {
        const reader = new FileReader()

        reader.onloadend = () => {
          const base64String = reader.result as string
          setImagePreview(base64String)
          formik.setFieldValue('image_path', file)
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const uniqueRole =
    props.allRoles.length > 0
      ? props.allRoles.filter(
        (item: { name: any }, index: any, self: any[]) =>
          index === self.findIndex((t) => t.name === item.name),
      )
      : []

  const roleIdArr: any = []
  const dependentsArr: any = []

  rowdata?.roles?.map((value: any) => roleIdArr.push(value.id))

  rowdata?.dependents &&
    rowdata?.dependents?.length > 0 &&
    rowdata?.dependents?.map((value: any) =>
      dependentsArr.push({
        id: value.id,
        name: value.name,
        id_relationship: value.id_relationship,
        age: value.age,
      }),
    )

  const formattedOfficialDate = rowdata?.official_birth_date
    ? dayjs(rowdata.official_birth_date).format('YYYY-MM-DD')
    : ''
  const formattedActualDate = rowdata?.birth_date
    ? dayjs(rowdata.birth_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationStartDate = rowdata?.location_start_date
    ? dayjs(rowdata.location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedLocationEndDate = rowdata?.location_end_date
    ? dayjs(rowdata.location_end_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationStartDate = rowdata?.comp_location_start_date
    ? dayjs(rowdata.comp_location_start_date).format('YYYY-MM-DD')
    : ''
  const formattedCompLocationEndDate = rowdata?.comp_location_end_date
    ? dayjs(rowdata.comp_location_end_date).format('YYYY-MM-DD')
    : ''

  const formik = useFormik({
    initialValues: {
      first_name: rowdata?.first_name ?? '',
      last_name: rowdata?.last_name ?? '',
      // middle_name: rowdata?.middle_name ?? '',
      id_tenant: 1,
      father_name: rowdata?.father_name ?? '',
      image_path: '',
      mother_name: rowdata?.mother_name ?? '',
      employee_id: rowdata?.employeeId ?? '',
      is_at_client_location: rowdata?.is_at_client_location ? Number(rowdata?.is_at_client_location) : 0,
      client_location: rowdata?.client_location ?? null,
      location_start_date: formattedLocationStartDate ? formattedLocationStartDate : '',
      location_end_date: formattedLocationEndDate ? formattedLocationEndDate : '',
      comp_location_start_date: formattedCompLocationStartDate
        ? formattedCompLocationStartDate
        : '',
      comp_location_end_date: formattedCompLocationEndDate ? formattedCompLocationEndDate : '',
      day_to_visit: rowdata?.day_to_visit ?? '',
      time_to_visit: rowdata?.time_to_visit ?? '',
      project_assigned: rowdata?.project_assigned ?? '',
      location: rowdata?.location ?? null,
      key_assigned: rowdata?.key_assigned === 1 ? 1 : 0,
      key_number: '',
      under_probation: rowdata?.under_probation === 0 ? 0 : 1,
      email: rowdata?.email ?? '',
      track_attendance: true,
      Workstation: rowdata?.workstation_id ?? null,
      loginId: rowdata?.username ?? '',
      password: rowdata?.password ?? '',
      confirm_password: rowdata?.password ?? '',
      designation: rowdata?.DesginationName?.id ?? '',
      desig_band: rowdata?.employeeinfo?.[0]?.designation_band ?? '',
      manager: rowdata?.managerName?.id ?? '',
      status: rowdata?.status ? Number(rowdata?.status) : 1,
      present_address: rowdata && 'address' in rowdata ? rowdata?.address[0]?.present_address : '',
      present_city:  rowdata && 'address' in rowdata ? rowdata?.address[0]?.present_city : '',
      present_postal_code:  rowdata && 'address' in rowdata ? rowdata?.address[0]?.present_postal_code : '',
      present_state:  rowdata && 'address' in rowdata ? String(rowdata?.address[0]?.present_state) : String(15),
      telephone_no: rowdata?.telephone_no ?? null,
      // per_telephone_no: null,
      deleted_by: 'Murthy',
      is_deleted: '0',
      id_country: 5,
      floor_id: rowdata?.floor_id ?? null,
      citizenship: rowdata?.citizenship ?? '',
      permanent_address: rowdata && 'address' in rowdata ? rowdata?.address[0]?.permanent_address : '',
      permanent_city: rowdata && 'address' in rowdata ? rowdata?.address[0]?.permanent_city : '',
      permanent_postal_code: rowdata && 'address' in rowdata ? rowdata?.address[0]?.permanent_postal_code : '',
      permanent_state:
      rowdata && 'address' in rowdata ? String(rowdata?.address[0]?.permanent_state) : String(15),
      mobile_no: rowdata?.mobile_no ?? '',
      id_country_permanent: rowdata && 'address' in rowdata ? rowdata?.address[0]?.id_country : 5,
      official_birth_date: formattedOfficialDate ? formattedOfficialDate : '', // '1992-10-28 14:22:50Z',
      birth_date: formattedActualDate ? formattedActualDate : '', // '1992-10-28 14:22:50Z',
      gender: rowdata?.gender ?? 1,
      blood_group: rowdata?.blood_group ?? '',
      id_roles: [...roleIdArr] ?? [],
      spouse_name: rowdata?.spouse_name ?? '',
      contact_person: rowdata?.contact_person ?? '',
      marital_status: rowdata?.matital_status ?? null,
      spouse_mobile: rowdata?.spouse_mobile ?? '',
      contact_person_phone: rowdata?.contact_person_phone ?? '',
      probation_period: rowdata?.probation_period ?? 12,
      isEmployeeSelected: false,
      dependents:
        dependentsArr.length > 0 ? dependentsArr : [{ name: '', id_relationship: '', age: null }],
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form values:', values)
    },
  })

  const stepSchemas = [
    Yup.object({
      first_name: Yup.string().required('Required'),
      // middle_name: Yup.string(),
      last_name: Yup.string().required('Required'),
      father_name: Yup.string().required('Required'),
      mother_name: Yup.string().required('Required'),
      mobile_no: Yup.string(),
      official_birth_date: Yup.date().required('Required'),
      birth_date: Yup.date().required('Required'),
      age: Yup.number(), // age is not required but enter then accept number
      gender: Yup.string(),
      marital_status: Yup.number(),
      under_probation: Yup.number(),
      probation_period: Yup.number(),
      // id_roles: Yup.array()
      //   .of(Yup.string().required())
      //   .min(1, 'At least one role must be selected')
      //   .required('Roles are required'),
      employee_id: Yup.string().required('Required'),
      id_roles: Yup.array()
        .of(Yup.number().required())
        .min(1, 'At least one role must be selected')
        .required('Roles are required')
        .test(
          'employee-role',
          'Employee ID is required when the Employee role is selected',
          function (value) {
            const { isEmployeeSelected, employee_id } = this.parent // Access other fields in the form
            if (value?.includes(281) && !employee_id) {
              return this.createError({
                path: 'employee_id',
                message: 'Employee ID is required when the Employee role is selected',
              })
            }
            return true
          },
        ),
    }),

    Yup.object({
      designation: Yup.string().required('Required'),
      desig_band: Yup.string().required('Required'),
      manager: Yup.string().required('Required'),
      is_at_client_location: Yup.number().required('Required'),
      location: Yup.number().when('is_at_client_location', (value: any) => {
        if (value[0]) return Yup.number().notRequired()
        return Yup.number().required()
      }),
      client_location: Yup.number().when('is_at_client_location', (value: any) => {
        if (value[0]) return Yup.number().required()
        return Yup.number().notRequired()
      }),
      // floor_id: Yup.number().when('is_at_client_location', (value: any) => {
      //   if (value[0]) return Yup.number().notRequired() //.required()
      //   return Yup.number().required()
      // }),
      // Workstation: Yup.string().when('is_at_client_location', (value: any) => {
      //   if (value[0]) return Yup.string().notRequired() //.required()
      //   return Yup.string().required()
      // }),

      status: Yup.number().required('Required'),
      // key_assigned: Yup.number().notRequired(),
      loginId: Yup.string().required('Required'),
      password: Yup.string(),
      // .required('Password is required')
      // .min(6, 'Password must be at least 6 characters'),
      confirm_password: Yup.string()
        // .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    }),
    Yup.object({
      present_address: Yup.string().required('Required'),
      present_city: Yup.string().required('Required'),
      present_postal_code: Yup.string().required('Required'),
      present_state: Yup.string().required('Required'),
      id_country: Yup.number().required('Required'),
      citizenship: Yup.string(),
      // telephone_no: Yup.number().notRequired(),

      permanent_address: Yup.string().required('Required'),
      permanent_city: Yup.string().required('Required'),
      permanent_postal_code: Yup.string().required('Required'),
      permanent_state: Yup.string().required('Required'),
      // per_telephone_no: Yup.number().notRequired(),
      id_country_permanent: Yup.number().required('Required'),
      email: Yup.string().email('Invalid email format').required('Required'),
    }),
    // Yup.object({
    //   blood_group: Yup.string(),
    //   spouse_name: Yup.string(),
    //   contact_person: Yup.string(),
    //   spouse_mobile: Yup.string(),
    //   contact_person_phone: Yup.string(),
    // }),
  ]

  const handleNext = async () => {
    const stepSchema = stepSchemas[activeStep]

    try {
      await stepSchema.validate(formik.values, { abortEarly: false })
      if (activeStep === stepSchemas.length - 1) {
        const entity = {
          id: Boolean(rowdata?.username) && rowdata?.userid,
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          id_tenant: formik.values.id_tenant,
          father_name: formik.values.father_name,
          image: '',
          mother_name: formik.values.mother_name,
          employee_id: String(formik.values.employee_id),
          is_at_client_location: Number(formik.values.is_at_client_location),
          location: formik.values.location,
          under_probation: Number(formik.values.under_probation),
          email: formik.values.email,
          track_attendance: true,
          Workstation: formik.values.Workstation,
          username: formik.values.loginId,
          password: formik.values.password,
          status: Number(formik.values.status),
          present_address: formik.values.present_address,
          present_city: formik.values.present_city,
          present_postal_code: formik.values.present_postal_code,
          present_state: formik.values.present_state,
          telephone_no: formik.values.telephone_no,
          // per_telephone_no: formik.values.per_telephone_no,
          deleted_by: formik.values.deleted_by,
          dependents: formik.values.dependents,
          is_deleted: '0',
          id_country: formik.values.id_country,
          floor_id: formik.values.floor_id,
          citizenship: formik.values.citizenship,
          permanent_address: formik.values.permanent_address,
          permanent_city: formik.values.permanent_city,
          permanent_postal_code: formik.values.permanent_postal_code,
          permanent_state: formik.values.permanent_state,
          mobile_no: formik.values.mobile_no,
          id_country_permanent: formik.values.id_country_permanent,
          official_birth_date: formik.values.official_birth_date,
          birth_date: formik.values.birth_date,
          gender: String(formik.values.gender),
          age: age,
          blood_group: formik.values.blood_group,
          id_roles: formik.values.id_roles.map((roleId: number) => roleId),
          spouse_name: formik.values.spouse_name,
          marital_status: formik.values.marital_status,
          spouse_mobile: formik.values.spouse_mobile,
          contact_person: formik.values.contact_person,
          contact_person_phone: formik.values.contact_person_phone,
          probation_period: Number(formik.values.probation_period),
          day_to_visit: String(formik.values.day_to_visit),
          key_assigned: Number(formik.values.key_assigned),
          location_start_date: formik.values.location_start_date,
          location_end_date: formik.values.location_end_date,
          comp_location_start_date: formik.values.comp_location_start_date,
          comp_location_end_date: formik.values.comp_location_end_date,
          time_to_visit: formik.values.time_to_visit,
          project_assigned: formik.values.project_assigned,
          client_location: formik.values.client_location,

          id_manager: formik.values.manager, //manger 
          id_grade: formik.values?.designation, //designation
          designation_band: formik.values.desig_band, //designation
          secondary_employment: 1,
          company_identification_no: "1",
          id_payroll_frequency: 1,
          job_code: 0,
          employment_type: 1,
          non_resident: 0,
        }

        const isUpdate = Boolean(rowdata?.username)
        // userid
        const apiCall = isUpdate ? props.updateUser : props.createUser

        if (formik.values.image_path) {
          entity.image = formik.values.image_path
          await apiCall(entity)
        } else {
          apiCall(entity)
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  let existEmpId = formik.values.id_roles.filter((emp) => emp === 281)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    const currentRoles = formik.values.id_roles

    if (checked) {
      formik.setFieldValue('id_roles', [...currentRoles, parseInt(value)])
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', true)
      }
    } else {
      formik.setFieldValue(
        'id_roles',
        currentRoles?.filter((roleId: number) => roleId !== parseInt(value)),
      )
      if (parseInt(value) === 281) {
        formik.setFieldValue('isEmployeeSelected', false)
      }
    }

    setTimeout(() => {
      formik.validateField('id_roles')
    }, 0)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='first_name'
                    name='first_name'
                    label='First Name'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    inputProps={{ tabIndex: 1 }}
                  />
                </Grid>

                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='father_name'
                    name='father_name'
                    label={`Father's Name`}
                    value={formik.values.father_name}
                    onChange={formik.handleChange}
                    error={formik.touched.father_name && Boolean(formik.errors.father_name)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>

                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='official_birth_date'
                    name='official_birth_date'
                    label='Official Birth Date'
                    type='date'
                    value={formik.values.official_birth_date || ''}
                    onChange={(e: any) => {
                      const age = dayjs().diff(dayjs(e?.target?.value, 'YYYY-MM-DD'), 'year')
                      if (age > 0) setAge(age)
                      return formik.handleChange(e)
                    }}
                    error={
                      formik.touched.official_birth_date &&
                      Boolean(formik.errors.official_birth_date)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 5 }}
                  />
                </Grid>

                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='age'
                    name='age'
                    label='Age'
                    value={age ?? ''}
                    disabled
                  />
                </Grid>
                {/* <Grid item sx={style.gridStyle}>
                <InputField
                      size='small'
                      fullWidth
                      id='employee_id'
                      name='employee_id'
                      label='Employee ID'
                      value={formik.values.employee_id}
                      // onChange={formik.handleChange}
                      disabled={Boolean(rowdata?.username)}
                      onChange={(e) => {
                        let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                        if (onlyNums.length > 10) {
                          onlyNums = onlyNums.slice(0, 10) // Limit to 10 digits
                        }
                        formik.setFieldValue('employee_id', onlyNums)
                      }}
                      error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                      tabIndex={formik.values.dependents.length * 3 + 20 + uniqueRole.length}
                    />
                </Grid> */}

              </Grid>
              <Grid item xs={4}>
                {/* <Grid item sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='middle_name'
                  name='middle_name'
                  label='Middle Name'
                  value={formik.values.middle_name}
                  onChange={formik.handleChange}
                  required={false}
                />
              </Grid> */}
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    inputProps={{ tabIndex: 2 }}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='mother_name'
                    name='mother_name'
                    label={`Mother's Name`}
                    value={formik.values.mother_name}
                    onChange={formik.handleChange}
                    error={formik.touched.mother_name && Boolean(formik.errors.mother_name)}
                    inputProps={{ tabIndex: 4 }}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='birth_date'
                    name='birth_date'
                    label='Actual Birth Date'
                    type='date'
                    value={formik.values.birth_date || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ tabIndex: 6 }}
                  />
                </Grid>

                <Grid item sx={style.gridStyle}>
                  <SelectField
                    size='small'
                    id='gender'
                    name='gender'
                    label='Gender'
                    select
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    inputProps={{ tabIndex: 7 }}
                  >
                    <StyledMenuItem value={1}>Male</StyledMenuItem>
                    <StyledMenuItem value={2}>Female</StyledMenuItem>
                  </SelectField>
                </Grid>

              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item style={{ textAlign: 'center', marginBottom: '15px' }}>
                  <Avatar
                    src={imagePreview ? (imagePreview as string) : props.userImage}
                    alt='User Preview'
                    sx={{
                      width: 150,
                      height: 150,
                      margin: '0 auto',
                      border: '2px solid #ddd',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                  />
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Button
                    variant='contained'
                    component='label'
                    sx={{
                      fontSize: '16px !important',
                      fontFamily: 'Montserrat-SemiBold !important',
                      width: '100%',
                      borderRadius: '20px !important',
                    }}
                    tabIndex={8}
                  >
                    UPLOAD PHOTO
                    <input type='file' hidden accept='image/*' onChange={handleImageChange} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='employee_id'
                  name='employee_id'
                  label='Employee ID'
                  value={formik.values.employee_id}
                  disabled={Boolean(rowdata?.username)}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                    if (onlyNums.length > 10) {
                      onlyNums = onlyNums.slice(0, 10) // Limit to 10 digits
                    }
                    formik.setFieldValue('employee_id', onlyNums)
                  }}
                  error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                  tabIndex={formik.values.dependents.length * 3 + 20 + uniqueRole.length}
                />
              </Grid>

              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='mobile_no'
                  name='mobile_no'
                  label='Mobile Number'
                  value={formik.values.mobile_no}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                    if (onlyNums.length > 10) {
                      onlyNums = onlyNums.slice(0, 10) // Limit to 10 digits
                    }
                    formik.setFieldValue('mobile_no', onlyNums)
                  }}
                  error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                  required={false}
                  inputProps={{ tabIndex: 14 }}
                />
              </Grid>

              <Grid item xs={4} sx={style.gridStyle}>
                <SelectField
                  select
                  size='small'
                  fullWidth
                  id='blood_group'
                  name='blood_group'
                  label='Blood Group'
                  value={formik.values.blood_group}
                  onChange={formik.handleChange}
                  error={formik.touched.blood_group && Boolean(formik.errors.blood_group)}
                  required={false}
                  inputProps={{ tabIndex: 15 }}
                // helperText={formik.touched.present_state && formik.errors.present_state}
                >
                  <StyledMenuItem value='' disabled>
                    Select Blood Group
                  </StyledMenuItem>
                  <StyledMenuItem value='A+'>A Positive</StyledMenuItem>
                  <StyledMenuItem value='A-'>A Negative</StyledMenuItem>
                  <StyledMenuItem value='B+'>B Positive</StyledMenuItem>
                  <StyledMenuItem value='B-'>B Negative</StyledMenuItem>
                  <StyledMenuItem value='O+'>O Positive</StyledMenuItem>
                  <StyledMenuItem value='O-'>O Negative</StyledMenuItem>
                  <StyledMenuItem value='AB+'>AB Positive</StyledMenuItem>
                  <StyledMenuItem value='AB-'>AB Negative</StyledMenuItem>
                </SelectField>
              </Grid>

            </Grid>


            <Divider sx={{ mb: 3, mt: 1 }} />

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={4} sx={style.gridStyle}>
                <SelectField
                  size='small'
                  id='marital_status'
                  name='marital_status'
                  label='Marital Status'
                  select
                  value={formik.values.marital_status}
                  onChange={formik.handleChange}
                  error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                  inputProps={{ tabIndex: 9 }}
                >
                  <StyledMenuItem value={1}>Single</StyledMenuItem>
                  <StyledMenuItem value={2}>Married</StyledMenuItem>
                  <StyledMenuItem value={3}>Widow</StyledMenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='spouse_name'
                  name='spouse_name'
                  label='Spouse Name'
                  value={formik.values.spouse_name}
                  onChange={formik.handleChange}
                  error={formik.touched.spouse_name && Boolean(formik.errors.spouse_name)}
                  required={false}
                  disabled={formik.values.marital_status !== 2}
                  inputProps={{ tabIndex: 10 }}
                />
              </Grid>
              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='spouse_mobile'
                  name='spouse_mobile'
                  label='Spouse Mobile'
                  value={formik.values.spouse_mobile}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                    if (onlyNums.length > 10) {
                      onlyNums = onlyNums.slice(0, 10)
                    }
                    formik.setFieldValue('spouse_mobile', onlyNums)
                  }}
                  error={formik.touched.spouse_mobile && Boolean(formik.errors.spouse_mobile)}
                  required={false}
                  disabled={formik.values.marital_status !== 2}
                  inputProps={{ tabIndex: 11 }}
                />
              </Grid>
              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='contact_person'
                  name='contact_person'
                  label='Emergency Contact Person'
                  value={formik.values.contact_person}
                  onChange={formik.handleChange}
                  error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                  required={false}
                  inputProps={{ tabIndex: 12 }}
                />
              </Grid>
              <Grid item xs={4} sx={style.gridStyle}>
                <InputField
                  size='small'
                  fullWidth
                  id='contact_person_phone'
                  name='contact_person_phone'
                  label='Emergency Contact Person Phone'
                  value={formik.values.contact_person_phone}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                    if (onlyNums.length > 10) {
                      onlyNums = onlyNums.slice(0, 10)
                    }
                    formik.setFieldValue('contact_person_phone', onlyNums)
                  }}
                  error={
                    formik.touched.contact_person_phone &&
                    Boolean(formik.errors.contact_person_phone)
                  }
                  required={false}
                  inputProps={{ tabIndex: 13 }}
                />
              </Grid>

            </Grid>
            {/* </Grid> */}

            <Divider sx={{ my: 1 }} />

            <Grid container spacing={0}>
              <Grid item xs={6} display='flex' alignItems='center'>
                <Grid item>
                  <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormLabel id='demo-row-radio-buttons-group-label' sx={{ mr: 2 }}>
                      Under Probation
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='under_probation'
                      value={formik.values.under_probation}
                      onChange={(e: any) => {
                        if (e.target.value === '0') formik.values.probation_period = 0
                        else formik.values.probation_period = 12
                        return formik.handleChange(e)
                      }}
                    >
                      <FormControlLabel value={1} control={<Radio tabIndex={16} />} label='Yes' />
                      <FormControlLabel value={0} control={<Radio tabIndex={17} />} label='No' />
                    </RadioGroup>
                    {formik.touched.under_probation && formik.errors.under_probation && (
                      <FormHelperText error>{formik.errors.under_probation}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item>
                  <SelectField
                    size='small'
                    id='probation_period'
                    name='probation_period'
                    label='Probation Period'
                    select
                    value={formik.values.probation_period}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.probation_period && Boolean(formik.errors.probation_period)
                    }
                    disabled={formik.values.under_probation <= 0}
                    required={false}
                    inputProps={{ tabIndex: 18 }}
                  >
                    {formik.values.under_probation <= 0 && (
                      <StyledMenuItem value={0}>0 Months</StyledMenuItem>
                    )}
                    <StyledMenuItem value={3}>3 Months</StyledMenuItem>
                    <StyledMenuItem value={6}>6 Months</StyledMenuItem>
                    <StyledMenuItem value={9}>9 Months</StyledMenuItem>
                    <StyledMenuItem value={12}>1 Year</StyledMenuItem>
                  </SelectField>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={-1}>
                <FormikProvider value={formik}>
                  <Form>
                    <FieldArray name='dependents'>
                      {({ push, remove }) => (
                        <>
                          {formik.values.dependents.map((dependent: any, index: any) => {
                            const baseTabIndex = 19 + index * 3
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '-16px',
                                }}
                              >
                                <InputField
                                  name={`dependents[${index}].name`}
                                  size='small'
                                  fullWidth
                                  label='Name of the dependent'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={false}
                                  inputProps={{ tabIndex: baseTabIndex }}
                                />
                                <InputField
                                  name={`dependents[${index}].id_relationship`}
                                  size='small'
                                  fullWidth
                                  select
                                  label='Relationship'
                                  variant='outlined'
                                  margin='normal'
                                  style={{ marginLeft: 10 }}
                                  value={dependent.id_relationship}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={false}
                                  inputProps={{ tabIndex: baseTabIndex + 1 }}
                                >
                                  <MenuItem value='children'>Children</MenuItem>
                                  <MenuItem value='parent'>Parent</MenuItem>
                                  <MenuItem value='spouse'>Spouse</MenuItem>
                                </InputField>
                                <InputField
                                  name={`dependents[${index}].age`}
                                  size='small'
                                  fullWidth
                                  type='number'
                                  label='Age'
                                  variant='outlined'
                                  margin='normal'
                                  style={{ marginLeft: 10 }}
                                  value={dependent.age}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={false}
                                  inputProps={{ tabIndex: baseTabIndex + 2 }}
                                />
                                {index > 0 && (
                                  <IconButton
                                    onClick={() => remove(index)}
                                    style={{ marginLeft: 10 }}
                                    tabIndex={baseTabIndex + 3}
                                  >
                                    <RemoveCircleOutlineSharpIcon />
                                  </IconButton>
                                )}
                              </div>
                            )
                          })}
                          <CommonButton
                            type='button'
                            onClick={() =>
                              push({ dependent_name: '', id_relationship: '', age: null })
                            }
                            startIcon={<AddCircleOutlineTwoToneIcon />}
                            variant='contained'
                            color='primary'
                            sx={{ mt: 2 }}
                            tabIndex={formik.values.dependents.length * 3 + 19}
                          >
                            Add Dependent
                          </CommonButton>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1}>
              <Grid item>
                <FormControl component='fieldset' className={classes.formControl}>
                  <FormLabel component='legend'>Assign Role</FormLabel>
                  <FormGroup row>
                    {uniqueRole &&
                      uniqueRole.length > 0 &&
                      uniqueRole.map((role: { id: number; name: string }, roleIndex: any) => (
                        <FormControlLabel
                          key={role.id}
                          control={
                            <Checkbox
                              name='id_roles'
                              value={role.id.toString()}
                              checked={(formik.values.id_roles as number[]).includes(role.id)}
                              onChange={handleCheckboxChange}
                              tabIndex={formik.values.dependents.length * 3 + 20 + roleIndex}
                            />
                          }
                          label={role.name}
                        />
                      ))}
                  </FormGroup>

                  <FormHelperText sx={{ color: 'red', ml: 0 }}>
                    {Array.isArray(formik.errors.id_roles)
                      ? formik.errors.id_roles.join(', ')
                      : formik.errors.id_roles}{' '}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item xs={6} sx={{ mt: -2 }}>
                {formik.values.isEmployeeSelected && existEmpId?.[0] === 281 && (
                  <Grid item xs={6}>
                    <InputField
                      size='small'
                      fullWidth
                      id='employee_id'
                      name='employee_id'
                      label='Employee ID'
                      value={formik.values.employee_id}
                      // onChange={formik.handleChange}
                      disabled={Boolean(rowdata?.username)}
                      onChange={(e) => {
                        let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                        if (onlyNums.length > 10) {
                          onlyNums = onlyNums.slice(0, 10) // Limit to 10 digits
                        }
                        formik.setFieldValue('employee_id', onlyNums)
                      }}
                      error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                      tabIndex={formik.values.dependents.length * 3 + 20 + uniqueRole.length}
                    />
                  </Grid>
                )}
              </Grid> */}
            </Grid>
          </>
        )
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormLabel id='demo-row-radio-buttons-group-label' sx={{ mr: 2 }}>
                  At Client Location
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='is_at_client_location'
                  value={formik.values.is_at_client_location}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value={1} control={<Radio />} label='Yes' />
                  <FormControlLabel value={0} control={<Radio />} label='No' />
                </RadioGroup>
                {formik.touched.is_at_client_location && formik.errors.is_at_client_location && (
                  <FormHelperText error>{formik.errors.is_at_client_location}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {formik?.values?.is_at_client_location > 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <SelectField
                        size='small'
                        id='client_location'
                        name='client_location'
                        label='Client Location'
                        select
                        value={formik.values.client_location}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.client_location && Boolean(formik.errors.client_location)
                        }
                        sx={style.selectStyle}
                      >
                        {props?.allClientLocation.map((location: any) => (
                          <StyledMenuItem key={location.id} value={location.id}>
                            {location.location_name} - {location.city}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <InputField
                        size='small'
                        fullWidth
                        id='location_start_date'
                        name='location_start_date'
                        label='Location Start Date'
                        type='date'
                        value={formik.values.location_start_date || ''}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.location_start_date &&
                          Boolean(formik.errors.location_start_date)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <InputField
                        size='small'
                        fullWidth
                        id='location_end_date'
                        name='location_end_date'
                        label='Location End Date'
                        type='date'
                        value={formik.values.location_end_date || ''}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.location_end_date &&
                          Boolean(formik.errors.location_end_date)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <SelectField
                        size='small'
                        id='day_to_visit'
                        name='day_to_visit'
                        label='Day To Visit'
                        select
                        value={formik.values.day_to_visit}
                        onChange={formik.handleChange}
                        error={formik.touched.day_to_visit && Boolean(formik.errors.day_to_visit)}
                        sx={style.selectStyle}
                        required={false}
                      >
                        {daysOfWeek?.map((day: any) => (
                          <StyledMenuItem key={day.id} value={day.value}>
                            {day.value}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <InputField
                        size='small'
                        fullWidth
                        id='time_to_visit'
                        name='time_to_visit'
                        label='Time To Visit'
                        type='time'
                        value={formik.values.time_to_visit || ''}
                        onChange={formik.handleChange}
                        error={formik.touched.time_to_visit && Boolean(formik.errors.time_to_visit)}
                        InputLabelProps={{ shrink: true }}
                        required={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <InputField
                        size='small'
                        fullWidth
                        id='project_assigned'
                        name='project_assigned'
                        label='Project Assigned'
                        value={formik.values.project_assigned}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.project_assigned && Boolean(formik.errors.project_assigned)
                        }
                        required={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <SelectField
                        size='small'
                        id='location'
                        name='location'
                        label='Location'
                        select
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        error={formik.touched.location && Boolean(formik.errors.location)}
                        sx={style.selectStyle}
                        required={false}
                      >
                        <StyledMenuItem key={0} value={0}>
                          NA
                        </StyledMenuItem>
                        {props?.allLocation.map((location: any) => (
                          <StyledMenuItem key={location.id} value={location.id}>
                            {location.location}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>

                  <Grid item sx={style.gridStyle} xs={4}>
                    <InputField
                      size='small'
                      fullWidth
                      id='comp_location_start_date'
                      name='comp_location_start_date'
                      label='Location Start Date'
                      type='date'
                      value={formik.values.comp_location_start_date || ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.comp_location_start_date &&
                        Boolean(formik.errors.comp_location_start_date)
                      }
                      InputLabelProps={{ shrink: true }}
                      required={false}
                      disabled={!formik?.values?.location}
                    />
                  </Grid>
                  <Grid item sx={style.gridStyle} xs={4}>
                    <InputField
                      size='small'
                      fullWidth
                      id='comp_location_end_date'
                      name='comp_location_end_date'
                      label='Location End Date'
                      type='date'
                      value={formik.values.comp_location_end_date || ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.comp_location_end_date &&
                        Boolean(formik.errors.comp_location_end_date)
                      }
                      InputLabelProps={{ shrink: true }}
                      required={false}
                      disabled={!formik?.values?.location}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <SelectField
                        size='small'
                        id='floor_id'
                        name='floor_id'
                        label='Floor'
                        select
                        value={formik.values.floor_id}
                        onChange={formik.handleChange}
                        error={formik.touched.floor_id && Boolean(formik.errors.floor_id)}
                        sx={style.selectStyle}
                        required={false}
                      >
                        {props?.allFloors.map((floor: any) => (
                          <StyledMenuItem key={floor.id} value={floor?.id}>
                            {floor?.floor_name}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={style.gridStyle}>
                      <SelectField
                        size='small'
                        id='Workstation'
                        name='Workstation'
                        label='Workstation'
                        select
                        value={formik.values.Workstation}
                        onChange={formik.handleChange}
                        error={formik.touched.Workstation && Boolean(formik.errors.Workstation)}
                        sx={style.selectStyle}
                        required={false}
                      >
                        <StyledMenuItem key={0} value={0}>
                          NA
                        </StyledMenuItem>
                        {props.allWorkstations.map((workstation: any) => (
                          <StyledMenuItem key={workstation.id} value={workstation.id}>
                            {workstation.workstation_name}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                  {/* ** For selecting Workstation please select Location first and then Floor. */}
                  {/* <Grid item xs={4}>
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <FormLabel id='key_assigned' sx={{ mr: 2 }}>
                            Key Assigned
                          </FormLabel>
                          <RadioGroup
                            row
                            name='key_assigned'
                            aria-labelledby='key_assigned'
                            // name='Key Assigned'
                            value={formik.values.key_assigned}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              disabled={!formik?.values?.Workstation}
                              value={1}
                              control={<Radio />}
                              label='Yes'
                            />
                            <FormControlLabel
                              disabled={!formik?.values?.Workstation}
                              value={0}
                              control={<Radio />}
                              label='No'
                            />
                          </RadioGroup>
                          {formik.touched.key_assigned && formik.errors.key_assigned && (
                            <FormHelperText error>{formik.errors.key_assigned}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          disabled={!formik.values.key_assigned || !formik?.values?.Workstation}
                          size='small'
                          fullWidth
                          id='key_number'
                          name='key_number'
                          label='Key Number'
                          value={formik.values.key_number}
                          onChange={formik.handleChange}
                          error={formik.touched.key_number && Boolean(formik.errors.key_number)}
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormLabel id='demo-row-radio-buttons-group-label' sx={{ mr: 2 }}>
                    Status
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value={1} control={<Radio />} label='Active' />
                    <FormControlLabel value={0} control={<Radio />} label='InActive' />
                  </RadioGroup>
                  {formik.touched.status && formik.errors.status && (
                    <FormHelperText error>{formik.errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sx={style.gridStyle} xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  id='loginId'
                  name='loginId'
                  label='Login Id'
                  value={formik.values.loginId}
                  onChange={formik.handleChange}
                  error={formik.touched.loginId && Boolean(formik.errors.loginId)}
                />
              </Grid>
              <Grid item sx={style.gridStyle} xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  type='password'
                  id='password'
                  name='password'
                  label='Password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  required={false}
                // helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item sx={style.gridStyle} xs={4}>
                <InputField
                  size='small'
                  fullWidth
                  type='password'
                  id='confirm_password'
                  name='confirm_password'
                  label='Confirm Password'
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  required={false}
                  error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                />
                <Typography></Typography>
              </Grid>

              {/* Designation */}
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <SelectField
                    size='small'
                    id='designation'
                    name='designation'
                    label='Designation'
                    select
                    value={formik.values.designation}
                    onChange={formik.handleChange}
                    error={formik.touched.designation && Boolean(formik.errors.designation)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.designationData?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.id}>
                        {data.grade_name} - {data.desc}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

              {/* Designation */}
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <SelectField
                    size='small'
                    id='desig_band'
                    name='desig_band'
                    label='Designation Band'
                    select
                    value={formik.values.desig_band}
                    onChange={formik.handleChange}
                    error={formik.touched.desig_band && Boolean(formik.errors.desig_band)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.designationBandData?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.id}>
                        {data.band_title}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

              {/* Manager */}
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <SelectField
                    size='small'
                    id='manager'
                    name='manager'
                    label='Manager'
                    select
                    value={formik.values.manager}
                    onChange={formik.handleChange}
                    error={formik.touched.manager && Boolean(formik.errors.manager)}
                    sx={style.selectStyle}
                    required={true}
                  >
                    {props.AllEmpList?.map((data: any, index: number) => (
                      <StyledMenuItem key={index} value={data.userId}>
                        {data.name}
                      </StyledMenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Box>
            <Box mb={2}>
              <Typography fontFamily='Montserrat-SemiBold'>Present Address</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {props.allCountries && props.allCountries.length > 0 && (
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='id_country'
                      name='id_country'
                      label='Country'
                      value={formik.values.id_country}
                      onChange={formik.handleChange}
                      error={formik.touched.id_country && Boolean(formik.errors.id_country)}
                      inputProps={{ tabIndex: 1 }}
                    >
                      {props.allCountries.map((country: any) => (
                        <StyledMenuItem key={country.id} value={country.id}>
                          {country.country_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  )}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_address'
                    name='present_address'
                    label='Address'
                    value={formik.values.present_address}
                    onChange={formik.handleChange}
                    error={formik.touched.present_address && Boolean(formik.errors.present_address)}
                    inputProps={{ tabIndex: 4 }}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='telephone_no'
                    name='telephone_no'
                    label='Telephone Number'
                    value={formik.values.telephone_no}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9]/g, '') // Keep only numbers
                      formik.setFieldValue('telephone_no', onlyNums) // Update formik state with numbers only
                    }}
                    required={false}
                    error={formik.touched.telephone_no && Boolean(formik.errors.telephone_no)}
                    inputProps={{ tabIndex: 7 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {/* {props.allStates && props.allStates.length > 0 && ( */}
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='present_state'
                      name='present_state'
                      label='State'
                      value={formik.values.present_state}
                      onChange={formik.handleChange}
                      error={formik.touched.present_state && Boolean(formik.errors.present_state)}
                      // helperText={formik.touched.present_state && formik.errors.present_state}
                      inputProps={{ tabIndex: 2 }}
                    >
                      {props.allStates.map((state: any) => (
                        <StyledMenuItem key={state.id} value={state.id}>
                          {state.state_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  {/* )} */}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_postal_code'
                    name='present_postal_code'
                    label='Postal Code'
                    value={formik.values.present_postal_code}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      let allowedCharacters = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                      if (allowedCharacters.length > 6) {
                        allowedCharacters = allowedCharacters.slice(0, 6)
                      }
                      formik.setFieldValue('present_postal_code', allowedCharacters)
                    }}
                    error={
                      formik.touched.present_postal_code &&
                      Boolean(formik.errors.present_postal_code)
                    }
                    inputProps={{ tabIndex: 5 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='present_city'
                    name='present_city'
                    label='City'
                    value={formik.values.present_city}
                    onChange={formik.handleChange}
                    error={formik.touched.present_city && Boolean(formik.errors.present_city)}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='citizenship'
                    name='citizenship'
                    label='Citizenship'
                    value={formik.values.citizenship}
                    onChange={formik.handleChange}
                    error={formik.touched.citizenship && Boolean(formik.errors.citizenship)}
                    required={false}
                    inputProps={{ tabIndex: 6 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box mb={2}>
              <Typography fontFamily='Montserrat-SemiBold'>Permanent Address</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {props.allCountries && props.allCountries.length > 0 && (
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='id_country_permanent'
                      name='id_country_permanent'
                      label='Country'
                      value={formik.values.id_country_permanent}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.id_country_permanent &&
                        Boolean(formik.errors.id_country_permanent)
                      }
                      inputProps={{ tabIndex: 8 }}
                    >
                      {props.allCountries.map((country: any) => (
                        <StyledMenuItem key={country.id} value={country.id}>
                          {country.country_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  )}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='permanent_address'
                    name='permanent_address'
                    label='Address'
                    value={formik.values.permanent_address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.permanent_address && Boolean(formik.errors.permanent_address)
                    }
                    inputProps={{ tabIndex: 11 }}
                  // helperText={formik.touched.permanent_address && formik.errors.permanent_address}
                  />
                </Grid>
                {/* <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='per_telephone_no'
                    name='per_telephone_no'
                    label='Telephone Number'
                    value={formik.values.per_telephone_no}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9]/g, '') // Keep only numbers
                      formik.setFieldValue('per_telephone_no', onlyNums) // Update formik state with numbers only
                    }}
                    required={false}
                    error={
                      formik.touched.per_telephone_no && Boolean(formik.errors.per_telephone_no)
                    }
                    inputProps={{ tabIndex: 14 }}
                  />
                </Grid> */}
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  {/* {props.allStates && props.allStates.length > 0 && ( */}
                    <SelectField
                      select
                      size='small'
                      fullWidth
                      id='permanent_state'
                      name='permanent_state'
                      label='State'
                      value={formik.values.permanent_state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.permanent_state && Boolean(formik.errors.permanent_state)
                      }
                      inputProps={{ tabIndex: 9 }}
                    >
                      {props.allStates.map((state: any) => (
                        <StyledMenuItem key={state.id} value={state.id}>
                          {state.state_name}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  {/* )} */}
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='permanent_postal_code'
                    name='permanent_postal_code'
                    label='Postal Code'
                    value={formik.values.permanent_postal_code}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      let allowedCharacters = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                      if (allowedCharacters.length > 6) {
                        allowedCharacters = allowedCharacters.slice(0, 6)
                      }
                      formik.setFieldValue('permanent_postal_code', allowedCharacters)
                    }}
                    error={
                      formik.touched.permanent_postal_code &&
                      Boolean(formik.errors.permanent_postal_code)
                    }
                    inputProps={{ tabIndex: 12 }}
                  // helperText={
                  //   formik.touched.permanent_postal_code && formik.errors.permanent_postal_code
                  // }
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='permanent_city'
                    name='permanent_city'
                    label='City'
                    value={formik.values.permanent_city}
                    onChange={formik.handleChange}
                    error={formik.touched.permanent_city && Boolean(formik.errors.permanent_city)}
                    inputProps={{ tabIndex: 10 }}
                  // helperText={formik.touched.permanent_city && formik.errors.permanent_city}
                  />
                </Grid>
                <Grid item sx={style.gridStyle}>
                  <InputField
                    size='small'
                    fullWidth
                    id='email'
                    name='email'
                    label='Alternative Email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    required={true}
                    inputProps={{ tabIndex: 13 }}
                    disabled={Boolean(rowdata?.username)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (

    <div className={classes.root}>
      {(isUserCreating || isUserUpdating) && (
        <Dialog
          open={isUserCreating || isUserUpdating}
          PaperProps={{ style: loaderProps }}
        >
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <form onSubmit={formik.handleSubmit}>
        {/* <Box mb={2}>
          <Typography
            sx={{ fontSize: '28px', color: '#193C6D', fontFamily: 'Montserrat-Bold' }}
            textAlign={'center'}
          >
            {Boolean(rowdata?.username) ? 'Update Employee' : 'Add Employee'}
          </Typography>
        </Box> */}
        <Box sx={{ textAlign: 'left' }}>
          <Box
            onClick={() => navigate(-1)}
            sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
          >
            <ArrowBack />
          </Box>
        </Box>

        <HeaderHeading sx={{ mb: 2 }}>{Boolean(rowdata?.username) ? 'Update Employee' : 'Add Employee'}</HeaderHeading>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mx: 4, my: 1, mt: 3 }}>
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div
              style={{
                marginTop: '24px',
                display: 'flex',
                justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
              }}
            >
              <CommonButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                sx={{ display: activeStep === 0 ? 'none' : 'flex' }}
              >
                PREVIOUS
              </CommonButton>
              <CommonButton variant='contained' color='primary' onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}
              </CommonButton>
            </div>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,

    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    allRoles: fetchUserDetailsEntities.fetchUserData(state).fetchAllRole,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    allFloors: fetchUserDetailsEntities.fetchUserData(state).fetchAllFloor,
    allWorkstations: fetchUserDetailsEntities.fetchUserData(state).fetchAllWorkstation,
    allLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllLocation,
    allClientLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllClientLocation,
    isUserCreated: fetchUserDetailsUI.fetchUserData(state).isUserCreatedSuccess,
    isUserCreating: fetchUserDetailsUI.fetchUserData(state).isUserCreating,
    isUserCreateFailed: fetchUserDetailsUI.fetchUserData(state).isUserCreateFailed,
    isUserUpdated: fetchUserDetailsUI.fetchUserData(state).isUserUpdated,
    isUserUpdating: fetchUserDetailsUI.fetchUserData(state).isUserUpdating,
    isUserUpdateFailed: fetchUserDetailsUI.fetchUserData(state).isUserUpdateFailed,
    userImage: fetchUserDetailsEntities.fetchUserData(state).getUserImage,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    getUserImage: (data: any) => dispatch(getUserImage.request(data)),
    resetUserImage: () => {
      dispatch(getUserImage.reset())
    },
    uploadUserImg: (data: any) => dispatch(uploadUserImg.request({ data })),
    createUser: (data: any) => dispatch(createUser.request({ data })),
    resetCreateUser: () => {
      dispatch(createUser.reset())
    },
    fetchAllRole: () => dispatch(fetchAllRoles.request()),
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    fetchAllFloor: () => dispatch(fetchAllFloors.request()),
    fetchAllWorkstation: () => dispatch(fetchAllWorkstation.request()),
    fetchAllLocation: () => dispatch(fetchAllLocation.request()),
    updateUser: (data: any) => dispatch(updateUser.request({ data })),
    resetUpdateUser: () => {
      dispatch(updateUser.reset())
    },
    fetchAllClientLocation: () => dispatch(fetchAllClientLocation.request()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StepperForm)
