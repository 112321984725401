import { Copyright } from '@mui/icons-material'
import { StyledNavLink } from './CommonStyles'

const Footer = () => {
  return (
    <div
      style={{
        position:"absolute",
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: '12px',
        fontFamily: 'Montserrat-Medium',
        background: '#fff',
        padding: '5px 0px 5px 0px',
        minHeight: '70px',
        display: 'block',
      }}
    >
      <p style={textStyle}>
        Copyright <Copyright style={{ fontSize: '15px', position: 'relative', top: '3px' }} /> {new Date().getFullYear()} {' '}
        by{' '}
        <StyledNavLink to='https://tudip.com' target='_blank'>
          Tudip Technologies
        </StyledNavLink>
        .
      </p>
      <p style={textStyle}>All Rights Reserved.</p>
      <p style={textStyle}>
        <StyledNavLink to='https://tudip.com/privacy-policy' target='_blank'>
          Privacy Policy
        </StyledNavLink>
        .
      </p>
    </div>
  )
}

const textStyle = {
  color: '#193C6C',
}

export default Footer
