import Button from '@mui/material/Button'
import { ReactComponent as ExportToPDFIcon } from '../../../assets/images/exportToPDF.svg'
import { ReactComponent as ExportToExcelIcon } from '../../../assets/images/exportToExcel.svg'
import { Box } from '@mui/material'
import { exportToExcel} from './DownloadCSVButton'
import { exportAsPDF } from './ExportAsPdf'
import { downloadCSV } from './ExportAsExcel'
// import {exportAsExe}


const style = {
    
  buttonStyle: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '1rem',
    padding: '0',
    width: '40px',
    height: '40px',
    minWidth: 'fit-content',
    borderRadius: '50%',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '@media only screen and (max-width:780px)': {
      fontSize: '0.9rem',
    },
  },
  container:{
    '@media only screen and (max-width:1230px)': {
      width:"200px"
    },
  }
}


const ExportButton = (props: any) => {

  return (
    <Box display='flex' justifyContent='end' alignItems='center' width='160px' sx={style.container}>
        <Button
          onClick={() => {
            downloadCSV(props.subTableData, props.selectedTab)
          }}
          sx={style.buttonStyle}
          variant='outlined'
        >
          <ExportToExcelIcon />
        </Button>
    </Box>
  )
}

export default ExportButton
