import { combineReducers } from 'redux'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { EMPLOYEE_HISTORY, MANAGER_HISTORY, REQUEST, SUCCESS } from '../../actions/actiontypes'

const ui = () => {


    const isGettingEmpHistoryData = (state = false, action: Actions) => {
        switch (action.type) {
          case EMPLOYEE_HISTORY[SUCCESS]:
            return false
          case EMPLOYEE_HISTORY[REQUEST]:
            return true
          default:
            return state
        }
      }
      const isGettingmManagerHistoryData = (state = false, action: Actions) => {
        switch (action.type) {
          case MANAGER_HISTORY[SUCCESS]:
            return false
          case MANAGER_HISTORY[REQUEST]:
            return true
          default:
            return state
        }
      }

    return combineReducers({
        isGettingEmpHistoryData,
        isGettingmManagerHistoryData
      })
}
export default ui

export const getAllEmpPortalUI = (state: RootState) => state.ui.employeePortal
