import Box from '@mui/material/Box'
import SideDrawer from '../Common/SideDrawer'
import Header from '../Common/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Common/Footer'

export default function PersistentDrawerLeft() {
  return (
    <>
      <SideDrawer />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '94%',
          minHeight: '100vh',
          flexDirection: 'column',
          marginLeft: '77px',
          background: 'rgb(231, 235, 240)',
        }}
      >
        <Header />

        <Outlet />
        <Box height={'100px'}></Box>
        <Footer />
      </Box>
    </>
  )
}
