import * as React from 'react'
import { useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
  Dialog,
  CircularProgress,
  Box,
} from '@mui/material'
import DetailsIcon from '@mui/icons-material/Details'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import HighlightIcon from '@mui/icons-material/Highlight'
import ContactsIcon from '@mui/icons-material/Contacts'
import { dashboardEntity, dashboardUI } from '../../reducers'
import Tooltip from '@mui/material/Tooltip'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useNavigate } from 'react-router-dom'
import { DashboardIcon, OpenTabLogo } from '../../utils/StaticData'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Primary } from '../../utils'
import { connect } from 'react-redux'
import { getHomePageDetails } from '../../actions'
import { loaderProps } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'
import { makeStyles } from '@mui/styles'

function MasterSetUp({ homePageDetails, getHomeDetails, loaderState }: any) {
  const useStyles = makeStyles({
    DRsHeading: {
      marginBottom: '0 !important',
      position: 'relative',
      top: 4,
      lineHeight: '1 !important',
      color: '#193C6D !important',
      fontSize: '15px !important',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '150px',
      transition: 'text-decoration 0.3s',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconBillable: {
      color: 'green',
      position: 'relative',
      top: 5,
      fontSize: '18px !important',
    },
    iconAdditional: {
      color: 'brown',
      fontSize: '18px !important',
      position: 'relative',
      top: 5,
    },
    projectCard: {
      position: 'relative',
      bottom: 5,
    },
  })
  const classes = useStyles()
  const navigate = useNavigate()

  const handleBasicInfo = (userId: number) => {
    if (userId) {
      navigate(`basic-info?id=${userId}`)
    }
  }
  const handleLeaveDetails = (userId: number) => {
    if (userId) {
      navigate(`leave-details?id=${userId}`)
    }
  }
  const handleDrsDetails = () => {
    navigate('drs')
  }
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleDrsLeave = () => {
    navigate('drs-list')
  }
  const handleAttendanceList = () => {
    navigate('attendance-list')
  }
  const handleAssignedAsset = () => {
    navigate('assigned-asset')
  }
  const handleHolidayList = () => {
    navigate('holiday-list')
  }

  const handleTodaysHighlights = () => {
    navigate('todays-highlights')
  }

  const handleServiceRequest = () => {
    navigate('service-request')
  }

  React.useEffect(() => {
    getHomeDetails()
  }, [])


  const welcomeMessage = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.5',
    fontSize: '10px',
  }
  const DashboardText = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
    fontSize: '14px',
  }

  const IconStyles: React.CSSProperties = {
    margin: '0px 10px 0 15px',
    width: '19px',
    position: 'relative',
    top: '3px',
  }

  const cardStyles = {
    marginLeft: '18px',
    marginBottom: '18px',
    marginTop: '8px',
    width: '23%',
  }

  const CommonCardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }

  const CardHeadingText: React.CSSProperties = {
    position: 'relative',
    bottom: '10px',
    fontFamily: 'Montserrat-SemiBold',
  }

  const CardMidText = {
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }

  const CardIconStyle = {
    fontSize: 28,
    color: Primary,
    marginRight: '4px',
    position: 'relative',
    right: '4px',
  }

  const CardStyle = {
    padding: '16px 13px 16px 16px',
  }

  const spanStyle = {
    cursor: 'pointer',
  }

  const BasicInfoCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <LightbulbIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Your Info </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={(e) => handleBasicInfo(homePageDetails?.basicInfo?.userId)}
              />
            </span>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Name:
            <span>{` ${homePageDetails?.basicInfo?.name}`} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Designation:
            <span> {homePageDetails?.basicInfo?.designation} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Employee Id:
            <span> {homePageDetails?.basicInfo?.employeeId} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Manager:
            <span> {homePageDetails?.basicInfo?.managerName} </span>
          </Typography>
          <Typography
            onClick={handleAttendanceList}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            {/* Attendance Details  */}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const DrsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <ContactsIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> DRs </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleDrsDetails}
              />
            </span>
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                <span style={{ color: 'red' }}> Free: </span>{' '}
                <span style={{ color: 'red' }}>{homePageDetails?.DRS?.free} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                <span style={{ color: 'red' }}> Additional: </span>{' '}
                <span style={{ color: 'red' }}>{homePageDetails?.DRS?.additional} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Billable: {homePageDetails?.DRS?.billable}
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Unbillable: {homePageDetails?.DRS?.nonBillable}
              </Typography>
            </Box>
          </Box>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Total: {homePageDetails?.DRS?.count}
          </Typography>

          <Typography
            onClick={handleDrsLeave}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            DRs Leave Details
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const LeaveDetailsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <DetailsIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Leave Details </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  handleLeaveDetails(homePageDetails?.basicInfo?.userId)
                }}
              />
            </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Total Leaves Allocated:
            <span> {homePageDetails?.leaveDetails?.totalLeaveAllocated} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Leaves Availed:
            <span> {homePageDetails?.leaveDetails?.leavesAvailed}</span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Leave Balance:
            <span> {homePageDetails?.leaveDetails?.leaveBalance}</span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions></CardActions>
      </div>
    </React.Fragment>
  )

  const AssignedAssetCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <ImportantDevicesIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Assets </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleAssignedAsset}
              />
            </span>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Laptop Id:<span> {homePageDetails?.laptopDetails?.laptopId}</span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Brand:<span> {homePageDetails?.laptopDetails?.Brand} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Assigned On:
            <span>
              {` ${new Date(homePageDetails?.laptopDetails?.AssignedOn).toLocaleDateString(
                'en-GB',
              )}`}
            </span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions></CardActions>
      </div>
    </React.Fragment>
  )

  const HolidayListCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <HolidayVillageIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Holidays </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleHolidayList}
              />
            </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Upcoming Holidays:<span> {homePageDetails?.holidayList?.upcomingHoliday} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Total Holidays:<span> {homePageDetails?.holidayList?.totalHolidays} </span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions></CardActions>
      </div>
    </React.Fragment>
  )

  const TodaysHighlightCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <HighlightIcon sx={CardIconStyle} />
            <span style={CardHeadingText}>Today's Highlights</span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleTodaysHighlights}
              />
            </span>
          </Typography>
          {homePageDetails?.todaysHighlights?.todaysAnniversaryCount > 0 ? (
            <Typography sx={CardMidText} color='text.secondary' gutterBottom>
              Tudipversaries Count:
              <span> {homePageDetails?.todaysHighlights?.todaysAnniversaryCount}</span>
            </Typography>
          ) : null}

          {homePageDetails?.todaysHighlights?.todaysBirthDayCount > 0 ? (
            <Typography sx={CardMidText} color='text.secondary' gutterBottom>
              Birthday Count:
              <span> {homePageDetails?.todaysHighlights?.todaysBirthDayCount}</span>
            </Typography>
          ) : null}

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Lunch:
            <span style={spanStyle}>
              {' '}
              <Tooltip title={homePageDetails?.todaysHighlights?.lunch}>
                {homePageDetails?.todaysHighlights?.lunch.length > 30
                  ? homePageDetails?.todaysHighlights?.lunch.slice(0, 30) + '...'
                  : homePageDetails?.todaysHighlights?.lunch}
              </Tooltip>
            </span>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Dinner:
            <span style={spanStyle}>
              {' '}
              <Tooltip title={homePageDetails?.todaysHighlights?.dinner}>
                {homePageDetails?.todaysHighlights?.dinner.length > 30
                  ? homePageDetails?.todaysHighlights?.dinner.slice(0, 30) + '...'
                  : homePageDetails?.todaysHighlights?.dinner}
              </Tooltip>
            </span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const ProjectDetailsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <AccountTreeIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Projects</span>
            {/* <span style={{ float: 'right' }}>
              <img src={OpenTabLogo} style={{ cursor: 'pointer' }} alt='' onClick={handleProjectDetails} />
            </span> */}
          </Typography>

          <Typography
            className={classes.projectCard}
            sx={CardMidText}
            color='text.secondary'
            gutterBottom
          >
            {homePageDetails?.projectDetails?.projectInfo.map((data: any, index: number) => (
              <span key={data[index]}>
                {data.includes('Billable') ? (
                  <>
                    {data.replace('Billable', '')}
                    <Tooltip title='Billable'>
                      <AttachMoneyIcon className={classes.iconBillable} />
                    </Tooltip>
                  </>
                ) : data.includes('Additional') ? (
                  <>
                    {data.replace('Additional', '')}
                    <Tooltip title='Additional'>
                      <AddCircleIcon className={classes.iconAdditional} />
                    </Tooltip>
                  </>
                ) : (
                  data
                )}
                {index !== homePageDetails?.projectDetails?.projectInfo.length - 1 && (
                  <>
                    <br />
                  </>
                )}
              </span>
            ))}
          </Typography>

          <Typography
            className={classes.projectCard}
            sx={CardMidText}
            color='text.secondary'
            gutterBottom
          >
            Status: {homePageDetails?.projectDetails?.resourseStatus}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const ServiceRequest = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <MiscellaneousServicesIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Service Requests </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleServiceRequest}
              />
            </span>
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Open: <span> {homePageDetails?.serviceRequest?.pendingRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Closed: <span> {homePageDetails?.serviceRequest?.closedRequest} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                In Progress: <span> {homePageDetails?.serviceRequest?.inProgressRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Reopen: <span> {homePageDetails?.serviceRequest?.reOpenRequest} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Won't Fixed: <span> {homePageDetails?.serviceRequest?.wontFixedRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Total: <span> {homePageDetails?.serviceRequest?.totalRequest} </span>
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const HeadingBar = {
    padding: '10px 0px',
    display: 'flex',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 3px #00000029',
    opacity: 1,
  }  

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div style={{ width: '100%' }}>
          <Paper elevation={3} style={HeadingBar}>
            <span style={DashboardText}>
              {' '}
              <img style={IconStyles} src={DashboardIcon} />
              My Dashboard
            </span>
          </Paper>

          <div style={{ padding: '12px' }}>
            <Typography style={welcomeMessage} variant='body1'></Typography>
            <Paper
              elevation={3}
              style={{
                width: '100%',
                height: '100%',
                padding: '10px 0px 0px 0px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 3px 6px #00000029',
                opacity: '1',
                marginTop: '10px',
                overflow: 'auto',
              }}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card sx={cardStyles} variant='outlined'>
                  {TodaysHighlightCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {ProjectDetailsCard}
                </Card>
                {homePageDetails?.DRS?.count > 0 ? (
                  <Card sx={cardStyles} variant='outlined'>
                    {DrsCard}
                  </Card>
                ) : null}
                <Card sx={cardStyles} variant='outlined'>
                  {ServiceRequest}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {AssignedAssetCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {LeaveDetailsCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {HolidayListCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {BasicInfoCard}
                </Card>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    homePageDetails: dashboardEntity.getDashboard(state).getHomePageDetails,
    loaderState: dashboardUI.getDashboard(state).isHomePageData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getHomeDetails: () => {
      dispatch(getHomePageDetails.request())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterSetUp)
